import {$getSelection, ElementNode,TextNode,type EditorConfig,type LexicalNode, type NodeKey, type SerializedElementNode, type SerializedTextNode, type Spread} from 'lexical';

function confidenceClass(confidence: number) {
  //user
  if (confidence === -1) return "confidence-user";
  if (confidence > 0.9) return "confidence-hight";
  if (confidence > 0.8) return "confidence-90";
  if (confidence > 0.7) return "confidence-80";
  if (confidence > 0.6) return "confidence-70";
  if (confidence > 0.5) return "confidence-60";
  return "confidence-low";
}

function getCurrentConfidenceClass(classList:DOMTokenList) : string {
  for (let i = 0; i < classList.length; i++) {
    const className = classList[i];
    if (className.startsWith("confidence-")) return className;
  }
  return "";
}

export type SerializedWordtNode = Spread<
  {
    type: 'word';
    version: 1;
  },
  SerializedTextNode
>;

export class WordNode extends TextNode {
    __start:number
    __end:number
    __confidence:number
    __initialText:string

    static getType(): string {
      return 'word';
    }
  
    static clone(node: WordNode): WordNode {
      return new WordNode(node.__text, node.__initialText, node.__start,node.__end,node.__confidence,node.__key);
    }

    constructor(text:string, initialText:string, start:number, end:number, confidence:number, key?: NodeKey) {
        super(text,key);
        this.__initialText = initialText
        this.__start = start;
        this.__end = end;
        this.__confidence = confidence;
    }
  
    createDOM(config: EditorConfig): HTMLElement {
      const element = super.createDOM(config);
      element.classList.add("word")
      element.classList.add(confidenceClass(this.__confidence))
      return element;
    }
  
    updateDOM(prevNode: WordNode, dom: HTMLElement,config: EditorConfig): boolean {
      const isUpdated = super.updateDOM(prevNode, dom, config);
      dom.classList.add("word")
      const currConfidenceClass = confidenceClass(this.__confidence)
      const prevConfidenceClass = getCurrentConfidenceClass(dom.classList)
      if (prevConfidenceClass !== currConfidenceClass) {
        dom.classList.remove(prevConfidenceClass)
        dom.classList.add(currConfidenceClass)
      }
      return isUpdated;
    }

    getStart(): number {
        return this.__start;
    }
    
    getEnd(): number {
        return this.__end;
    }

   getInitialText(): string {
    return this.__initialText;
    }
    
   getConfidence(): number {
    return this.__confidence;
    }

   setConfidence(confidence:number): this {
    const self = this.getWritable()
    self.__confidence = confidence
    return self;
   }

    exportJSON(): SerializedWordtNode {
      return {
        ...super.exportJSON(),
        type: 'word',
        version: 1,
      };
    }
  
  }
  
  export function $createWordNode(text:string, start:number, end:number, confidence:number): WordNode {
      return new WordNode(text,text,start,end,confidence);
    }
    
    export function $isWordNode(node: LexicalNode): boolean {
      return node instanceof WordNode;
    }