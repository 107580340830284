<script lang="ts">
   	import { Link } from "svelte-navigator";
    import ProjectLayout from "../layouts/Project.svelte";
  import Timeline from "../components/timelines/GenericTimeline.svelte";
  import { project, readOnly } from "../stores/project";
  import VideoPlayer from "../components/VideoPlayer.svelte";
  import TranscriptEditor from "../components/TranscriptEditor/TranscriptEditor.svelte";
  import {  useNavigate  } from "svelte-navigator";
  import { onDestroy, onMount } from "svelte";
  import { editorMode } from "../stores/editorMode";
  import {voiceoverReady} from "../stores/voiceover"
  import { transcript } from "../stores/transcript";

  const navigate = useNavigate()

  let editor:TranscriptEditor|undefined;
  //$: console.log("Editor", editor)

  $: noTranscript = $transcript.records.length === 0

  function handleNext() {
    if(editor) {
      editor.save();
    }
    navigate("../voiceover")

  }

  onMount(() => {
    $editorMode = "transcript"
  })

  onDestroy(() => {
    $editorMode = null
  })

</script>

<!--
<div>
    Transcript
    <Link to="../translate">Next >></Link>    
</div>
-->

<ProjectLayout>
    <div slot="title" class="title-project-page axis-x">
      <Link to="/"><button>Back</button></Link>
      <span class="page-header">
        {$project.name}
      </span> 
      <button disabled={$readOnly && !$voiceoverReady || noTranscript} on:click={handleNext}>Next</button>    
    </div>
      <Timeline slot="timeline"/>
      <VideoPlayer slot="player"/>
      <TranscriptEditor slot="editor" bind:this={editor}/>
</ProjectLayout>    