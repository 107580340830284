import { DecoratorNode, type LexicalNode, type NodeKey } from "lexical";
import type {ComponentProps, SvelteComponent} from 'svelte';
import RecordTranscriptText from "./RecordTranscriptText.svelte";

type DecoratorTranscriptTextType = {
    componentClass: typeof SvelteComponent;
    props: ComponentProps<RecordTranscriptText>;
  };
  

export class TranscriptTextNode extends DecoratorNode<DecoratorTranscriptTextType> {
  __id:string 
 
    static getType(): string {
      return 'record-transcript-text';
    }
  
    static clone(node: TranscriptTextNode): TranscriptTextNode {
      return new TranscriptTextNode(node.__id, node.__key);
    }
  
    constructor(id:string, key?: NodeKey) {
      super(key);
      this.__id = id
    }

    isIsolated(): boolean { return true; }
/*
    setNumber(num:number):this {
        const self = this.getWritable();
        self.__num = num;
        return self;
    }

    getNumber():number {
        return this.__num;
    }
*/  
    createDOM(): HTMLElement {
        const el = document.createElement('div');
        el.classList.add('voiceover-record-transcript-text');
        el.style.userSelect = 'none';
        return el
    }
  
    updateDOM(): false {
      return false;
    }
  
    decorate(): DecoratorTranscriptTextType {
        return {
            componentClass: RecordTranscriptText,
            props: {
                id: this.__id
            }
        }
      
    }
  }
  
  export function $createTranscriptTextNode(id:string): TranscriptTextNode {
    return new TranscriptTextNode(id);
  }
  
  export function $isTranscriptTextNode(node: LexicalNode): boolean {
    return node instanceof TranscriptTextNode;
  }