<script lang="ts">
    import SvgCheck from "../../assets/check.svg";
    import SvgCloseSmall from "../../assets/close_small.svg"; 
    import SvgEdit from "../../assets/edit.svg";    
    import {createEventDispatcher} from "svelte";
    import type { Speaker } from "./Speaker";

    export let speakers : Speaker[]
    export let close: () => void

    const dispatch = createEventDispatcher();

    let editSpeakerIndex : number | null | "new" = null;
    let speakerEditValue : Speaker = undefined;

    let activeEdit;

    $: if (activeEdit) {
        activeEdit.focus();
    }

    const handleNew = () => {
        editSpeakerIndex = "new"

        
        for(let i=0; i< 100; ++i) {
            let postfix = i === 0 ? "" : ` (${i})`
            let possibleName = `Speaker ${speakers.length + 1}${postfix}` 
            if (!speakers.map(s=>s.name).includes(possibleName)) {
                speakerEditValue = {name:possibleName, gender:"unknown"}
                break;
            }
        }

    }

    const handleEdit = (index:number) => () => {
        editSpeakerIndex=index
        speakerEditValue = {...speakers[index]}
    }

    const handleEditCancel = () => {
        editSpeakerIndex=null
        activeEdit = null;
    }

    const handleEditApply = () => {
        if(sameNameExists) return;
        if (editSpeakerIndex === "new") {
            dispatch("addSpeaker", speakerEditValue)
            close()
        } else {
            dispatch("updateSpeaker", {old: speakers[editSpeakerIndex], new: speakerEditValue})
        }
        editSpeakerIndex=null
        activeEdit = null;
    }

    const handleSelect = (index:number) => () => {
        if(editSpeakerIndex !== null) return;
        dispatch("selectSpeaker", speakers[index])
        close();
    }

    $: sameNameExists = speakers.map(s => s.name).includes(speakerEditValue?.name) && speakerEditValue?.name !== speakers[editSpeakerIndex]?.name

    function displayGender(gender:string) {
        switch(gender) {
            case "male": return "Male"
            case "female": return "Female"
            default: return ""
        }
    }



    function handleOkCancelKey(event: KeyboardEvent & { currentTarget: EventTarget & HTMLInputElement; }) {
        switch(event.key) {
            case "Enter": handleEditApply(); break;
            case "Escape": handleEditCancel(); break;
        }
        event.stopPropagation();
    }
</script>
<!-- svelte-ignore a11y-click-events-have-key-events -->

<div class="manage-speaker" class:editing={editSpeakerIndex !== null}>
        
    {#each speakers as speaker,i}
        {#if i === editSpeakerIndex}
            <div class="editable-speaker">
                <input class="speaker selected-speaker" type="text" bind:value={speakerEditValue.name} bind:this={activeEdit} on:keydown={handleOkCancelKey}/>
                <div class="speaker-actions">
                    <select class="choose-gender" bind:value={speakerEditValue.gender}>
                        <option value="unknown">(Not set)</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                    </select>
                    <button on:click={handleEditApply} class="gray-check svg-button-gray squre20-button" disabled={sameNameExists}><SvgCheck/></button>
                    <button on:click={handleEditCancel} class="svg-button-gray squre20-button"><SvgCloseSmall/></button>
                </div>
            </div>
        {:else}
            <div class="axis-x speaker">
                <div class="speaker-name" on:click={handleSelect(i)}>{speaker.name}</div>
                <!-- <span  on:click={handleSelect(i)}>{speaker.name}</span> -->
                <div class="speaker-actions">
                    <span class="speaker-gender">{displayGender(speaker.gender)}</span>
                    <button on:click={handleEdit(i)} class="svg-button-gray squre20-button"><SvgEdit/></button>
                </div>
            </div>
        {/if}
    {/each}

    {#if editSpeakerIndex === "new"}
        <div class="editable-speaker">
            <input class="speaker selected-speaker" type="text" bind:value={speakerEditValue.name} bind:this={activeEdit} on:keydown={handleOkCancelKey}/>
            <div class="speaker-actions">
                <select  class="choose-gender" bind:value={speakerEditValue.gender}>
                    <option value="unknown">(Not set)</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                </select>
                <button on:click={handleEditApply} class="gray-check svg-button-gray squre20-button" disabled={sameNameExists}><SvgCheck/></button>
            </div>
            <button on:click={handleEditCancel} class="svg-button-gray squre20-button"><SvgCloseSmall/></button>
        </div>
    {:else}
        <button on:click={handleNew} class="speaker new-speaker-button" disabled={editSpeakerIndex !== null}>Add new speaker</button>
    {/if}

    
    <button on:click={close} class="speaker ok-button" disabled={editSpeakerIndex !== null}>OK</button>
</div>


<style>
    .manage-speaker {
        min-width: 250px;
        /*height: 161px;*/
        margin: 20px;  
        background: #FFFFFF;
        box-shadow: 2px 2px 6px 2px rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        padding: 8px 16px;
        white-space: normal;
    }
    .speaker-gender {
        font-family: "Segoe UI", Arial, sans-serif;
        font-size: 14px;
        font-weight: 400;
        text-align: right;
    }

    .speaker {
        font-size: 14px;
        font-weight: 600;
        padding: 7px 0;
        color: var(--main-blue);
        justify-content: space-between;
        gap: 10px;
        align-items: center;
        border: 1px solid transparent;
    }

    .speaker:hover {
        cursor: pointer;
    }

    .editing .speaker:hover {
        cursor: default;
    }

    .speaker-actions {
        display: flex;
        gap: 5px;
    }

    .speaker-name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        flex: 0 0 135px;
    }
    .gray-check :global(svg path)  {
        stroke: var(--light-gray);
        fill: none;
    }
    .gray-check:hover :global(svg path)  {
        stroke: var(--hover-blue);
        fill: none;
    }

    .editable-speaker {
        align-items: baseline;
        justify-content: space-between;
        display: flex;
        gap: 5px;
        margin-right: 5px;
    }

    .editable-speaker button{
        display: inline-block;
    }

    .choose-gender {
        border: none;
        height: 25px;
        margin: 0 3px;
    }

    .choose-gender,
    .choose-gender option {
        font-family: "Segoe UI", Arial, sans-serif;
        font-weight: 400;
        font-size: 14px;
    }

    .choose-gender:focus {
        border-radius: 4px;
        outline: 1px solid var(--light-gray);
    }

/* 
    .editable-speaker :global(:nth-child(2)) {
        margin: 0 3px 0 5px;
    } */

    .selected-speaker {
        height: 28px;
        margin: 3px 0 4px 0;
        border-radius: 0px;
        border-bottom: 1px solid var(--light-gray);
        color: var(--text-black);
    }

    .selected-speaker:focus {
        outline: 0;
        outline-offset: 0;

    }

    .new-speaker-button {
        width: 100%;
        background-color: transparent;
        color: var(--text-black);
        text-align: start;
    }

    .ok-button {
        width: 100%;
        background-color: transparent;
        color: var(--text-black);
        justify-content: center;
        font-weight: 600;
        font-size: 12px;
    }

    .new-speaker-button:hover:not(:disabled),
    .ok-button:hover:not(:disabled) {
        color: var(--hover-blue);
    }

    .new-speaker-button:active,
    .ok-button:active {
        background-color: var(--main-blue-op01);
    }

    .new-speaker-button:disabled,
    .ok-button:disabled {
        color: var(--disabled-gray);
    }
</style>