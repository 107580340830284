<script lang="ts">
    import { voiceover } from "../../stores/voiceover";
    import { transcriptChanges } from "../../stores/transcriptChanges";
    export let id:string
    $: num = $voiceover.findIndex(r => r.id === id) + 1;
    $: del = $transcriptChanges.some(c => c.action === "del"  && c.recordId === id)
    $: change = $transcriptChanges.some(c => c.action === "change"  && c.recordId === id)

</script>

<div>
    {num}
</div>

{#if del}
    <div class = "del"></div>
{:else if change}
    <div class = "change"></div>
{/if}


<style>
    .del {
        position: absolute;
        width: 2px;
        background-color: red;
        right:10px;
        top:0;
        bottom: 5px;
    }
    .change {
        position: absolute;
        width: 2px;
        background-color: orange;
        right:10px;
        top:0;
        bottom: 5px;
    }

</style>
