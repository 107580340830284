<script lang="ts">
	import { Router, Route } from "svelte-navigator";
  import RedirectRoute from './navigate/RedirectRoute.svelte'
  import RouteTracker from "./components/RouteTracker.svelte";

  import ProjectsList from './pages/ProjectsList.svelte';
  import Login from "./pages/Login.svelte";
  import Register from "./pages/Register.svelte";
  import Project from "./pages/Project.svelte";
  import CreateProject from "./pages/CreateProject/CreateProject.svelte";
  import ResetPassword from "./pages/ResetPassword.svelte";
  import Sandbox from "./pages/Sandbox.svelte";
  import PrivateRoute from "./navigate/PrivateRoute.svelte";
  import ResetPasswordConfirm from "./pages/ResetPasswordConfirm.svelte";
  import AuthAction from "./pages/AuthAction.svelte";
  import AdminRoute from "./navigate/AdminRoute.svelte";
  import UsersList from "./admin/UsersList.svelte";
  import User from "./admin/User.svelte";
  import Domains from "./admin/Domains.svelte";
</script>

<Router>
  <RouteTracker>
    <AdminRoute path="admin/users"><UsersList /></AdminRoute>
    <AdminRoute path="admin/user/:id" let:params><User id={params.id}/></AdminRoute>
    <AdminRoute path="admin/domains"><Domains /></AdminRoute>

    <PrivateRoute path="project/:id/:lang/*" let:params><Project id={params.id} lang={params.lang}/></PrivateRoute>
    <PrivateRoute path="projects"><ProjectsList /></PrivateRoute>
    <PrivateRoute path="create"><CreateProject /></PrivateRoute>

    <Route path="login"><Login /></Route>
    <Route path="register"><Register /></Route>
    <Route path="reset"><ResetPassword /></Route>
    <Route path="new_password"><ResetPasswordConfirm /></Route>
    <Route path="auth_action"><AuthAction /></Route>
    
    <Route path="sandbox"><Sandbox /></Route>
    <RedirectRoute path="*" to="projects"/>
  </RouteTracker>
</Router>

