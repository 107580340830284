<script lang="ts">
    import { onMount } from "svelte";
    import Portal from "svelte-portal/src/Portal.svelte";
    import Loader from '../LoaderBig.svelte'


    export let element: HTMLElement

    let elementRect: DOMRect

    $: {
        if(divEl && elementRect) {
            const style = `left: ${elementRect.left}px; top: ${elementRect.top}px; width: ${elementRect.width}px; height: ${elementRect.height}px;`
            divEl.setAttribute("style",style)
        }
    } 


    let divEl : HTMLDivElement 

    function updatePosition() {
        elementRect = element.getBoundingClientRect() 
    }

    function getAllparents(el:HTMLElement): HTMLElement[] {
        if(!el.parentElement)
            return []
        return [...getAllparents(el.parentElement),el.parentElement]
    }


    onMount(() =>{
        updatePosition()
        
        var ro = new ResizeObserver(entries => {
            updatePosition()
        });

        ro.observe(element);
        const parentsPath = getAllparents(element)

        for(const parentEl of parentsPath) {
            parentEl.addEventListener("scroll",updatePosition)
        }

        return () => {
            ro.disconnect()

            for(const parentEl of parentsPath) {
                parentEl.removeEventListener("scroll",updatePosition)
            }
        }
    })

</script>

<Portal>
    <div class="block-overlay" bind:this={divEl}>
        <Loader/>
    </div>
</Portal>



<style>
    .block-overlay {
        /*background-color: red;*/
        /*opacity: 0.1;*/
        position: absolute;
        pointer-events: none; 
    }
</style>