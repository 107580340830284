import { DecoratorNode, type LexicalNode, type NodeKey } from "lexical";
import type {ComponentProps, SvelteComponent} from 'svelte';
import RecordTranscriptHeader from "./RecordTranscriptHeader.svelte";

type DecoratorTranscriptHeaderType = {
    componentClass: typeof SvelteComponent;
    props: ComponentProps<RecordTranscriptHeader>;
  };
  

export class TranscriptHeaderNode extends DecoratorNode<DecoratorTranscriptHeaderType> {
  __id:string 
 
    static getType(): string {
      return 'record-transcript-header';
    }
  
    static clone(node: TranscriptHeaderNode): TranscriptHeaderNode {
      return new TranscriptHeaderNode(node.__id, node.__key);
    }
  
    constructor(id:string, key?: NodeKey) {
      super(key);
      this.__id = id
    }

    isIsolated(): boolean { return true; }
/*
    setNumber(num:number):this {
        const self = this.getWritable();
        self.__num = num;
        return self;
    }

    getNumber():number {
        return this.__num;
    }
*/  
    createDOM(): HTMLElement {
        const el = document.createElement('div');
        el.classList.add('voiceover-record-transcript-header');
        el.style.userSelect = 'none';
        return el
    }
  
    updateDOM(): false {
      return false;
    }
  
    decorate(): DecoratorTranscriptHeaderType {
        return {
            componentClass: RecordTranscriptHeader,
            props: {
                id: this.__id
            }
        }
      
    }
  }
  
  export function $createTranscriptHeaderNode(id:string): TranscriptHeaderNode {
    return new TranscriptHeaderNode(id);
  }
  
  export function $isTranscriptHeaderNode(node: LexicalNode): boolean {
    return node instanceof TranscriptHeaderNode;
  }