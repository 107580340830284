<script lang="ts">
    import {getDbAllUsersProfileStore} from "../stores/db"
    import { Link } from "svelte-navigator";
    import {projectId} from "../firebase"
    import { getSourceLanguageById, getTargetLanguageById  } from "../stores/langs";
    import {_adminSetAdmin,_adminSetCredit,_adminSetFreePlan} from "../services/database"

    export let id:string

    const store = getDbAllUsersProfileStore()
    $:user = $store.data?.[id]
    $:projects = Object.values(user?.projects ?? {})

    async function handleToggleAdmin() {
        await _adminSetAdmin(id, !user.admin)
    }

    async function handleAddCredit() {
        await _adminSetCredit(id, user.credits + 10*60)
    }

    async function handleSet0Credit() {
        await _adminSetCredit(id, 0)
    }


    async function handleToggleFreePlan() {
        await _adminSetFreePlan(id, !user.freePlan)
    }

</script>

{#if user}
<div class="container">
    <h1>
        <Link to="../../users"><button>Back</button> </Link>
        User (Admin) {id}
    </h1>
    <h2>Info</h2>
    <table>
        <tr>
            <th>id</th>
            <td>{user.id}</td>
            <td>
                <a href ={`https://console.firebase.google.com/project/${projectId}/database/${projectId}-default-rtdb/data/~2Fusers~2F${user.id}`} target="_blank">
                    <button>Firebase</button>
                </a>
            </td>
        </tr>
        <tr>
            <th>e-mail</th>
            <td>{user.email}</td>
            <td></td>
        </tr>
        <tr>
            <th>credits</th>
            <td>{user.credits}</td>
            <td>
                <button on:click={handleSet0Credit}>0</button>
                <button on:click={handleAddCredit}>+10min</button>
            </td>
        </tr>
        <tr>
            <th>admin</th>
            <td>{user.admin ?? false}</td>
            <td><button on:click={handleToggleAdmin}>toggle</button></td>
        </tr>
        <tr>
            <th>free plan</th>
            <td>{user.freePlan ?? true}</td>
            <td><button on:click={handleToggleFreePlan}>toggle</button></td>
        </tr>
        <tr>
            <th>Registration date</th>
            <td>{user.registrationDate}</td>
            <td></td>
        </tr>
    </table>
    <h2>Projects</h2>
    <table>
        <thead>
            <tr>
                <th>Name</th>
                <th>Source</th>
                <th>Duration</th>
                <th>Created</th>
                <th>Status</th>
                <th>Actions</th>
                
            </tr>
            <tr>
                <th></th>
                <th>Target</th>
                <th>generating</th>
                <th>donwload</th>
                <th>Status</th>
                <th>Actions</th>
            </tr>
        </thead>
        <tbody>
            {#each projects as project}
                <tr>
                    <!--{project.id}-->
                    <td>{project.name}</td>
                    <td>{getSourceLanguageById(project.source)?.displayName ?? project.source}</td>
                    <td>{project.lenght}</td>
                    <td>{new Date(project.created).toLocaleString()}</td>
                    <td>{project.status}</td>
                    <td>
                        <a href = {`https://console.firebase.google.com/project/${projectId}/database/${projectId}-default-rtdb/data/~2Fprojects~2F${project.id}`} target="_blank">
                            <button>Firebase</button>
                        </a>
                    </td>
                </tr>
                {#each Object.values(project.targets ?? {}).sort((a,b) => a.order - b.order) as target}
                    <tr>
                        <td></td>
                        <td>{getTargetLanguageById(target.languageId)?.displayName ?? target.languageId}</td>
                        <td>{target.resultsGenerating}</td>
                        <td>{target.resultsDownload}</td>
                        <td>{target.status}</td>
                        <td>
                            <a href={`/project/${project.id}/${target.languageId}/transcript`} target="_blank"> 
                                <button disabled={project.status==="precreate" || project.status==="creating"}>Open</button>
                            </a>
                        </td>
                    </tr>
                {/each}
            {/each}
        </tbody>
    </table>
    <br>
</div>
{/if}

<style>
    button {
        display: inline-block;
    }
    .container {
        width: 100%;
        height: 100%;
        margin: 10px;
        overflow: auto;
    }
</style>
