<script lang="ts">
    export let progress: number|null = null;
</script>

<div class="progres-border">
    {#if progress !== null}
        <div class="progres-status" style="width: {progress}%"></div>
    {:else}
        <div class="progres-animation"></div>
    {/if}
    
</div>

<style>

.progres-border {
  width:100%;
  height: 10px;
  background-color: var( --disabled-gray);
  border-radius: 2px;
  margin: 16px 0 11px 0;
  overflow: hidden;
}

.progres-status {
  height:10px;
  background-color: var( --main-blue);
  border-radius: 2px;
}

.progres-animation {
  width: 50%;
  height: 100%;
  background-color: var( --main-blue);
  animation: progresAnimation 3s infinite linear;
 }
 
@keyframes progresAnimation {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(200%);
  }
}


</style>