export function debounce(f:(...args:any[])=>any, t:number) {
    let lastCall = undefined
    let lastCallTimer
    return function (...args:any[]) {
      let previousCall = lastCall;
      lastCall = Date.now();
      if (previousCall && ((lastCall - previousCall) <= t)) {
        clearTimeout(lastCallTimer);
      }
      lastCallTimer = setTimeout(() => f(...args), t);
    }
  }

  export function throttle(f:(...args:any[])=>any, t:number) {
    let lastCall = undefined
    return function (...args:any[]) {
      let previousCall = lastCall;
      lastCall = Date.now();
      if (previousCall === undefined // function is being called for the first time
          || (lastCall - previousCall) > t) { // throttle time has elapsed
        f(...args);
      }
    }
  }

  export function debounce2(f:(...args:any[])=>any, t:number) {
    let lastCall = undefined
    let lastCallTimer

    const call = (...args:any[]) => {
      let previousCall = lastCall;
      lastCall = Date.now();
      if (previousCall && ((lastCall - previousCall) <= t)) {
        clearTimeout(lastCallTimer);
      }
      lastCallTimer = setTimeout(() => f(...args), t);
    }

    const cancel = () => {
      clearTimeout(lastCallTimer);
    }

    return {call, cancel}
  }
