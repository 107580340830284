import { readable, type Readable } from 'svelte/store';
import { getDownloads } from "../../services/functions";
import { onProject, deleteGenerationResults } from '../../services/database';
import type { DbTypes, FnTypes } from 'common';
import {getUrl} from "../../services/storage"

export type DownloadResultsRecord = {
    id: string
    name: string
    url: string
    target:string
    status: "UNKNOWN" | "GENERATING" | "READY"

}

type ResultVideo = DbTypes.ResultVideo & {
    id:string
    target:string
}

export type Store = Readable<DownloadResultsRecord[]> & {
    delete: (target:string, resultId:string) =>  void
}

export function createStore(projectId: string, target: string|undefined) : Store {

    const store = readable<DownloadResultsRecord[]>([ ], (set) => {
        let dataFromDb : (ResultVideo & {url?:string}) [] | undefined = undefined
        let dataFromFn : FnTypes.getDownloadsResults | undefined = undefined

        function _mergeResults() {
            if(!dataFromDb && !dataFromFn) {
                set(undefined)
                return
            }

            let res = (dataFromDb??[]).map(db => ({
                id: db.id,
                name: db.name,
                url: db.url,
                target: db.target,
                status: db.generating ? "GENERATING" : "READY"
            } as DownloadResultsRecord))

            for(const fn of dataFromFn ?? []) {
                const rec = res.find(r => r.id === fn.id) 
                if(rec) {
                    rec.url = fn.url
                } 
                /*
                else {
                    res.push({
                        id: fn.id,
                        name: fn.name,
                        target: fn.target,
                        url: fn.url,
                        status: "UNKNOWN"
                    })
                }
                */
            }
            if(target) {
                res = res.filter(d => d.target === target)
            }
            res = res.sort((a,b) => - a.name.localeCompare(b.name))
            set(res)
        }

        async function _getDownloads() {
            dataFromFn = await getDownloads(projectId)
            _mergeResults()
        } 

        const un = onProject(projectId,project => {
            const targets = Object.values(project.targets ?? {})
            dataFromDb = targets.flatMap(t => Object.keys(t.results??{}).map(key => ({...t.results[key], id:key, target:t.languageId, url:undefined })) )
            
            for(const rec of dataFromDb) {
                if(rec.fbFile) {
                    getUrl(rec.fbFile).then(url => {
                        rec.url = url
                        _mergeResults()
                    })
                }
            }

            _mergeResults()
            _getDownloads()
        })

        _getDownloads()
        return un
    })

    return {
        subscribe: store.subscribe,
        delete: (target:string,resultId:string) => {
            deleteGenerationResults(projectId, target, resultId)
        }
    }
}