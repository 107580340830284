<script lang="ts">
   import SvgPlay from "../../assets/play.svg";
   import SvgStop from "../../assets/stop.svg";
   import SpeakerSelector from "../speakerselector/SpeakerSelector.svelte";

   import {playRange, pause, isPlaying} from '../../stores/player'
   
   import { getEditor } from "../../lexer-editor/editorContext";
   import {transcript,speakers,updateSpeakerInfo,addSpeakerInfo} from '../../stores/transcript'
  import { RENAME_SPEAKER_COMMAND, SET_SPEAKER_COMMAND } from "./editor";
    import { onMount } from "svelte";
    import IconButton from "../../controls/IconButton.svelte";

   const editor = getEditor();
   let isEditable = editor.isEditable()


    export let id:string

    let thisPlaying = false

    $: record = $transcript.records.find(r => r.id === id)

    $: speaker = record?.speaker
    $: startTime = record?.words?.[0]?.startTime
    $: endTime = record?.words?.[record?.words.length - 1]?.endTime
    $: duration = endTime - startTime

    $:startTimeStr = startTime?.toFixed(1)
    $:endTimeStr = endTime?.toFixed(1)
    $:durationStr = duration?.toFixed(1)

    async function handlePlay() {
        thisPlaying = true
        await playRange(startTime, endTime)
        thisPlaying = false
    }

    function handleStop() {
      pause()
    }

    //console.log("RecordHeader", id, count, start, end, num, editor)



  function handleAddSpeaker(e: CustomEvent<any>): void {
    addSpeakerInfo(e.detail)
    editor.dispatchCommand(SET_SPEAKER_COMMAND,{recordId:id, speaker:e.detail.name } )
  }
  function handleUpdateSpeaker(e: CustomEvent<any>): void {
    updateSpeakerInfo(e.detail.old, e.detail.new)
    editor.dispatchCommand(RENAME_SPEAKER_COMMAND, {oldName:e.detail.old.name, newName:e.detail.new.name })
  }
  function handleSelectSpeaker(e: CustomEvent<any>): void {
    editor.dispatchCommand(SET_SPEAKER_COMMAND,{recordId:id, speaker:e.detail.name } )
  }
  
  onMount(() => {
    return  editor.registerEditableListener((e:boolean) => isEditable = e)
  })

</script>

<div class="axis-x hh2" style="justify-content: space-between;" dir="ltr">
    <div class="axis-x">
        <SpeakerSelector disabled={!isEditable} selectedSpeaker={speaker} speakers={$speakers} on:addSpeaker={handleAddSpeaker} on:updateSpeaker={handleUpdateSpeaker} on:selectSpeaker={handleSelectSpeaker}/>
        <div style="margin-left: 11px">{startTimeStr} - {endTimeStr} ({durationStr}  sec)</div>
    </div>
    <div class="axis-x">
        {#if thisPlaying}
          <IconButton title="Stop playing" on:click={handleStop}>
            <SvgStop width={20} height={20} viewBox="0 0 10 10" fill="currentColor"/>
          </IconButton>
        {:else}
          <IconButton on:click={handlePlay} disabled={$isPlaying} title="Play">
            <SvgPlay width={20} height={20} viewBox="0 0 10 12" fill="currentColor"/>
          </IconButton>
        {/if}
    </div>
</div>

<style>
</style>