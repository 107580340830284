<script lang="ts">
    import {getDbAllUsersProfileStore} from "../stores/db"
    import { Link } from "svelte-navigator";
    import {projectId} from "../firebase"
    import * as fn from "../services/functions"

    const store = getDbAllUsersProfileStore()
    $: usersList = Object.values($store.data ?? {})

    let users = fn.adminGetUsersList()
    function refresh() {
        users = fn.adminGetUsersList()
    }

    async function downloadCSV() {
        const _users = await users;
        const csvHeader = "name,email,credits,projects count,project credits,registartion,last login,admin,free plan,spam,uid\n"
        const csv = _users.usersList
            .sort((a,b) => b.creationTime - a.creationTime)
            .map(user => {
                return `${user.displayName ?? ""},${user.email},${user.credits},${user.projectsCount},${user.projectsCredits},${new Date(user.creationTime).toLocaleDateString()},${new Date(user.lastSignInTime).toLocaleDateString()},${user.admin},${user.freePlan},${user.spam},${user.uid}`
            }).join("\n")


        const blob = new Blob([csvHeader+csv], {type: 'text/csv'})
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = 'users.csv'
        a.click()
        window.URL.revokeObjectURL(url)
    }

</script>

<div  class="container">
    <h1>Users List (Admin)</h1>

    {#await users}
        loading...
    {:then _users} 
    {@const usersList = _users.usersList.sort((a,b) => b.creationTime - a.creationTime)}
    <button on:click={refresh}>Refresh</button>
    <button on:click={downloadCSV}>Download CSV</button>
    <table>
        <thead>
            <tr>
                <th>name</th>
                <th>e-mail</th>
                <th>credits</th>
                <th>projects</th>
                <th>projects credits</th>
                <th>registartion</th>
                <th>last login</th>
                <th>Actions</th>
            </tr>
        </thead>
        <tbody>

            {#each usersList as user}
                <tr class:notfree={!(user.freePlan ?? true)}>
                    <td>{user.displayName ?? ""}</td>
                    <td>
                        <span>{user.email}</span>
                        {#if user.admin}
                            <span style="color: red; font-weight: normal">(admin)</span>
                        {/if}
                    </td>
                    <td>{user.credits}</td>
                    <td>{user.projectsCount}</td>
                    <td>{user.projectsCredits}</td>
                    <td>{new Date(user.creationTime).toLocaleDateString()}</td>
                    <td>{new Date(user.lastSignInTime).toLocaleDateString()}</td>
                    <td>
                        <Link to={`/admin/user/${user.uid}`}>
                            <button>Details</button>
                        </Link>
                        <a href ={`https://console.firebase.google.com/project/${projectId}/database/${projectId}-default-rtdb/data/~2Fusers~2F${user.uid}`}  target="_blank">
                            <button>Firebase</button>
                        </a>

                    </td>
                </tr>
            {/each}
        </tbody>
    </table>

    {/await}
</div>

<style>
    .notfree {
        font-weight: bold;
    }
    button {
        display: inline-block;
    }
    .container {
        width: 100%;
        height: 100%;
        padding: 10px;
        overflow: auto;
    }
</style>