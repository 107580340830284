import { writable,get, type Writable } from "svelte/store";
import * as db from '../services/database'
import * as storage from '../services/storage'
import * as fn from '../services/functions'
import {downloadYoutube, type YoutubeUpdateProgress} from '../services/youtubedl'
import { authStore } from "./auth";
import {cacheCreatedProject} from './projectslist'
import { reduceExpectedBalance } from "./balance";
import { createConfirmationStore } from "./confirmationStrore";
import type { DbTypes } from "common";


export type CratingStatus = "SELECTING_FILE" | "WAITING" | "UPLOADING_FILE" | "ANALYZING_FILE" | "READY_TO_CREATE"


export type UploadingStatus = {
    fileName : string
    totalBytes : number
    bytesTransferred: number
    progress : number
}

export type UploadedFileInfo = {
    fileName: string,
    duration: number,
    width, height: number,
    audioExists: boolean
}

//export const [status,isStatusPending,statusConfirm] = createConfirmationStore<CratingStatus>("SELECTING_FILE",import.meta.env.PROD) //writable<CratingStatus>("SELECTING_FILE") //import.meta.env.PROD
export const status = writable<CratingStatus>("SELECTING_FILE") 
export const projectName = writable<string>("")
export const projectId = writable<string>("")
export const uploadingStatus = writable<UploadingStatus | null>(null)
export const fileInfo = writable<UploadedFileInfo | null>(null)
export const sourceLanguage = writable<string|null>(null)
export const customDictionary = writable<string>("")

export const targets = (() => {
    const { subscribe, set, update } = writable<string[]>([])
    const add = (item) => update(state => [...state,item])
    const remove = (item) => update(state => state.filter(i => i !== item))
    const clear = () => set([])
    return {
        subscribe,
        add,
        remove,
        clear,
    }

})()

async function analyze(projectId:string, fileName:string) {
    status.set("ANALYZING_FILE")
    fn.analyze(projectId)
    await db.waitForDbPath(`projects/${projectId}/meta`)
    const meta = await db.readDb<DbTypes.Meta>(`projects/${projectId}/meta`)
    console.log("meta",meta)

    const videoStream = meta.streams.find((s:any) => s.codec_type === 'video')
    const audioStream = meta.streams.find((s:any) => s.codec_type === 'audio')
    fileInfo.set({
        fileName,
        duration: meta.format.duration ?? 0,
        width: videoStream?.width ?? -1,
        height: videoStream?.height ?? -1,
        audioExists: !!audioStream
    })
    status.set("READY_TO_CREATE")
}

function removeExtension(filename: string): string {
    const videoFormats = ['mp4', 'avi', 'mkv', 'mov', 'flv', 'wmv', 'webm', 'ogg', 'm4v', '3gp', 'f4v'];
    const extension = filename.split('.').pop();
  
    if (videoFormats.includes(extension)) {
      return filename.slice(0, -extension.length - 1);
    }
  
    return filename;
  }

export async function uploadFile(file) {
    console.log("uploadFile",file)

    const userId = get(authStore).user.uid

    const ext = file.name.substring(file.name.lastIndexOf("."))
    const id = await db.precreateProject(file.name,userId,`sourceVideo${ext}`)
    const sourceVideoFileName = `${id}/sourceVideo${ext}`

    console.log("uploadFile",id,sourceVideoFileName)

    projectId.set(id)
    projectName.set(removeExtension(file.name))
    status.set("UPLOADING_FILE")
    await storage.uploadVideoFile(id,file,sourceVideoFileName,({fileName,totalBytes,bytesTransferred}) => {
        uploadingStatus.set({
            fileName,
            totalBytes,
            bytesTransferred,
            progress : (bytesTransferred / totalBytes) * 100
        })
    })

    await analyze(id,file.name)
} 

export async function createProject(oneClick:boolean) {

    const projectCost = Math.ceil(get(fileInfo)?.duration ?? 0) * get(targets).length
    reduceExpectedBalance(projectCost)

    fn.createProject(get(projectId),get(projectName),get(sourceLanguage),get(targets),get(customDictionary), oneClick)
    cacheCreatedProject(get(projectId),get(projectName),get(fileInfo).duration,get(sourceLanguage),get(targets))
}


export function reset() {
    status.set("SELECTING_FILE")
    projectName.set("")
    projectId.set("")
    targets.clear()
    uploadingStatus.set(null)
    fileInfo.set(null)
    sourceLanguage.set(null)
    customDictionary.set("")
}

export async function urlDownload(url:string) {
    function update(progress: YoutubeUpdateProgress) {
        if(get(status) === "WAITING") {
            projectName.set(progress.name)
            status.set("UPLOADING_FILE")
        } else {
            uploadingStatus.set({
                fileName: progress.name,
                totalBytes: progress.total_bytes,
                bytesTransferred: progress.downloaded_bytes,
                progress : (progress.downloaded_bytes / progress.total_bytes) * 100
            })
    
        }
    }
    status.set("WAITING")

    console.log("urlDownload",url)
    const userId = get(authStore).user.uid
    const id = await db.precreateProject("[Youtube]",userId,``)
    projectId.set(id)
    const fileName = await downloadYoutube(id,url,update)

    await analyze(id,fileName)
}