import { readable, derived, writable } from "svelte/store";
import type {DbTypes} from "common"
import {authStore} from './auth'
import { onUserProfile } from "../services/database";
import type { ProjectPreview, TargetPreview } from "../types/projectPreview";
import { dbUserProfileStore } from "./db";
import { getSourceLanguageById, getTargetLanguageById, sourceLanguages, targetLanguages } from "./langs";



function convertTarget(projectProcessing: boolean) {
    return (target:DbTypes.TargetPreview): TargetPreview => {
        return {
            langCode: target.languageId,
            langName: getTargetLanguageById(target.languageId)?.displayName ?? target.languageId,
            status: target.status,
            processing: (projectProcessing || target.resultsGenerating) ?? false,
            download: target.resultsDownload ?? false,
            order: target.order
        }
    }
}

function convertProject(project:DbTypes.ProjectPreview): ProjectPreview {
    const targets = Object.values(project?.targets ?? {}).map(convertTarget(project.status === "creating")).sort((a,b) => a.order - b.order)
    return {
        id: project.id,
        name: project.name,
        duration: project.lenght,
        _created: project.created,
        created: new Date(project.created).toLocaleDateString(),
        srcLang: getSourceLanguageById(project.source)?.displayName ?? project.source,
        processing: targets.some(t => t.processing),
        download: targets.some(t => t.download),
        targets
    }
}

const cachedAddedTargets = writable<{projectId:string,target:string,order:number}[]>([])
const cachedRemovedTargets = writable<{projectId:string,target:string}[]>([])

const cachedAddedProjects = writable<ProjectPreview[]>([])
const cachedRemovedProjects = writable<string[]>([])

export const projectsListStore = derived([dbUserProfileStore,cachedAddedProjects,cachedRemovedProjects,cachedAddedTargets,cachedRemovedTargets, sourceLanguages,targetLanguages], 
    ([
        $userProfileStore,
        $cachedAddedProjects,$cachedRemovedProjects,
        $cachedAddedTargets,$cachedRemovedTargets
    ]) => {
    let projects = Object.values($userProfileStore.data?.projects ?? {}).map(convertProject) ?? []

    //apply cached added projects
    for(const addedProject of $cachedAddedProjects) {
        if(!projects.find(p => p.id === addedProject.id)) {
            projects.push(addedProject)
        } else {
            cachedAddedProjects.update(t => t.filter(r => r.id !== addedProject.id))
        }
    }

    //apply cached removed projects
    for(const removedProject of $cachedRemovedProjects) {
        if(projects.find(p => p.id === removedProject)) {
            projects = projects.filter(p => p.id !== removedProject)
        } else {
            cachedRemovedProjects.update(t => t.filter(r => r !== removedProject))
        }
    }


    //apply cached added targets
    for(const addedTarget of $cachedAddedTargets) {
        const project = projects.find(p => p.id === addedTarget.projectId)
        if(project) {
            if(project.targets.find(t => t.langName === addedTarget.target)) {
                // already exists
                cachedAddedTargets.update(t => t.filter(r => r.projectId !== addedTarget.projectId && r.target !== addedTarget.target))
            } else {
                project.targets.push({
                    langCode: "",
                    langName: addedTarget.target,
                    status: "created",
                    processing: true,
                    download: false,
                    order: addedTarget.order
                })
            }

        }
    }

    //apply cached removed targets
    for(const removedTarget of $cachedRemovedTargets) {
        const project = projects.find(p => p.id === removedTarget.projectId)
        if(project) {
            if(!project.targets.find(t => t.langName === removedTarget.target)) {
                // already removed
                cachedRemovedTargets.update(t => t.filter(r => r.projectId !== removedTarget.projectId && r.target !== removedTarget.target))
            } else {
                project.targets = project.targets.filter(t => t.langName !== removedTarget.target)
            }
        } 
    }

    return projects.sort((a,b) => b._created - a._created)
    
}, [])

//db: userProfile, sourceLangues, targetLanguages, voices, project

// added project
// removed project
// added target
// removed target

export function cacheCreatedProject(projectId:string, projectName:string, duration:number, sourceLanguage:string, targetLanguages:string[]) {
    const tgts = targetLanguages.map((t,i) => ({
        langCode: t,
        langName: getTargetLanguageById(t)?.displayName ?? t,
        status: "created",
        processing: true,
        download: false,
        order: i,
    }))

    const projectPreview = {
        id: projectId,
        name: projectName,
        duration,
        _created: Date.now(),
        created: new Date().toLocaleDateString(),
        srcLang: getSourceLanguageById(sourceLanguage)?.displayName ?? sourceLanguage,
        processing: true,
        download: false,
        targets: tgts
    }

    cachedAddedProjects.update(t => [...t,projectPreview])
}

export function cacheRemovedProject(projectId:string) {
    console.log('cacheRemovedProject',projectId)
    //creatingProjectStore.set(null)
    cachedAddedProjects.update(t => t.filter(r => r.id !== projectId))
    cachedRemovedProjects.update(t => [...t,projectId])
}

export function cacheAddedTarget(projectId:string, targetId:string , order:number) {
    console.log('cacheAddedTarget',projectId,targetId)
    const target = getTargetLanguageById(targetId)?.displayName ?? targetId
    cachedAddedTargets.update(t => [...t,{projectId,target,order}])
}
export function cacheRemovedTarget(projectId:string, targetId:string) {
    console.log('cacheRemovedTarget',projectId,targetId)
    const target = getTargetLanguageById(targetId)?.displayName ?? targetId
    cachedAddedTargets.update(t => t.filter(r => r.projectId !== projectId && r.target !== target))
    cachedRemovedTargets.update(t => [...t,{projectId,target}])
}
