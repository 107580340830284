import type { DbTypes } from "common"
import { voices } from "../../stores/voiceover"
import { derived, writable } from "svelte/store"
import { getSourceLanguageById } from "../../stores/langs"

export function splitBySearch(text:string, search:string) {
    if((search ?? "") === "") return [text,"",""]

    const i = text.toLowerCase().indexOf(search.toLowerCase())
    if(i === -1) return [text,"",""]
    

    return [
        text.substring(0,i),
        text.substring(i,i+search.length),
        text.substring(i+search.length),
    ]

}


export type Voice = DbTypes.Voice & {
    tags: string[]
    searches: ((search:string)=>boolean)[] 
}

function convert(voice:DbTypes.Voice) : Voice {
    let tags: string[] = [voice.sex,voice.provider]
    
    let searches: ((search:string)=>boolean)[] = [
        search => voice.name.toLowerCase().includes(search.toLowerCase()), //search name
        //search => tags.some(tag => tag.toLowerCase().includes(search.toLowerCase())), //search tags
        search => voice.sex.toLowerCase().startsWith(search.toLowerCase()), //search sex
        search => voice.langCode.toLowerCase().includes(search.toLowerCase()), //search lang code
    ]

    const lang = getSourceLanguageById(voice.langCode)
    if(lang) {
        tags.push(lang.displayName)
        searches.push(
            search => lang.displayName.toLowerCase().includes(search.toLowerCase()), //lang display name
        )
    } else {
        tags.push(voice.langCode)
    }

    
    return {
        ...voice,
        tags,
        searches
    }
}

function filterVoices(voices:Voice[], search : string, tags: string[]) : Voice[] {
    const allTagsPresetn = (ts:string[]) => tags.every(t => ts.includes(t)) 

    const voiceSearches = voices.filter(v => v.searches.some(s => s(search)))
    const voicesFiltered = tags.length === 0 ? voiceSearches : voiceSearches.filter(v => allTagsPresetn(v.tags) )
    

    return voicesFiltered
}

export const search = writable("")

function createTagsStore(init: string[]) {
    const store = writable(init)
    return {
        subscribe: store.subscribe,
        add: (tag:string) => store.update(s => ([...s,tag]).filter((v,i,a) => a.indexOf(v) === i)),
        del: (tag:string) => store.update(s => s.filter(t => t != tag)),
        clear: () => store.set([])
    }
}

export const searchTags = createTagsStore([])

export const filteredVoices = derived([voices,search,searchTags],([$voices,$search,$searchTags]) => filterVoices($voices.map(convert),$search,$searchTags))