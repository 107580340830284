<script lang="ts">
    import { getContext } from "svelte";
    import {timelineStoreSymbol,timesampToScreen,durationToScreen,screenToTimestamp,screenToDuration, getVisibleRange} from "./store";
    import type {TimelineStore} from "./store";
    import Waveform from "./Waveform.svelte";
    import { pannable } from "../utils/pannable";
    import { getMousePosition } from "./MouseTrack.svelte";
    import FragmentPopup from "./FragmentPopup.svelte";
    import { clickOutside } from "../utils/clickOutside";
    import type {Fragment} from "../types/fragment"

    export let waveformData: Float32Array
    export let waveformCleanedData : Float32Array
    export let readonly = false;

    const ctx = getContext<TimelineStore>(timelineStoreSymbol);

    // selecting | sected | fragment


    type Selection = {
        type: "selecting",
        start: number,
        end: number,
        $left: number,
        $width: number,
        limitLeft: number,
        limitRight: number,
     } | {
        type: "selected",
        start: number,
        duration: number,
        popupPos: {x: number, y: number},
     } | {
        type: "fragment",
        fragment: string,
        _start: number,
        _end: number,
        start: number,
        end: number,
        limitLeft: number,
        limitRight: number,
        popupPos: {x: number, y: number} | null, 
     } | null

    const samplesPerSec = 1000;

    $: visibleRange = getVisibleRange($ctx);

    let selection: Selection = null
    let inAction = false


    export let fragments: Fragment[] = []



    function handleSelectingStart(event) {
        //console.log("handlePanStart",event)

        const x = screenToTimestamp($ctx,event.detail.x - event.target.getBoundingClientRect().x)

        const limitLeft = Math.max(0,...fragments.map(f => f.start+f.duration).filter(e => e <= x))
        const limitRight = Math.min($ctx.duration,...fragments.map(f => f.start).filter(s => s >= x))

        selection = {type: "selecting", start: x, end: x,$left:x, $width:0,limitLeft,limitRight}
	}

	function handleSelectingMove(event) {
        if(selection?.type !== "selecting") return
        //console.log("handlePanMove",event)

        let x = screenToTimestamp($ctx,event.detail.x - event.target.getBoundingClientRect().x)
        if(x < selection.limitLeft) x = selection.limitLeft
        if(x > selection.limitRight) x = selection.limitRight


        const $left = Math.min(timesampToScreen($ctx,selection.start),timesampToScreen($ctx,x))
        const $width = Math.abs(timesampToScreen($ctx,x) - timesampToScreen($ctx,selection.start))
        selection = {...selection, end: x, $left,$width}
	}

	function handleSelectingEnd(event) {
        //console.log("handlePanEnd",event)
        if(selection?.type !== "selecting") return

        if(selection.$width > 10) {
            selection = {
                type: "selected", 
                start: Math.min(selection.start,selection.end), 
                duration: Math.abs(selection.end - selection.start),
                popupPos: getMousePosition()
            }
        } else {
            selection = null
        }
}

  function selectFragment(id,popupPos=null) {
    const fragment = fragments.find(f => f.id === id)
    if(!fragment) return

    if(selection?.type === "fragment" && selection.fragment === id) return

    const otherFragments = fragments.filter(f => f.id !== id)
    const limitLeft = Math.max(0,...otherFragments.map(f => f.start+f.duration).filter(l => l <= fragment.start))
    const limitRight = Math.min($ctx.duration,...otherFragments.map(f => f.start).filter(l => l >= fragment.start + fragment.duration))

    console.log("selectFragment",id)

    selection = {
        type: "fragment", 
        fragment: id, 
        start: fragment.start, 
        end: fragment.start + fragment.duration,
        limitLeft,
        limitRight,
        _start: fragment.start,
        _end: fragment.start + fragment.duration,
        popupPos: popupPos ?? getMousePosition()
    }
  }
    
  function handleSelectFragment(id:string) {
    return () => selectFragment(id)
  }


  function createFragment(e: { detail: { cleaned: boolean; volume: number; }; }) {
    if(selection?.type !== "selected") return
    console.log("createFragment",e)
    const fragment = {start: selection.start, duration: selection.duration, id: crypto.randomUUID(), cleaned: e.detail.cleaned, volume: e.detail.volume }
    fragments = [...fragments,fragment]
    selectFragment(fragment.id,selection.popupPos)
  }

  function deleteFragment(fragmentId:string, e: { detail: { cleaned: boolean; volume: number; }; }) {
    console.log("deleteFragment",fragmentId)
    const fragment = fragments.find(f => f.id === fragmentId)
    console.log("deleteFragment",fragment)
    fragments = fragments.filter(f => f.id !== fragmentId)
    if(selection?.type === "fragment") 
      selection = {type:"selected",start: fragment.start, duration: fragment.duration, popupPos: selection?.popupPos}
    else
      selection = null
    
  }

  function handleUpdateFragment(fragmentId:string, e: { detail: { cleaned: boolean; volume: number; }; }) {
    console.log("handleUpdateFragment",fragmentId,e.detail)
    fragments = fragments.map(f => f.id === fragmentId ? {...f, cleaned: e.detail.cleaned, volume: e.detail.volume} : f)
  }

  function handleLeftGripStart(event) {
    inAction = true
  }

  function handleLeftGripMove(event) {
    if(selection?.type !== "fragment") return
    const _start = selection._start + screenToDuration($ctx,event.detail.dx)
    let start = Math.max(_start,selection.limitLeft)
    if(durationToScreen($ctx,selection.end - start) < 10) start = selection.end - screenToDuration($ctx,10)
    selection = {...selection, start, _start}
  }

  function handleLeftGripDone() {
    if(selection?.type !== "fragment") return
    inAction = false
    const fragmentId = selection.fragment
    const start = selection.start
    const duration = selection.end - selection.start
    fragments = fragments.map(f => f.id === fragmentId ? {...f, start, duration} : f)
  }  

  function handleRightGripStart(event) {
    inAction = true
  }

  function handleRightGripMove(event) {
    if(selection?.type !== "fragment") return
    const _end = selection._end + screenToDuration($ctx,event.detail.dx)
    let end = Math.min(_end,selection.limitRight)
    if(durationToScreen($ctx,end - selection.start) < 10) end = selection.start + screenToDuration($ctx,10)
    selection = {...selection, end,_end}
  }

  function handleRightGripDone() {
    if(selection?.type !== "fragment") return
    inAction = false
    const fragmentId = selection.fragment
    const start = selection.start
    const duration = selection.end - selection.start
    fragments = fragments.map(f => f.id === fragmentId ? {...f, start, duration} : f)
  }

  function handleClickOutside() {
    if(/*selection?.type !== "selected" &&*/ !selection?.["popupPos"]) {
      selection = null
      //console.log("click outside")
    }
        
  }

  function handleFragmentmenu(event: MouseEvent & { currentTarget: EventTarget & HTMLDivElement; }) {
    //console.log("handleFragmentmenu",event)
    if(selection?.type !== "fragment") return
    selection = {...selection, popupPos: getMousePosition()}
    event.preventDefault()
  }

  function handleClosePopup() {
    if(selection?.type === "fragment") {
      selection = {...selection, popupPos: null}
    }
    if(selection?.type === "selected") {
      selection = null
    }
    
  }
</script>

            <!-- svelte-ignore a11y-click-events-have-key-events -->
{#if readonly}
  <div class="waveform">
    <Waveform data = {waveformData} samplesPerSec={samplesPerSec} bandWidth={6} spaceWidth={10} start={visibleRange.start} duration={visibleRange.end - visibleRange.start} pixelsPerSec={$ctx.zoom}/>
  </div> 
{:else}
<div class="waveform" 
    class:in-cation={inAction || selection?.type === "selecting" || selection?.type === "selected"}
    use:pannable 
    on:panstart={handleSelectingStart}
    on:panmove={handleSelectingMove}
    on:panend={handleSelectingEnd}
    use:clickOutside
    on:click_outside={handleClickOutside}
    >
    <Waveform data = {waveformData} samplesPerSec={samplesPerSec} bandWidth={6} spaceWidth={10} start={visibleRange.start} duration={visibleRange.end - visibleRange.start} pixelsPerSec={$ctx.zoom}/>

    {#if selection?.type === "selected"}
        <div class="fragment" style="left: {timesampToScreen($ctx,selection.start)}px; width:{durationToScreen($ctx,selection.duration)}px; background-color:#1976D2; opacity:0.5;">
        </div>
        <FragmentPopup showPopup={true} popupPos={selection.popupPos} volume={0} cleaned={false} on:update={createFragment} creaing={true} on:close={handleClosePopup}/>
    {/if}

    {#each fragments as fragment}
        {#if selection?.type === "fragment" && selection.fragment === fragment.id} 
            {@const start = timesampToScreen($ctx,selection.start)}
            {@const duration = durationToScreen($ctx,selection.end-selection.start)}
            <div class="fragment selected" use:pannable style="left: {start}px; width:{duration}px;" on:contextmenu={handleFragmentmenu}>
                <Waveform data = {waveformCleanedData} samplesPerSec={samplesPerSec} bandWidth={6} spaceWidth={10} start={selection.start} duration={selection.end-selection.start} pixelsPerSec={$ctx.zoom}/>
                <div class="handle left" use:pannable on:panstart={handleLeftGripStart} on:panmove={handleLeftGripMove} on:panend={handleLeftGripDone}></div>
                <div class="handle right" use:pannable on:panstart={handleRightGripStart} on:panmove={handleRightGripMove} on:panend={handleRightGripDone}></div>
            </div>
            <FragmentPopup showPopup={selection.popupPos !== null} popupPos={selection.popupPos} volume={fragment.volume} cleaned={fragment.cleaned} on:update={(e) => handleUpdateFragment(fragment.id,e)} on:delete={(e)=>deleteFragment(fragment.id,e) } on:close={handleClosePopup} />
        {:else}
            <div class="fragment" use:pannable style="left: {timesampToScreen($ctx,fragment.start)}px; width:{durationToScreen($ctx,fragment.duration)}px;" on:panstart={handleSelectFragment(fragment.id)}>
                <!--<Waveform data = {dataClean} samplesPerSec={samplesPerSec} bandStyle="background-color:#CCCCCC;opacity:0.5;" backgroundStyle="background-color:#1976D2;opacity:0.1;" bandWidth={1} spaceWidth={0} start={fragment.start} duration={fragment.duration} pixelsPerSec={$ctx.zoom}/>-->
                <Waveform data = {waveformCleanedData} samplesPerSec={samplesPerSec} bandWidth={6} spaceWidth={10} start={fragment.start} duration={fragment.duration} pixelsPerSec={$ctx.zoom}/>
            </div>
        {/if}
    {/each}

    {#if selection?.type === "selecting"}
        <div class="creating" style="left:{selection.$left}px; width:{selection.$width}px;"></div>
    {/if}
</div>
  
{/if}            

<style>
    .waveform {
        position: relative;
        width: 100%;
        height: 74px;
    }
    .fragment {
        position: absolute;
        height: 100%; 
        top:0px; 
        background-color:#E8F1FB;
    }
    :not(.in-cation) .fragment:hover {
        background-color:#8CBBE9;
    }

    .fragment.selected {
        background-color:#8CBBE9;
        z-index:1;
    }

    .handle {
        position: absolute;
        top: 0px;
        height: 100%;
        width: 10px;
        visibility: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .handle::after {
        content: "";
        position: absolute;
        height: 32px;
        width: 4px;
        border-radius: 4px;
        background-color:  #1976D2;
        cursor: ew-resize;
    }
    .handle.left {
        left: 0px;
        margin-left: -5px;
    }
    .handle.right {
        right: 0px;
        margin-right: -5px;
    }

    .fragment.selected .handle {
        visibility: visible;
    }

    .creating {
        position: absolute;
        top: 0px;
        height: 100%;
        background-color:  #1976D2;
        opacity: 0.5;

    }

</style>