<script lang="ts">
   	import { Link } from "svelte-navigator";
    import ProjectLayout from "../layouts/Project.svelte";
    import VoiceoverTimeline from "../components/timelines/VoiceoverTimeline.svelte";
    import { project } from "../stores/project";
  import VideoPlayer from "../components/VideoPlayer.svelte";
  // @ts-ignore
  import SvgAutotranslate from "../assets/autotranslate.svg";  
  // @ts-ignore
  import SvgExport from "../assets/export.svg";  
  // @ts-ignore
  import SvgImport from "../assets/import.svg";  

</script>

<ProjectLayout>
    <div slot="title" class="title-project-page axis-x">
        <Link to="../transcript"><button>Back</button></Link> 
        <button class="button-white" style="margin-left: 32px"><SvgAutotranslate/><span>&#032;Autotranslate</span></button>
        <span class="page-header">
          Translate
          Project {$project.projectId}-{$project.targetCode}
        </span>
        <button class="button-white"><SvgExport/><span>Export</span></button>
        <button class="button-white" style="margin-left: 32px" disabled><SvgImport/><span>Import</span></button>
        <Link to="../voiceover"><button style="margin-left: 32px">Next</button></Link>    
    </div>
    <VoiceoverTimeline slot="timeline"/>
    <VideoPlayer slot="player"/>
</ProjectLayout>    