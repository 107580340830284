<script lang="ts">
    import { getContext } from "svelte";
    import {timelineStoreSymbol} from "./store";
    import type {TimelineStore} from "./store";

    export let className = ""

    const ctx = getContext<TimelineStore>(timelineStoreSymbol);

    function handleClick() {
        ctx.zoomIn();
    }

</script>

<button on:click={handleClick} style="pointer-events:all;" class={className}>
    <slot>
        ZoomIn
    </slot>
</button>