<script lang="ts">
  import { onMount } from "svelte";
  import { getEditor } from "./editorContext";

  export let className = "editor-class" 

  const editor = getEditor();
  let editorEl

  let editable = editor.isEditable()

  

  onMount(()=>{
    editor.setRootElement(editorEl);
    return  editor.registerEditableListener((e:boolean) => editable = e)
  })
</script>

<div bind:this={editorEl} contenteditable={editable} class={className}/>