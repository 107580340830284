<script lang="ts">
  import { overlaps, rewriteLonger, rewriteShorter, translateRecord, type VoiceoverRecord } from "../../stores/voiceover";
  import SpeakerSelector from "../speakerselector/SpeakerSelector.svelte";
  import { voiceover ,speakerVoices, speakers,generateSound,getSoundUrl,addSpeakerInfo,updateSpeakerInfo } from "../../stores/voiceover";
  import SvgPlay from "../../assets/play.svg";
  import SvgStop from "../../assets/stop.svg";
  
  import { getEditor } from "../../lexer-editor/editorContext";
  import { getRecordKeyById, RENAME_SPEAKER_COMMAND, SET_SPEAKER_COMMAND, SET_TRANSLATION_COMMAND } from "./editor";
  import {playRange, pause, isPlaying, playSound} from '../../stores/player'
  import SelectVoiceDialog from "../../dialogs/SelectVoice/SelectVoice.svelte";
  import { voiceoverEditorStore } from "./store";
  import { onMount } from "svelte";
  import IconButton from "../../controls/IconButton.svelte";
  import SvgTranslate from "../../assets/translate_blue.svg";
  import SvgLonger from "../../assets/plus_blue.svg";
  import SvgShorter from "../../assets/minus_blue.svg";
  import SvgPlayOutline from "../../assets/play_blue_outline.svg";
  import Logo from "../../controls/Logo.svelte";

  export let id: string

  const editor = getEditor();
  let isEditable = editor.isEditable()

  let disabled = false

  $: record = $voiceover.find(r => r.id === id)
  
  $: overlap = $overlaps.some(o => o.rec2.id === id)
  
  let thisPlaying = false
  let thisTTsPlaying = false

  let showSelectDialog: boolean = false;
  let postponedGenration: boolean = false

  $: empty = (record?.voiceoverText ?? "").trim() === ""

  $: if(postponedGenration && record?.voiceDefined) {
    postponedGenration = false
    generateSound(record.id)
  }
  
  async function handlePlay() {
    thisPlaying = true
    await playRange(record.transcript.startTime, record.transcript.endTime)
    thisPlaying = false
  }

  function handleStop() {
      pause()
  }


  async function handleTranslate() {
    
    const key = getRecordKeyById(editor,id)
    if(!key) return
    const el = editor.getElementByKey(key)
    voiceoverEditorStore.add(el)
    disabled=true
    try {
      const newText = await translateRecord(id)
      editor.dispatchCommand(SET_TRANSLATION_COMMAND,{recordId:id, translation: newText } )
    } finally {
      disabled=false
      voiceoverEditorStore.remove(el)
    }
  }

  async function handlePlayTTS() {
    console.log("handlePlayTTS",record)
    thisTTsPlaying = true
    const url = getSoundUrl(record.id)
    await playSound(url,record.startTime)
    thisTTsPlaying = false
  }

  function handleGenerate() {
    if(record?.voiceDefined)
      generateSound(record.id)
    else {
      postponedGenration = true
      showSelectDialog = true
    }
      
  }

  async function handleRewriteShorter() {
    const key = getRecordKeyById(editor,id)
    if(!key) return
    const el = editor.getElementByKey(key)
    voiceoverEditorStore.add(el)
    disabled=true
    try {
      const newText = await rewriteShorter(id)
      editor.dispatchCommand(SET_TRANSLATION_COMMAND,{recordId:id, translation: newText } )
    } finally {
      disabled=false
      voiceoverEditorStore.remove(el)
    }

  }

  async function handleRewriteLonger() {
    const key = getRecordKeyById(editor,id)
    if(!key) return
    const el = editor.getElementByKey(key)
    voiceoverEditorStore.add(el)
    disabled=true
    try {
      const newText = await rewriteLonger(id)
      editor.dispatchCommand(SET_TRANSLATION_COMMAND,{recordId:id, translation: newText } )
    } finally {
      disabled=false
      voiceoverEditorStore.remove(el)
    }
  }

  function handleAddSpeaker(e: CustomEvent<any>): void {
    addSpeakerInfo(e.detail)
    editor.dispatchCommand(SET_SPEAKER_COMMAND,{recordId:id, speaker:e.detail.name } )
  }
  function handleUpdateSpeaker(e: CustomEvent<any>): void {
    updateSpeakerInfo(e.detail.old, e.detail.new)
    editor.dispatchCommand(RENAME_SPEAKER_COMMAND, {oldName:e.detail.old.name, newName:e.detail.new.name })
  }
  function handleSelectSpeaker(e: CustomEvent<any>): void {
    editor.dispatchCommand(SET_SPEAKER_COMMAND,{recordId:id, speaker:e.detail.name } )
  }


  onMount(() => {
    return  editor.registerEditableListener((e:boolean) => isEditable = e)
  })
   
    //export let record: VoiceoverRecord
</script>

<div class="voiceover-record-header axis-x" class:disabled={disabled} class:header-overlap={overlap} dir="ltr">
    <!-- <SpeakerSelector selectedSpeaker={record.speaker} speakers={$speakerVoices.map(sv => sv.speaker)}/> -->
    <SpeakerSelector disabled={disabled || !isEditable} selectedSpeaker={record?.speaker} speakers={$speakers} on:addSpeaker={handleAddSpeaker} on:updateSpeaker={handleUpdateSpeaker} on:selectSpeaker={handleSelectSpeaker}/>
    <div class="axis-x" style="gap:2px;">
        {#if thisPlaying}
          <IconButton disabled={disabled} title="Stop playing" on:click={handleStop}>
            <SvgStop width={20} height={20} viewBox="0 0 10 10" fill="currentColor"/>
          </IconButton>
        {:else}
          <IconButton disabled={$isPlaying || disabled || !record?.transcript} title="Play original" on:click={handlePlay}>
            <!--<SvgPlay width={20} height={20} viewBox="0 0 10 12" fill="currentColor"/>-->
            <SvgPlayOutline width={20} height={20} viewBox="0 0 28 28"/>
          </IconButton>
        {/if}
        <IconButton disabled={disabled || !isEditable} title="Translate" on:click={handleTranslate}>
          <SvgTranslate width={20} height={20} viewBox="0 0 24 24" fill="currentColor"/>
        </IconButton>
        <IconButton disabled={(record?.voiceoverText?.trim() ?? "") === "" || disabled || !isEditable} title="Rewrite shorter" on:click={handleRewriteShorter}>
          <SvgShorter  width={20} height={20} viewBox="0 0 32 32" fill="currentColor"/>
        </IconButton>
        <IconButton disabled={(record?.voiceoverText?.trim() ?? "") === "" || disabled || !isEditable} title="Rewrite longer" on:click={handleRewriteLonger}>
          <SvgLonger width={20} height={20} viewBox="0 0 32 32" fill="currentColor"/>
        </IconButton>

        {#if record?.soundReady}
          {#if thisTTsPlaying}
            <IconButton disabled={disabled} title="Stop playing" on:click={handleStop}>
              <SvgStop width={20} height={20} viewBox="0 0 10 10" fill="currentColor"/>
            </IconButton>
          {:else}
            <IconButton on:click={handlePlayTTS} disabled={$isPlaying || disabled} title="Play TTS">
              <SvgPlay width={20} height={20} viewBox="0 0 10 12" fill="currentColor"/>
            </IconButton>
          {/if}

        {:else}
          {#if record?.generatingSound}
            <div style="padding:2px;">
              <Logo animate={true}/>
            </div>
          {:else}
            <IconButton disabled={empty || disabled || !isEditable} on:click={handleGenerate} title="Generate">
              <Logo/>
            </IconButton>
          {/if}
        {/if}
    </div>
</div>

<SelectVoiceDialog bind:show={showSelectDialog} speaker={record?.speaker} />

<style>
  .header-overlap::before{
    position: absolute;
    top: -10.5px;
    content: "";
    width: 100%;
    height: 2px;
    background-color: red;
    opacity: 0.2;
  }
</style>
