<script lang="ts">
    import { onMount } from "svelte";
    import { Link, useNavigate, useLocation } from "svelte-navigator";

    const navigate = useNavigate()
    const location = useLocation()

    onMount(() => {
        const searchParams = new URLSearchParams($location.search);
        const mode = searchParams.get("mode") 
        const code = searchParams.get("oobCode")

        if(mode === "resetPassword") {
            navigate("/new_password", {state:{oobCode:code}} )
            return
        }

        navigate( "/")
    })

</script>