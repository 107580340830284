<script lang="ts">
import Dialog from "../components/Dialog.svelte";
import * as fn from "../services/functions";


export let show: boolean = true;

let feedback = ""
$:disableSend = feedback.trim().length === 0 

function sendFeedback() {
    fn.sendFeedback(feedback)
    feedback = ""
    show = false
}

</script>

<Dialog bind:show={show} title="Not enough minutes. Add more minutes">
    Need more credits to process more videos? Please share the feedback about the current state of the service and the features that you might need.  <br/>
    Tell us how much credits you need to complete your research. We will review your request and get back to you. <br/>
    <br/>
    <textarea bind:value={feedback} rows="5" cols="33"></textarea>
    <button disabled={disableSend} on:click={sendFeedback}>Send</button>
</Dialog>


<style>
    textarea {
        width: 100%
    }
</style>