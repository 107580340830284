<script lang="ts">
  import AuthLayout from "../layouts/Auth.svelte"
  import { Link, useNavigate, useLocation } from "svelte-navigator";
  import { authStore } from "../stores/auth";
  import {firebaseErrors} from "../services/firebase_errors"
  import {logSignUp} from "../services/analytics";


  let email:string = ""
  let password1:string = ""
  let password2:string = ""

  let acceptTerms = false
  let error = ""

  const navigate = useNavigate()
  const location = useLocation()


  $: canRegister = email.length > 0 && password1.length > 0 && password2.length > 0 && acceptTerms

  async function register() {
      if(password1 !== password2) {
        error = "Password are different"
        return
      }
      
      try {
        await authStore.register(email,password1)
        logSignUp("email")
        navigate($location.state?.from ?? "/")
      } catch (e) {
        error = firebaseErrors[e.code] ?? e.message ?? "Internal error"
    }
  }
</script>

<AuthLayout>
    <div  class="account-caption">Create account</div>
            
    <input 
        type="text" 
        bind:value = {email}
        class="account-input" 
        placeholder="Email"
    />
    <input 
        type="password" 
        bind:value = {password1}
        class="account-input" 
        placeholder="Password"
    />
    <input 
        type="password" 
        bind:value = {password2}
        class="account-input" 
        placeholder="Repeat password"
    />

    <span style="color:red">{error}</span>
    
    <div class="check-group">
        <input 
            class="custom-checkbox" 
            type="checkbox" 
            id="remember"
            bind:checked = {acceptTerms}
        />
        <label class="text" for="remember"> 
            <span>
            I agree to the{" "}
            <a href="https://dubwiz.com/terms" target="_blank" rel="noopener noreferrer">Terms of Service</a> 
        </span>
        </label>
    </div>
    
    <button type="button" on:click={register} disabled={!canRegister} class="full-button">Register</button>
    <div class="new-account">
      <span style="margin-right: 5px;">Already have an account?</span>
      <Link to="/login">
          <button  type="button" class="button-small-blue-text">Sign In</button>
      </Link>
    </div>

    <div class="div-line-center"></div>
    <div style=" display: flex; justify-content: center;">
        <Link to="/reset">
            <button type="button" class="button-small-blue-text" style="color: black">Forgot password?</button>
        </Link>
    </div>
</AuthLayout>

<style>

.account-caption {
  font-size: 18px;
  margin-top: 12px;
  text-align: center;
}

.account-input {
  width: 336px;
  height: 40px;
  margin-top: 16px;
  border: 1px solid var(--light-gray);
  border-radius: 4px;
  padding: 9px 12px 7px 12px;
}

.check-group {
  display: flex;
  align-items: center;
  margin: 16px 0 20px 0;
}

.full-button {
  width: 100%;
  display: inline-block;
  margin-bottom: 16px;
}

.new-account {
  text-align: center;
}
.new-account *{
  display: inline-block;
  font-size: 14px;
}

.div-line-center {
  width: 150px;
  height: 1px;
  background-color: var(--light-gray);
  opacity: 0.1;
  display: flex;
  margin: 16px 0 0 93px;
  justify-content: center;

}

</style>