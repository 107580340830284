<script lang="ts">
    import Portal from "svelte-portal/src/Portal.svelte";
    import { createEventDispatcher } from 'svelte';
    import { clickOutside } from "../utils/clickOutside";
    import Slider from "../components/Slider.svelte";

      // @ts-ignore
      import SvgMagic from "../assets/magic.svg";
      import SvgReset from "../assets/bx_reset.svg";

    export let volume:number;
    export let cleaned:boolean;

    function formatValue(value) {
        if (value === 0) return "0.0";
        if (value < 0) {
            return value.toFixed(1);
        }
        return "+" + value.toFixed(1);
    }  

    $: volumeStr = formatValue(volume);

    const dispatch = createEventDispatcher();

    export let showPopup:boolean = false;
    export let popupPos = { x: 0, y: 0 };
    export let creaing: boolean = false;


    function close() {
        dispatch("close");
    }

    function deleteFragment() {
        cleaned = false
        volume = 0;
        dispatch("delete");
    }

    function update() {
        dispatch("update",{volume,cleaned});
    }

    function handleMagicTogle() {
        cleaned = !cleaned;
        update();
    }


    

</script>

{#if showPopup}
    <Portal>
        <div use:clickOutside on:click_outside={close} class="popup" style="top: {popupPos.y}px; left: {popupPos.x}px;">
            <div class="axis-x" style="justify-content: space-between;">
                <button class="svg-button squre26-button" class:checked={cleaned} style="width:20px; height:20px;" on:click={handleMagicTogle}><SvgMagic/></button>
                <button class="svg-button squre26-button" disabled={creaing} style="width:20px; height:20px;" on:click={deleteFragment}><SvgReset/></button>
            </div>
            <div class="axis-x" style="justify-content: space-between; padding:3px 1px 0 2px;">
                <Slider min={-1} max={1} bind:value={volume} step={0.1} width="48px" on:change={update}/>
                <span style="margin-top: -5px; padding-left:2px;">{volumeStr}</span>
            </div>
        </div>
    </Portal>
{/if}

<style>
    .popup {
        position: absolute;
        /*width: 76px;
        height: 41px;*/
        width: 86px;
        background-color: white;
        box-shadow: 2px 2px 6px 2px rgba(0, 0, 0, 0.15);
        border-radius: 4px;        
        padding: 3px;
        z-index: 20;
    }
</style>