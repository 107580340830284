<script lang="ts">
    import RecordCollapsed from "./RecordCollapsed.svelte";
    import RecordExpanded from "./RecordExpanded.svelte";
    import type { ProjectPreview } from "../../types/projectPreview";


    export let project: ProjectPreview;
    export let odd: boolean;
    export let expanded: boolean = false;

    function handleExpand() {
        expanded = true;
    }

    function handleCollapse() {
        expanded = false;
    }

</script>

{#if expanded}
    <RecordExpanded {project} {odd} on:collapse={handleCollapse} on:popup_open on:popup_close/>
{:else} 
    <RecordCollapsed {project} {odd} on:expand={handleExpand}/>
{/if}