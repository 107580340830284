import {ElementNode,type LexicalNode, type NodeKey, type SerializedElementNode, type Spread} from 'lexical';

export type SerializedRecordTextNode = Spread<
  {
    type: 'record-text';
    version: 1;
  },
  SerializedElementNode
>;


export class RecordTextNode extends ElementNode {
  static getType(): string {
    return 'record-text';
  }

  static clone(node: RecordTextNode): RecordTextNode {
    return new RecordTextNode(node.__key);
  }

  constructor(key?: NodeKey) {
    super(key);
  }

  createDOM(): HTMLElement {
    // Define the DOM element here
    const dom = document.createElement('div');
    dom.classList.add("record-text")
    return dom;
  }

  updateDOM(prevNode: RecordTextNode, dom: HTMLElement): boolean {
    // Returning false tells Lexical that this node does not need its
    // DOM element replacing with a new copy from createDOM.
    return false;
  }

  exportJSON(): SerializedRecordTextNode {
    return {
      ...super.exportJSON(),
      type: 'record-text',
      version: 1,
    };
  }

}

export function $createRecordTextNode(): RecordTextNode {
  return new RecordTextNode();
}
  
export function $isRecordTextNode(node: LexicalNode): boolean {
  return node instanceof RecordTextNode;
}