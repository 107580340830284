<script lang="ts">
    import { onMount } from "svelte";
    import AppBar from "../AppBar.svelte";
    import { loadingStore } from "../stores/loading";
    import LoaderBig from "../components/LoaderBig.svelte";
    onMount(() => console.log("AppLayout mounted"))
  </script>


{#if $loadingStore.firstLoading}
    <LoaderBig/>
{:else}
    <AppBar/>
    <div class="content">
        {#if $loadingStore.loading}
            <LoaderBig/>
        {:else}
            <slot></slot>
        {/if}
    </div>
{/if}



<style>
    .content {
        position: relative;
        background-color: white;
        flex-grow: 1;
        margin: 32px;
        border-radius: 12px 12px 12px 12px;
        font-family: "Segoe UI", Arial, sans-serif;
        font-weight: 600;
        overflow: hidden;
        width:100%;
        /* height:100%; */
    }
</style>
