<script lang="ts">
    import SvgPlayBig from "../../assets/play_big.svg";
    import SvgPauseBig from "../../assets/pause_big.svg"; 
    import type { Writable } from "svelte/store"
    import { onDestroy, onMount } from "svelte";


    export let audioUrl:string
    export let currentPlaying:Writable<HTMLAudioElement> 

    
    let audioRef : HTMLAudioElement


    onDestroy(stop)


    function play() {
        function handleEnded() {
            $currentPlaying = undefined;
            audioRef.removeEventListener("ended", handleEnded);
        }

        $currentPlaying = audioRef;
        audioRef.addEventListener("ended", handleEnded);
        audioRef.play();
    }

    function stop() {
        if ($currentPlaying === audioRef) {
            audioRef.pause();
            audioRef.currentTime = 0;
            $currentPlaying = undefined;
        }

    }


</script>

{#key audioUrl}
    <audio bind:this={audioRef}>
        <source src={audioUrl} type="audio/mpeg">
    </audio>
{/key}


{#if $currentPlaying === audioRef}
    <button on:click|stopPropagation={stop} class="svg-button squre26-button"><SvgPauseBig/></button>
{:else  }
    <button on:click|stopPropagation={play} disabled={!!$currentPlaying} class="svg-button squre26-button"><SvgPlayBig/></button>
{/if}
