import { derived, writable, readable, type Readable, get } from "svelte/store";
import {onValue } from "firebase/database"
import type { DbTypes } from "common";
import { onProject, onSourceLanguages, onTargetLanguages, onUserProfile, onVoices, _onValue, _set } from "../services/database";
import { authStore } from "./auth";
import { currentProject } from './currentProject';



//db: userProfile, sourceLangues, targetLanguages, voices, project

type DbStoreType<T,P> = {
    url: string,
    params: P,
    data: T | undefined,
    loading: boolean,
}

type DbStoreParams<T> = {
    url: string,
    params: T,
}

function justUrl(url:string) {
    return readable<DbStoreParams<void>>({url, params:undefined})
}

function createDbSubStore<T,P>(store:Readable<DbStoreType<T,P>>,child:(store:DbStoreType<T,P>)=>string) {
    function getItem(data:any, path:string) {
        const parts = path.split("/")
        let item = data
        for(const part of parts) {
            if(!item) return undefined
            item = item[part]
        }
        return item
    }
    const substore = derived(store, ($store) => ({
        url: $store.url + "/" + child($store),
        params: $store.params,
        data: getItem($store.data,child($store)),
        loading: $store.loading,
    }))
    return {
        subscribe: substore.subscribe,
        sub: (child:(store:DbStoreType<T,P>)=>string) => createDbSubStore(substore,child),
        set: (data:T) => _set(get(substore).url,data),
    }
}

function createDbStore<T,P>(params:Readable<DbStoreParams<P>>) {
    const store = derived(
        [params,authStore], 
        ([$params,$authStore],set) => {
            set({data:undefined, loading:true, url:$params.url, params:$params.params}) 
            if(!$authStore.user) return
            if(!$params.url || $params.url === "") return

            return _onValue<T>($params.url,(data) => {
                set({data, loading: false, url:$params.url, params:$params.params})
            })
        },
        {data:undefined, loading:true,url:get(params).url, params:get(params).params} as DbStoreType<T,P>
    )

    store.subscribe((v) => {  //subsscribe need to keep cache
        console.log("dbStore",v.url,v.loading,v.data)
    })  

    return {
        subscribe: store.subscribe,
        sub: (child:(store:DbStoreType<T,P>)=>string) => createDbSubStore(store,child),
        set: (data:T) => _set(get(store).url,data),
    }
}



export const dbSourceLanguagesStore = createDbStore<DbTypes.SourceLanguageSet,void>(justUrl("/sourceLanguages"))
export const dbTargetLanguagesStore = createDbStore<DbTypes.TargetLanguageSet,void>(justUrl("/targetLanguages"))
export const dbVoicesStore = createDbStore<DbTypes.VoiceSet,void>(justUrl("/voices"))

type DbUserProfileParams = {
    uuid: string | undefined,
}

const _auth =  derived(authStore, ($authStore) => $authStore.user ? {url: `/users/${$authStore.user.uid}`, params: {uuid: $authStore.user.uid}} : {url: "", params: {uuid: undefined}})

export const dbUserProfileStore = createDbStore<DbTypes.UserProfile,DbUserProfileParams>(_auth)

type DbProjectParams = {
    projectId: string | undefined,
    targetCode: string | undefined,
}

const _project =  derived(currentProject, ($currentProject) => $currentProject ? {url: `/projects/${$currentProject.projectId}`, params: {projectId: $currentProject.projectId, targetCode: $currentProject.targetCode}} : {url: "", params: {projectId: undefined, targetCode: undefined}})

export const dbProjectStore = createDbStore<DbTypes.Project,DbProjectParams>(_project)

export const dbTargetStore = dbProjectStore.sub((store) => `targets/${store.params.targetCode}`) 

dbTargetStore.subscribe((v) => {
    console.log("dbTargetStore",v)
})

type DbAllUsersProfileStoreType = ReturnType<typeof createDbStore<DbTypes.UserProfileSet,void>>
let _dbAllUsersProfileStore: DbAllUsersProfileStoreType = undefined
export function getDbAllUsersProfileStore() : DbAllUsersProfileStoreType {
    if(!_dbAllUsersProfileStore)
        _dbAllUsersProfileStore = createDbStore<DbTypes.UserProfileSet,void>(justUrl("/users"))
    return _dbAllUsersProfileStore
}