import {storage} from '../firebase'
import { ref as sref, uploadBytesResumable, getDownloadURL} from 'firebase/storage'

export type UpdateProgress = {
    fileName : string
    totalBytes : number
    bytesTransferred: number
} 

export function uploadVideoFile(projectId:string, file:File, sourceVideoFileName:string, updateProgress: (update:UpdateProgress) => void) {
    console.log('UPLOAD')
    console.log(projectId)
    console.log(file)

    const storageRef = sref(storage, sourceVideoFileName);

    const task = uploadBytesResumable(storageRef, file);

    return new Promise((resolve,reject)=>{
        task.on('state_changed', 
            (snapshot) => {
                //const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                //uploadingStatus.set({status:"uploading",progress,projectId})
                updateProgress({
                    fileName:file.name,
                    totalBytes:snapshot.totalBytes,
                    bytesTransferred:snapshot.bytesTransferred,
                })
            },
            (error) => {
                //uploadingStatus.set({status:"error",error,projectId})
                reject(error)
            },
            () => {
                resolve(task.snapshot.ref)
            }
        )
    })
}

export function getUrl(path:string) {
    return getDownloadURL(sref(storage, path))
}