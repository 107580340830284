import { writable } from "svelte/store";
import type { Readable } from "svelte/store";

const defZoomFactor = 1.2;

export const timelineStoreSymbol = Symbol("timelineStore");

export type MarkerInfo = {
    position: number,
    color?: string,
    label?: string,
    //ystart?: number,
    //yend?: number,
    width?: number,
}

export type StoreDataType ={
    scroll: number;
    width: number;
    zoom: number;
    duration: number;
    markers: {[key: symbol]: MarkerInfo}
}

export function timesampToScreen(store: StoreDataType, timestamp: number) {
    return timestamp * store.zoom - store.scroll
}

export function screenToTimestamp(store: StoreDataType, screen: number) {
    return (screen + store.scroll) / store.zoom
}

export function durationToScreen(store: StoreDataType, duration: number) {
    return duration * store.zoom
}

export function screenToDuration(store: StoreDataType, screen: number) {
    return screen / store.zoom
}

export function calcRatioOnScreen(store: StoreDataType) {
    return  store.width / (store.duration*store.zoom) 
}


export function calcThumbWidth(store: StoreDataType) {
    return store.width * calcRatioOnScreen(store)
}

export function getVisibleRange(store: StoreDataType) {
    return {
        start: screenToTimestamp(store, 0),
        end: screenToTimestamp(store, store.width),
    }
}

export type TimelineStore = Readable<StoreDataType> & {

    setScroll: (scroll: number) => void;
    scrollDelta: (delta: number) => void;
    setWidth: (width: number) => void;

    setZoom: (zoom: number) => void;
    zoomIn: (point?:number,zoomFactor?:number) => void;
    zoomOut: (point?:number,zoomFactor?:number) => void;

    setDuration: (duration: number) => void;

    addorUpdateMarker: (key:symbol,marker: MarkerInfo) => void;
    removeMarker: (key:symbol) => void;
}

export function createTimelineStore(duration:number) : TimelineStore {
    const { subscribe, set, update } = writable({
        scroll: 0,
        width: -1,
        zoom: 100,
        duration,
        markers: {},
    });

    const setScroll = (scroll: number) => update((state) => ({ ...state, scroll }))

    const scrollDelta = (delta: number) => update((state) => {
        let newScroll = state.scroll + delta

        if(newScroll > state.duration*state.zoom - state.width) 
            newScroll = state.duration*state.zoom - state.width
        if(newScroll < 0) newScroll = 0

        return ({ ...state, scroll: newScroll })
    })


    const setWidth = (width: number) => update((state) => {

        if(state.width === -1) { //first time

        }

        return ({ ...state, width })
    })

    const setZoom = (zoom: number) => update((state) => ({ ...state, zoom }))
    const setDuration = (duration: number) => update((state) => ({ ...state, duration }))

    const zoom = (point:number|undefined,zoomFactor:number) => update((state) => {
        const anchor = point ?? state.width/2

        let newZoom = state.zoom * zoomFactor
        if(newZoom > 500) newZoom = 500

        const zf = newZoom / state.zoom
        let newScroll = zf * anchor - anchor + state.scroll * zf
            
        if(newScroll > state.duration*newZoom - state.width)
            newScroll = state.duration*newZoom - state.width

        if(newScroll < 0)
            newScroll = 0
            
        return ({ ...state, zoom: newZoom, scroll: newScroll })
    })

    const zoomIn = (point?:number,zoomFactor = 1.2) => zoom(point,zoomFactor ?? defZoomFactor)
    const zoomOut = (point?:number,zoomFactor = 1.2) => zoom(point,1/(zoomFactor ?? defZoomFactor))

    const addorUpdateMarker = (key:symbol,marker: MarkerInfo) => update((state) => ({...state, markers: {...state.markers, [key]: marker}}))
    const removeMarker = (key:symbol) => update((state) => ({...state, markers: {...state.markers, [key]: undefined}}))

    return {
        subscribe,
        setScroll,
        scrollDelta,
        setWidth,
        setZoom,
        zoomIn,
        zoomOut,
        setDuration,
        addorUpdateMarker,
        removeMarker,
    }

}