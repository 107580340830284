<script lang="ts">
    import Popup from "../components/Popup.svelte";
     // @ts-ignore
     import SvgArrowDown from "../../src/assets/arrow_down.svg";


    export let actions: {name: string, icon?: string, action: () => void}[];

    let action_button_width = 0;
    let popup_button_width = 0;
    let popup_button_height = 0;


    let active_action_index = 0;


</script>

<div class="wrap">
    <button class="button-white popup-name" bind:clientWidth={action_button_width} on:click={actions[active_action_index].action}> 
        {#if actions[active_action_index].icon}
            {@html actions[active_action_index].icon}
            <span>&#032;</span>
        {/if}
        {actions[active_action_index].name}
    </button>
    <Popup let:close>
        <button  class="button-white popup-open" bind:clientWidth={popup_button_width} bind:clientHeight={popup_button_height}><SvgArrowDown/></button>
        <div slot="popup" class="popup-panel" style={`left:${-action_button_width}px; width:${popup_button_width+action_button_width}px; top:${popup_button_height + 2}px;`}>
            {#each actions as action, index}
                {#if index != active_action_index}
                    <button on:click={() => {active_action_index = index; action.action(); close()}} class="button-white drop-action-button" >
                        {#if action.icon}
                            {@html action.icon}
                            <span>&#032;</span>
                        {/if}
                        {action.name}
                    </button>
                {/if}
            {/each}
        </div>
    </Popup>
</div>

<style>
    .wrap {
        display: flex;
        align-items: stretch;
    }

    .popup-name {
        border-radius: 4px 0 0 4px;
    }
    
    .popup-open {
        border-radius: 0 4px 4px 0;
        padding: 13px;
        margin-left: -1px;
    }

    .popup-open :global(svg) {
        transition: 0.5s;
    }

    .popup-open :global(svg path) {
        fill: var(--main-blue);
    }

    .popup-open:hover :global(svg path) {
        fill: white;
    }
/*  
    .popup-open:hover :global(svg) {
        transform: rotate(180deg);
    } */

    .popup-panel {
        position: relative;
    }
    .drop-action-button {
        width: 100%;
    }

</style>