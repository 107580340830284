<script lang="ts">
   import { playRange } from "../../stores/player";
    import {project} from "../../stores/project";
    //import Record from "./Record.svelte";
  import { WordNode } from "./WordNode";
  import { RecordNode } from "./RecordNode";
  import { HeaderNode } from "./HeaderNode";
  import Composer from "../../lexer-editor/Composer.svelte";
  import ContentEditable from "../../lexer-editor/ContentEditable.svelte";
  import TranscriptPlugin from "./TranscriptPlugin.svelte";
  import Debugger from "../../lexer-editor/Debugger.svelte";
  import { customNodesInfo } from "./editor";
  import { RecordTextNode } from "./RecordTextNode";
  import { RecordMarginNode } from "./MarginNode";
  import { transcript } from "../../stores/transcript";
    

  let plugin: TranscriptPlugin | undefined;

  export function save() {
    plugin?.save();
  }
  
  const config = {
    namespace: 'transcript-editor',
    editable: true,
    nodes: [WordNode,RecordNode,HeaderNode,RecordTextNode,RecordMarginNode],
    onError: console.error
 };

 $:transcriptId = $transcript.transcriptId
 
</script>

{#if $transcript.records.length > 0}
    <div class="transcript-editor">
        {#key transcriptId}
            <Composer config={config}>
                <ContentEditable className="transcript-editor"/>
                <TranscriptPlugin bind:this={plugin}/>
                <!-- <Debugger getNodeDebugInfo={customNodesInfo}/>  -->
            </Composer>
        {/key}
    </div>
{:else}
   <div> No records </div>  
{/if}

<style>
    .transcript-editor {
        
        font-weight: 400;
    }

    :global(.transcript-editor:focus-visible) {
        outline: none;
    } 

    :global(.confidence-user) {
        color: blue;
    }
    :global(.confidence-hight) {
        color: var(--text-black);
    }
    :global(.confidence-90) {
        color: #613838;
    }
    :global(.confidence-80) {
        color: #872b2b;
    }
    :global(.confidence-70) {
        color: #ab1e1e;
    }
    :global(.confidence-60) {
        color: #ce1111;
    }
    :global(.confidence-low) {
        color: red;
    }
    :global(.word.active) {
        text-shadow: -0.06ex 0 currentColor, 0.06ex 0 currentColor;
    }

    :global(.record) {
        display: grid;
        grid-template-columns: 0fr 1fr;
        grid-template-rows: repeat(2, 0fr);
        grid-column-gap: 0px;
        grid-row-gap: 0px;
    }

    :global(.record-header) { grid-area: 1 / 1 / 2 / 3; }
    :global(.record-margin) { grid-area: 2 / 1 / 3 / 2; }
    :global(.record-text) { grid-area: 2 / 2 / 3 / 3; }

    :global(.record) {
        /*width: 700px;*/
        width: 100%-4px;
        padding: 4px 12px 12px 8px;
        border: 1px solid transparent;
        border-radius: 4px;
        font-weight: 400;
        margin-left: 4px;
    }

    :global(.record.hover) {
        border: 1px solid var(--main-blue-op04);
    }

    :global(.record.active) {
        background-color: var(--main-blue-op01);
        border: 1px solid var(--main-blue-op04);
    }

</style>