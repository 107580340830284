<script lang="ts">
  import { createEmptyHistoryState, registerHistory, type HistoryState } from "@lexical/history";
  import { registerPlainText } from "@lexical/plain-text";
  import type {CreateEditorArgs } from "lexical";
  import { createEditor} from "lexical";
  import { onMount } from "svelte";
  import Decorator from "./Decorator.svelte";
  import { setEditor } from "./editorContext";

  export let config:CreateEditorArgs 

  const editor = createEditor(config);
  setEditor(editor);

  let externalHistoryState: HistoryState = createEmptyHistoryState();
  let delay = 1000;


  onMount(()=>{
    registerPlainText(editor)
    registerHistory(editor, externalHistoryState, delay)
  })
  
</script>

<slot/>
<Decorator/>