import { writable, derived, readable } from "svelte/store";
import { currentProject } from './currentProject';
import { dbProjectStore } from "./db";
import type { TranscriptRecord} from "../types/transcript"
import type { DbTypes } from "common";
import { getProjectUrls } from '../services/functions';
import { authStore } from "./auth";

async function loadWaveform(url: string) {
  const response = await fetch(url);
  const buffer = await response.arrayBuffer();
  return new Float32Array(buffer);
} 

export type Project = {
    name: string;
    sourceLanguage: string;
    isOwner: boolean
    duration: number;
    width: number
    height: number
    originalWaveform: Float32Array;
    cleanedWaveform: Float32Array;

    projectId: string;
    targetCode: string;

    videoUrl: string;
    cleanedAudioUrl: string;

    loaded:boolean

    speakers?: DbTypes.SpeakerInfoSet

    transcriptOriginalDb: DbTypes.TranscriptOriginal
    transcriptDataDb?: DbTypes.Transcript
    targetDb?: DbTypes.Target

    voiceoverSounds: {
        [recordId: string]: {
            url: string;
        };
    };


    _version?: number
}

const emptyProject:Project = {
    name: "",
    isOwner: true,
    duration: 0,
    width: 0,
    height: 0,

    sourceLanguage:"",

    originalWaveform: new Float32Array(),
    cleanedWaveform: new Float32Array(),

    projectId: "",
    targetCode: "",

    videoUrl: "",
    cleanedAudioUrl: "",

    loaded:false,

    transcriptOriginalDb: [],
    voiceoverSounds:{}
    
}

type ProjectCachedData = {
    id:string
    sourceVideoUrl: string;
    cleanedAudioUrl: string;
    originalWaveform: Float32Array
    cleanedWaveform: Float32Array

    voiceoverSounds: {
        [recordId: string]: {
            url: string;
        };
    };
} | null

const projectCachedData = derived([currentProject,], ([$currentProject,],set) => {
    async function load() {
        set(undefined)
        const projectId = $currentProject?.projectId
        if(!projectId) return
        const urls = await getProjectUrls(projectId)
        console.log(urls)

        //urls


        const originalWaveform = await loadWaveform(urls.originalWaveform1000f32leUrl)
        const cleanedWaveform = await loadWaveform(urls.originalWaveform1000f32leUrl)


        const res = {
            id: projectId, 
            sourceVideoUrl: urls.sourceVideoUrl,
            cleanedAudioUrl: urls.cleanedAudioFileUrl,
            originalWaveform,
            cleanedWaveform,
            voiceoverSounds: urls.targets[$currentProject.targetCode].voiceoverSounds
        }


        set(res)
    }
    //set(undefined)
    load()
}, null as ProjectCachedData)

export const project = derived([dbProjectStore,projectCachedData,authStore], ([$dbProjectStore,$projectCachedData,$authStore],set) => {
    async function load() {
        if(!$projectCachedData || $projectCachedData.id !== $dbProjectStore.params.projectId || !$dbProjectStore.data) {
            set(emptyProject)
            console.log("projectCachedData not loaded")
            return 
        }

        const isOwner = $dbProjectStore.data?.owner === $authStore.user?.uid

        const name = $dbProjectStore.data.name
        const duration = $dbProjectStore.data.meta.format.duration


        const target = $dbProjectStore.data.targets?.[$dbProjectStore.params.targetCode] 

        const videoStream = $dbProjectStore.data.meta.streams.find(s => s.codec_type === "video")

        
        

        set({
            name,
            isOwner,
            duration, 
            height: videoStream?.height ?? 0,
            width: videoStream?.width ?? 0,
            originalWaveform: $projectCachedData.originalWaveform, 
            cleanedWaveform: $projectCachedData.cleanedWaveform, 
            projectId: $dbProjectStore.params.projectId, 
            targetCode: $dbProjectStore.params.targetCode, 
            videoUrl: $projectCachedData.sourceVideoUrl,
            cleanedAudioUrl: $projectCachedData.cleanedAudioUrl,
            loaded:true,

            sourceLanguage: $dbProjectStore.data.sourceLangId!,

            transcriptOriginalDb: $dbProjectStore.data.transcript.original,
            transcriptDataDb: $dbProjectStore.data.transcript.data,
            targetDb: target,

            voiceoverSounds: $projectCachedData.voiceoverSounds,

            speakers: $dbProjectStore.data.transcript?.speakers,

            _version: ($dbProjectStore as any).version

        })
    }

    if(!$dbProjectStore.loading)
        load()
    else
        set(emptyProject)
        
},emptyProject)

export const notOwned = derived(project, $project => !$project.isOwner)
export const forceRwSwtitch = writable(false)
export const readOnly = derived(([notOwned,forceRwSwtitch]), ([$notOwned,$forceRwSwtitch]) => $notOwned && !$forceRwSwtitch)