<script lang="ts">
    import Dialog from "../../components/Dialog.svelte";
    import { defineSpeakerVoice, voices } from "../../stores/voiceover";
    import {getSourceLanguageById} from '../../stores/langs'
    import { onMount } from "svelte";
    import Tag from './Tag.svelte'
    import {search, searchTags, filteredVoices, splitBySearch} from './search'
    import { writable, type Writable } from "svelte/store";
    import AudioButton from "./AudioButton.svelte";

    export let show: boolean = true;
    export let speaker: string;
    export let speakerGender: string = "unknown";
    
    
    const currentPlaying : Writable<HTMLAudioElement> = writable()

    function selectVoice(voice: string) {
        console.log(voice);
        defineSpeakerVoice(speaker, voice);
        show = false;
    }
    //https://storage.googleapis.com/dubwiz-test-d_voice_samples/google-af-ZA-Standard-A.mp3


    function onShow() {
        $search = ""
        searchTags.clear()
        if(speakerGender !== "unknown") {
            searchTags.add(speakerGender.toUpperCase())
        }
    }

    $: if(show) onShow()


    function addTag(e:any) {
        searchTags.add(e.detail.tag)
    }

    function delTag(e:any) {
        searchTags.del(e.detail.tag)
    }

</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<Dialog bind:show={show} title={`Select voice for ${speaker}`}>
    <div class="select-voice-dialog">
        <div class="speaker">{speaker}</div>
        <input bind:value={$search} class="search-language" placeholder="Search" style="margin-bottom: 12px;"/>
        <div>
            {#each $searchTags as tag}
                <Tag tag = {tag} withButton={true} on:delTag={delTag}/>
            {/each}
        </div>
        
        <div class="voices-list">
            <table>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Tags</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {#each $filteredVoices as voice,i}
                    {@const samleUrl = `https://storage.googleapis.com/dubwiz-test-d_voice_samples/${voice.sample}`}
                    {@const nameSplitted = splitBySearch(voice.name,$search)}
                    <tr on:click={()=>selectVoice(voice.id)}>
                        <td>{nameSplitted[0]}<span class="found-text">{nameSplitted[1]}</span>{nameSplitted[2]}</td>
                        <td>
                            {#each voice.tags as tag}
                                <Tag tag = {tag} search={$search} on:addTag={addTag}/>
                            {/each}
                        </td>
                        <td>
                            <AudioButton audioUrl = {samleUrl} currentPlaying={currentPlaying}/>
                        </td>
                    </tr>
                    {/each}
                </tbody>
            </table>
        </div>
    </div>
    
</Dialog>

<style>
    .select-voice-dialog {
        width: 566px;
        /* max-height: 416px; */
    }

    .found-text {
        background-color: yellow;
    }
    .voices-list {
        max-height: 345px;
        overflow-y: scroll; 
    }

    .speaker {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 8px;
        color: var(--main-blue);
    }

    .search-language {
        width: 324px;  
    }

    table  {
        width: 100%;
        text-align: left;
        vertical-align: middle;
        border-spacing: 0px;
    }

    thead {
        /* fixing thead */
        position: sticky;
        top: 0; 
        background-color: white;
        z-index: 1;
        /* --- */
        font-weight: 700;
        height: 52px;
        font-weight: 700;
       
    }
    tbody td {
        height: 48px;
        padding: 2px 0 3px 3px;
        font-weight: 600;
    }

    tbody tr:hover {
        background-color: var(--hover-blue-op01);
    }

</style>
