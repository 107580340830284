<script lang="ts">
    import { useLocation } from "svelte-navigator";
    import {logScreen, setUser} from "../services/analytics"
    import {authStore} from "../stores/auth"
    import { writable } from "svelte/store";
    import { setContext } from "svelte";

    const pageNumber = writable(0)
    setContext("pageNumber",pageNumber)

    function updateCounter(a:any) {
        pageNumber.update(n => n + 1)
    }

    const location = useLocation()
    $:logScreen($location.pathname)

    $: updateCounter($location)
    $: console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!",pageNumber)

    $:setUser($authStore.user?.uid)
</script>

<slot/>