<script lang="ts">
    // @ts-ignore
    import SvgPlus from "../../assets/plus.svg";
    import type { ProjectPreview } from "../../types/projectPreview";

    import SvgDownload from "../../assets/download.svg";

    import Loader from "../Loader.svelte";
    import { createEventDispatcher } from "svelte";
    import * as db from "../../services/database"
    import { cacheRemovedProject } from "../../stores/projectslist";

    import DeleteButton from "../DeleteButton.svelte";
    import DownloadDialog from "../../dialogs/Download/Download.svelte";

    export let project : ProjectPreview;
    export let odd: boolean;

    const dispatch = createEventDispatcher();

    $: durationDisplay = `${project.duration.toFixed(1)}s`

    let showDownloadDialog = false;

    function handleExpand() {
      dispatch("expand")
    }

    function handleDelete() {
      db.deleteProject(project.id)
      cacheRemovedProject(project.id)
    }

    function handleDownload() {
      showDownloadDialog = true;
    }

</script>

<tr class:blue-background={odd} style="vertical-align: top;">
    <td class="button-plus"><button class="svg-button round24-button" on:click={handleExpand}><SvgPlus/></button></td>
    <td>{project.name}</td>
    <td>{durationDisplay}</td>
    <td>{project.created}</td>
    <td>{project.srcLang}</td>
    <td><span class="bold7">{project.targets.length}</span>&#032;language(s)</td>
    <td></td>
    
    {#if project.processing}
        <td style="text-align:center;"> <div style="margin-left: 17px;"> <Loader/> </div> </td>
    {:else}
        <td style="text-align:center;"><DeleteButton on:delete={handleDelete} big={true}/></td>
        <!-- <td style={{paddingTop: "5px"}}><DeleteTargetButton onClick={onDelete} redBig={true}/></td> -->
    {/if}

    <td style="text-align: center;">
      {#if project.download}
        <button on:click={handleDownload} class="squre20-button download-button">
          <SvgDownload/>
        </button>
          
      {/if}
    </td>
    
</tr> 
<DownloadDialog bind:show={showDownloadDialog} projectId={project.id}/>

<style>

  .blue-background {
    background-color: var(--main-blue-op01);
  }

  .button-plus {
    padding-left: 17px;
  }

  td {
    padding: 5px 0;
  }
  .bold7 {
    font-weight: 700;
  }

</style>
