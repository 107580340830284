<script lang="ts">
     import AuthLayout from "../layouts/Auth.svelte"
     import { authStore } from "../stores/auth";
     import { Link, useNavigate, useLocation } from "svelte-navigator";
     import {firebaseErrors} from "../services/firebase_errors"

     const navigate = useNavigate()

     let error = ""
     let email:string = ""
     
     async function resetPassword() {
        try{
            await authStore.sendResetPasswordMail(email)
            navigate( "/")
        } catch (e) {
            //error = e.message
            error = firebaseErrors[e.code] ?? e.message ?? "Internal error"
        }
     }

</script>

<AuthLayout>
    <div  class="account-caption">Reset Password</div>

    <input 
        type="text" 
        bind:value={email}
        class="account-input" 
        placeholder="Email"
    />
    <br/>
    <span style="color:red;">{error}</span>
    <br/>
    <button type="button" on:click={resetPassword} class="full-button">Reset Password</button>


</AuthLayout>

<style>
    .account-caption {
        font-size: 18px;
        margin-top: 12px;
        text-align: center;
    }

    .account-input {
        width: 336px;
        height: 40px;
        margin-top: 16px;
        border: 1px solid var(--light-gray);
        border-radius: 4px;
        padding: 9px 12px 7px 12px;
    }

    .full-button {
         width: 100%;
        display: inline-block;
        margin-bottom: 16px;
    }
</style>