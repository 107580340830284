<colgroup>
    <col style="width:5%;"/>
    <col style="width:25%;"/>
    <col style="width:10%;"/>
    <col style="width:10%;"/>
    <col style="width:15%;"/>
    <col style="width:15%;"/>
    <col style="width:7%;"/>
    <col style="width:4%;"/>
    <col style="width:9%;"/>
</colgroup>
<thead>
  <tr class="table-header">
    <th>  </th>
    <th> Name </th>
    <th> Length </th>
    <th> Created </th>
    <th> Source language</th>
    <th> Target language </th>
    <th> Status </th>
    <th>  </th>
    <th>  </th>
  </tr>
</thead>

<style>
  
    .table-header {
      font-size: 15px;
      font-weight: 600;
      background-color: #D1E4F6;
      line-height: 20px;
      height: 36px;
    }

    thead {
      position: sticky;
      top: 0; 
      z-index: 100;
    } 
  
</style>