<script lang="ts">
import {notOwned,forceRwSwtitch} from '../stores/project'
import Portal from "svelte-portal/src/Portal.svelte";
</script>

{#if $notOwned}
<Portal>
    <div class="banner" class:danger={$forceRwSwtitch}>
        You <b>don't</b> own this project. 
        {#if $forceRwSwtitch} 
        Project loaded in <b>read/write</b> mode.
        {:else}
            Project loaded in <b>readonly</b> mode.
        {/if}
        
        <br/>
        <input class="custom-checkbox" type="checkbox" id="edit" bind:checked={$forceRwSwtitch}/>
        <label class="text" for="edit">force editing</label> 
    </div>
</Portal>
{/if}

<style>
    .banner {
        position: absolute;
        right: 0;
        top: 0;
        background-color: coral;
        z-index: 10;
        padding: 10px;
    }
    .banner.danger {
        background-color: red;
    }

</style>