import { DecoratorNode, type LexicalNode, type NodeKey } from "lexical";
import type {ComponentProps, SvelteComponent} from 'svelte';
import RecordHeader from './RecordHeader.svelte';

type DecoratorImageType = {
    componentClass: typeof SvelteComponent;
    props: ComponentProps<RecordHeader>;
  };
  

export class HeaderNode extends DecoratorNode<DecoratorImageType> {
    __id:string
  
    static getType(): string {
      return 'header';
    }
  
    static clone(node: HeaderNode): HeaderNode {
      return new HeaderNode(node.__id, node.__key);
    }
  
    constructor(id:string , key?: NodeKey) {
      super(key);
      this.__id = id;
    }
    isIsolated(): boolean { return true; }

/*    
    setNumber(num:number):this {
        const self = this.getWritable();
        self.__num = num;
        return self;
    }

    setCount(count:number):this {
        const self = this.getWritable();
        self.__count = count;
        return self;
    }

    setStart(start:number):this {
        const self = this.getWritable();
        self.__start = start;
        return self;
    }

    setEnd(end:number):this {
        const self = this.getWritable();
        self.__end = end;
        return self;
    }
*/  
    createDOM(): HTMLElement {
        const el = document.createElement('div');
        el.classList.add('voiceover-record-header');
        el.style.userSelect = 'none';
        return el
    }
  
    updateDOM(): false {
      return false;
    }
  
    decorate(): DecoratorImageType {
        return {
            componentClass: RecordHeader,
            props: {
                id: this.__id,
            }
        }
      
    }
  }
  
  export function $createHeaderNode(id:string): HeaderNode {
    return new HeaderNode(id);
  }
  
  export function $isHeaderNode(node: LexicalNode): boolean {
    return node instanceof HeaderNode;
  }