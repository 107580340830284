<div style="margin:7px 7px 10px 10px;">
    <div class="dot-elastic"></div>
</div>

<style>
.dot-elastic {
    position: relative;
    /*display: inline-block;*/
    width: 3px;
    height: 6px;
    border-radius: 1.5px;
    background-color: #1976D2;
    color: #1976D2;
    animation: dot-elastic 1s infinite linear;
  }
  .dot-elastic::before, .dot-elastic::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }
  .dot-elastic::before {
    left: -6px;
    width: 3px;
    height: 6px;
    border-radius: 1.5px;
    background-color: #1976D2;
    color: #1976D2;
    animation: dot-elastic-before 1s infinite linear;
  }
  .dot-elastic::after {
    left: 6px;
    width: 3px;
    height: 6px;
    border-radius: 1.5px;
    background-color: #1976D2;
    color: #1976D2;
    animation: dot-elastic-after 1s infinite linear;
  }
  
  @keyframes dot-elastic-before {
    0% {
      transform: scale(1, 1);
    }
    25% {
      transform: scale(1, 2);
    }
    50% {
      transform: scale(1, 0.5);
    }
    75% {
      transform: scale(1, 1);
    }
    100% {
      transform: scale(1, 1);
    }
  }
  
  @keyframes dot-elastic {
    0% {
      transform: scale(1, 1);
    }
    25% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1, 2);
    }
    75% {
      transform: scale(1, 1);
    }
    100% {
      transform: scale(1, 1);
    }
  }
  
  @keyframes dot-elastic-after {
    0% {
      transform: scale(1, 1);
    }
    25% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1, 0.5);
    }
    75% {
      transform: scale(1, 2);
    }
    100% {
      transform: scale(1, 1);
    }
  }    
</style>