import type { LexicalEditor } from 'lexical';
import {getContext, setContext} from 'svelte';

const editorContextKey =  Symbol('editor');

export function getEditor(): LexicalEditor {
  return getContext(editorContextKey);
}
/**
 * Save `editor` in the svelte component context
 */
export function setEditor(editor: LexicalEditor): void {
  setContext(editorContextKey, editor);
}