import { DecoratorNode, type LexicalNode, type NodeKey } from "lexical";
import type {ComponentProps, SvelteComponent} from 'svelte';
import RecordMargin from "./RecordMargin.svelte";

type DecoratorMarginType = {
    componentClass: typeof SvelteComponent;
    props: ComponentProps<RecordMargin>;
  };
  

export class RecordMarginNode extends DecoratorNode<DecoratorMarginType> {
  __id:string 
 
    static getType(): string {
      return 'record-margin';
    }
  
    static clone(node: RecordMarginNode): RecordMarginNode {
      return new RecordMarginNode(node.__id, node.__key);
    }
  
    constructor(id:string, key?: NodeKey) {
      super(key);
      this.__id = id
    }

    isIsolated(): boolean { return true; }
/*
    setNumber(num:number):this {
        const self = this.getWritable();
        self.__num = num;
        return self;
    }

    getNumber():number {
        return this.__num;
    }
*/  
    createDOM(): HTMLElement {
        const el = document.createElement('div');
        el.classList.add('record-margin');
        el.style.userSelect = 'none';
        return el
    }
  
    updateDOM(): false {
      return false;
    }
  
    decorate(): DecoratorMarginType {
        return {
            componentClass: RecordMargin,
            props: {
                id: this.__id
            }
        }
      
    }
  }
  
  export function $createRecordMarginNode(id:string): RecordMarginNode {
    return new RecordMarginNode(id);
  }
  
  export function $isRecordMarginNode(node: LexicalNode): boolean {
    return node instanceof RecordMarginNode;
  }