import {ElementNode,type LexicalNode, type NodeKey, type SerializedElementNode, type Spread} from 'lexical';
import { $createHeaderNode } from './HeaderNode';
import { $createRecordMarginNode } from './MarginNode';
import { $createRecordTextNode } from './RecordTextNode';


export type SerializedRecordNode = Spread<
  {
    type: 'record';
    version: 1;
  },
  SerializedElementNode
>;


export class RecordNode extends ElementNode {
  __id: string
  __speaker: string
  
  static getType(): string {
    return 'record';
  }

  static clone(node: RecordNode): RecordNode {
    return new RecordNode(node.__id, node.__speaker, node.__key);
  }

  constructor(id:string, speaker:string, key?: NodeKey) {
    super(key);
    this.__id = id;
    this.__speaker = speaker;
  }

  getId() : string {
    return this.__id;
  } 

  getSpeaker() : string {
    return this.__speaker;
  }

  setSpeaker(speaker:string):this {
    const self = this.getWritable();
    self.__speaker = speaker;
    return self;
  }

  createDOM(): HTMLElement {
    // Define the DOM element here
    const dom = document.createElement('div');
    dom.classList.add("record")
    dom.setAttribute("data-id", this.__id)
    return dom;
  }

  updateDOM(prevNode: RecordNode, dom: HTMLElement): boolean {
    // Returning false tells Lexical that this node does not need its
    // DOM element replacing with a new copy from createDOM.
    return false;
  }

  exportJSON(): SerializedRecordNode {
    return {
      ...super.exportJSON(),
      type: 'record',
      version: 1,
    };
  }


}

export function $createRecordNode(id:string, speaker:string): RecordNode {
    const recordNode = new RecordNode(id,speaker);
    recordNode.append($createHeaderNode(id))
    recordNode.append($createRecordMarginNode(id))
    recordNode.append($createRecordTextNode())
    return recordNode;

  }
  
  export function $isRecordNode(node: LexicalNode): boolean {
    return node instanceof RecordNode;
  }