import {dbSourceLanguagesStore,dbTargetLanguagesStore, dbUserProfileStore} from './db'
import { derived, get } from 'svelte/store'
import type {  DbTypes } from 'common';

export const sourceLanguagesLoaded = derived(dbSourceLanguagesStore, $dbSourceLanguagesStore => !$dbSourceLanguagesStore.loading)
export const sourceLanguages = derived(dbSourceLanguagesStore, $dbSourceLanguagesStore => 
    Object.values($dbSourceLanguagesStore.data ?? {}).filter(lang => lang.enabled)
)

export function getSourceLanguageById(id:string) {
    return get(sourceLanguages).find(l => l.id === id)
}

export function getSourceLanguageLocale() {
    const allLangs = get(sourceLanguages)
    const browserLangs = navigator.languages
    const res: DbTypes.SourceLanguage[] = []
    for (const lang of browserLangs) {
        //full match
        {
            const found = allLangs.find(l => l.id === lang)

            if(found) {
                res.push(found)
                continue
            }
        }

        //two code (one lang exists)
        {
            const found = allLangs.filter(l => l.id.slice(0,2) === lang)
            if(found.length === 1) {
                res.push(found[0])
                console.log("found",found[0])
                continue
            }
        }

        //two code match + sufix from other lang, like hi-IN, en -> hi-IN, en-IN

        //native, like fr -> fr-FR 
        {
            const found = allLangs.find(l => l.id === lang + "-" + lang.toUpperCase())
            if(found) {
                res.push(found)
                continue
            }

        }

    }

    //if()
    return res
}

export const recentSourceLanguages = derived([dbUserProfileStore,sourceLanguages], ([$dbUserProfileStore]) => {
    const prevProject =  Object.values($dbUserProfileStore.data?.projects ?? {}).map(p => p.source).map(getSourceLanguageById)
    const allRecent = [...prevProject,...getSourceLanguageLocale()]

    return allRecent.filter((value, index, array) => array.indexOf(value) === index).filter(l => l)
})

export const targetLanguagesLoaded = derived(dbTargetLanguagesStore, $dbTargetLanguagesStore => !$dbTargetLanguagesStore.loading)
export const targetLanguages = derived(dbTargetLanguagesStore, $dbTargetLanguagesStore => 
    Object.values($dbTargetLanguagesStore.data ?? {}).filter(lang => lang.enabled)
)

export function getTargetLanguageById(id:string) {
    return get(targetLanguages).find(l => l.id === id)
}

export function getTargetLanguageLocale() {
    const allLangs = get(targetLanguages)
    const browserLangs = navigator.languages
    const res:DbTypes.TargetLanguage[] = []
    for (const lang of browserLangs) {
        //full match
        {
            const found = allLangs.find(l => l.id === lang)

            if(found) {
                res.push(found)
                continue
            }
        }

        //two code match
        {
            const found = allLangs.find(l => l.id === lang.slice(0,2))
            if(found) {
                res.push(found)
                continue
            }
        }
    }

    return res
}


export const recentTargetLanguages = derived([dbUserProfileStore,targetLanguages], ([$dbUserProfileStore]) => {
    const prevProject =  Object.values($dbUserProfileStore.data?.projects ?? {}).flatMap(p => Object.values(p.targets ?? {}).map(t => t.languageId)).map(getTargetLanguageById)
    const allRecent = [...prevProject,...getTargetLanguageLocale()]

    return allRecent.filter((value, index, array) => array.indexOf(value) === index).filter(l => l)
})