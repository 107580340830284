import * as fn from "../services/functions";
import {currentUserId} from "../stores/auth";
import { derived, get } from 'svelte/store';
import { dbUserProfileStore } from "./db";
import { writable } from 'svelte/store';

export function addCredits(amount:number) {
    console.log("add credits");
    fn.addCredits(currentUserId(),amount)
}

export const expectedBalance = writable<number|null>(null);

export const balanceStore = derived([dbUserProfileStore,expectedBalance], ([$dbUserProfileStore,$expectedBalance]) => {
    const balanceFromProfile = $dbUserProfileStore.data?.credits ?? 0

    if($expectedBalance === balanceFromProfile) {
        expectedBalance.set(null)
    }

    if ($expectedBalance !== null) {
        return $expectedBalance
    } else {
        return balanceFromProfile
    }

})
  
export function reduceExpectedBalance(amount:number) {
    expectedBalance.set(get(balanceStore) - amount)
}