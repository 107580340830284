<script lang="ts">
  import { editorMode } from "../stores/editorMode";
  import {voiceoverRecordsReady,voiceover} from "../stores/voiceover";
  import {isPlaying,currentPos} from "../stores/player";

  $: record = $voiceover.find(r => !r.soundReady && r.startTime <= $currentPos && r.startTime + r.estimatedDuration >= $currentPos)
  
</script>

{#if $editorMode === 'voiceover'}
    <div>
        This is preview mode. Generated results can be a bit different. 
        {#if !$voiceoverRecordsReady}
            <div>Not all sounds generated</div>
        {/if}
        {#if $isPlaying && record}
            <div>This record is not generated</div>
        {/if}
    </div>
{/if}

<style>
    div {
        font-weight: 200;
        font-size: small;
        overflow: hidden;
    }
</style>