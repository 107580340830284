<script lang="ts">
    import { getContext } from "svelte";
    import {timelineStoreSymbol,timesampToScreen,durationToScreen,screenToTimestamp,screenToDuration, getVisibleRange} from "./store";
    import type {TimelineStore} from "./store";

    export let position:number 
    export let color="red"
    export let label:string = null
    //export let ystart: number|null = null;
    //export let yend: number|null = null;
    export let width = 1


    const ctx = getContext<TimelineStore>(timelineStoreSymbol);

    //TODO: ysart and yend to top/bottom + height??
    function ystartStyle(ystart) {
        if(ystart === null) return "top:0px;"
        if(ystart < 0) return `bottom:${-ystart}px;`
        return `top:${ystart}px;`
    }

    function yendStyle(yend) {
        if(yend === null) return "bottom:0px;"
        if(yend < 0) return `bottom:${-yend}px;`
        return `top:${yend}px;`
    }
    
    $: style = `
        left:${timesampToScreen($ctx,position)}px; 
        background-color:${color};
        --color:${color};
        position: absolute;
        width: ${width}px;
        bottom: 0px;
        top: 50px;
        ` +
        (label ? `--label:"${label}";` : "") 
        //ystartStyle(ystart) +  
        //yendStyle(yend)


</script>

<div class="marker" {style} ></div>

<style>
    .marker::after {
        position: absolute;
        content: var(--label);
        width: 100px;
        left: -50px;
        text-align: center;
        margin-top: -16px;
        font-family: 'Segoe UI';
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        color:var(--color);
    }

</style>