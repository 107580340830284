<script lang="ts">
  import Stripe from "../../timeline/Stripe.svelte";
  import Timeline from "../../timeline/Timeline.svelte";
  import TimelineRuller from "../../timeline/TimelineRuller.svelte";
  import WaveformStripe from "../../timeline/WaveformStripe.svelte";
  import Scrollbar from "../../timeline/Scrollbar.svelte";
  import DebugInfo from "../../timeline/DebugInfo.svelte";
  import ButtonZoomIn from "../../timeline/ButtonZoomIn.svelte";
  import ButtonZoomOut from "../../timeline/ButtonZoomOut.svelte";
  import Cue from "./Cue.svelte";

  import {project} from "../../stores/project";
  import {transcript} from "../../stores/transcript";
  import {currentPos} from "../../stores/player";

  import { hoverRecordId, activeRecordId } from "../../stores/transcript";
  import { seek } from "../../stores/player";

  
  // @ts-ignore
  import SvgZoomIn from "../../assets/bx_zoom-in.svg";
  // @ts-ignore
  import SvgZoomOut from "../../assets/bx_zoom-out.svg";
  import PositionMarker from "./PositionMarker.svelte";


  //TODO: what if all words removed?
    $: data = $transcript.records.map(cue => {
      return {
        id: cue.id,
        speaker: cue.speaker,
        start: cue.words && cue.words.length > 0 ? cue.words[0].startTime : 0,
        duration: cue.words && cue.words.length > 0 ? cue.words[cue.words.length - 1].endTime - cue.words[0].startTime : 0, 
        text: cue.words?.map(word => word.word).join(" ") ?? "",
      }
    })
  

  function handleMoveCue(e: CustomEvent<any>): void {
    data = data.map(cue => cue.id === e.detail.cueId ? {...cue, start: cue.start + e.detail.poistion} : cue)
  }

  function handleRullerClick(e: CustomEvent<number>): void {
    seek(e.detail)
  }


</script>

<Timeline duration={$project.duration}>
  <PositionMarker position={$currentPos}/>
  <TimelineRuller showMarker={true} on:click={handleRullerClick}/>
    <Stripe data={data} on:move={handleMoveCue} readonly let:cue let:index bind:selection={$activeRecordId}>
      <Cue cue={cue} index={index} on:mouseenter={()=>$hoverRecordId=cue.id} on:mouseleave={()=>$hoverRecordId=null} hover={$hoverRecordId === cue.id}/>
    </Stripe>
    <WaveformStripe waveformData={$project.originalWaveform} waveformCleanedData={$project.cleanedWaveform} readonly/>
    <svelte:fragment slot="overlay">
        <Scrollbar/>
        <div class="controls">
            <ButtonZoomIn className="svg-button round24-button"> <SvgZoomIn/> </ButtonZoomIn>
            <ButtonZoomOut className="svg-button round24-button"> <SvgZoomOut/> </ButtonZoomOut>
        </div>
        <!--<DebugInfo dy={-120}/>-->
    </svelte:fragment>
</Timeline>

<style>
  .controls {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 100px;
    margin-left: -30px;
  }
</style>


