<script lang="ts">
    export let data:Float32Array;
    export let samplesPerSec;
    export let start;
    export let duration;
    //export let secInBand
    export let bandWidth;
    export let spaceWidth:number;
    export let pixelsPerSec;

    export let bandStyle = "";
    export let backgroundStyle = "";

    function chunkArrayInGroups(arr, size) {
        const myArray = [];
        for(let i = 0; i < arr.length; i += size) {
            myArray.push(arr.slice(i, i+size));
        }
        return myArray;
    }

    function resample(arr:Float32Array,size:number) {
        const res = [];
        for(let i = 0; i < arr.length; i += size) {
            const sampleAv = arr.subarray(i, i+size).reduce((a,b) => a+Math.abs(b),0)/size
            res.push( Math.log(sampleAv*100)*20 );
        }
        return res;

    }


    $: fullBandWidth = bandWidth + spaceWidth;
    $: firstVisibleBand = Math.floor(start*pixelsPerSec/fullBandWidth);
    $: firstBandCOrrection = (firstVisibleBand - start*pixelsPerSec/fullBandWidth) * fullBandWidth
    $: lastVisibleBand = Math.ceil((start+duration)*pixelsPerSec/fullBandWidth) 
    $: samplesInBand = samplesPerSec * fullBandWidth/pixelsPerSec;

    $: startSample = firstVisibleBand * samplesInBand
    $: endSample = (lastVisibleBand + 1) * samplesInBand

    /*
    $: waveform = chunkArrayInGroups(data.subarray(startSample,endSample),samplesInBand)
        .map(chank => chank.reduce((a,b) => a+b,0)/chank.length);
    */
    $: waveform = resample(data.subarray(startSample,endSample),samplesInBand);
</script>

<div class="waveform" style={backgroundStyle}>
    {#each waveform as item, index}
        {@const style = bandStyle + `left: ${index*fullBandWidth + firstBandCOrrection}px; height:${item}px; width: ${bandWidth}px;`}
        <div class="point" {style}></div>
    {/each}
</div>

<style>
    .point {
        position: absolute;
        background-color: #434343;
        bottom: 0px;
        font-size: smaller;
    }
    .waveform {
        position: relative;
        left:0;
        top:0;
        width: 100%;
        height: 100%;
        /*opacity: 0.5;*/
        overflow: hidden;
    }

</style>

