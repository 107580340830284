<script lang="ts">
  import Dialog from "../../components/Dialog.svelte";
  import DownloadDialogContent from "./DownloadDialogContent.svelte";

    export let show: boolean = true;
    export let projectId: string;
    export let target: string = undefined

</script>
   
<Dialog bind:show={show} title="Generated results">
    <DownloadDialogContent projectId={projectId} target={target}/>
</Dialog>

<style>


</style>
   
   