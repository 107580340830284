<script lang="ts">
    import { createEventDispatcher, getContext, onDestroy } from "svelte";
    import {timelineStoreSymbol,timesampToScreen, screenToTimestamp, type MarkerInfo} from "./store";
    import type {TimelineStore} from "./store";

    export let devisionValue
    export let pixelsPerSec
    export let formatTime
    export let start
    export let end

    export let showMarker = false

    export let subMark1 = 10
    export let subMark2 = 2

    const dispatch = createEventDispatcher()

    $: firstVisibleMark = Math.floor(start / devisionValue)
    //$: correction = (start / devisionValue - firstVisibleMark) * devisionValue
    $: lastVisibleMark = Math.ceil(end / devisionValue)

    let el:HTMLElement


    const rullerMarkerSymbol = Symbol("rullerMarker")
    let rullerMarker:MarkerInfo = {
        position: 0,
        color: "yellowgreen",
        width: 0.5,
    }


    const ctx = getContext<TimelineStore>(timelineStoreSymbol);
    function handleMouseLeave(){
        if(!showMarker) return
        ctx.removeMarker(rullerMarkerSymbol)
    }

    function handleMouseMove(e: MouseEvent){
        if(!showMarker) return
        const pos = screenToTimestamp($ctx,e.pageX-el.getBoundingClientRect().left)
        rullerMarker.position = pos
        rullerMarker.label = formatTime(pos)
        ctx.addorUpdateMarker(rullerMarkerSymbol,rullerMarker)
    }
    function handleMouseDown(e: MouseEvent){
        //if(!showMarker) return
        const pos = screenToTimestamp($ctx,e.pageX-el.getBoundingClientRect().left)
        dispatch("click",pos)
    }

    onDestroy(()=>{
        ctx.removeMarker(rullerMarkerSymbol)
    })
    
</script>

<div class="ruller" bind:this={el} on:mouseleave={handleMouseLeave} on:mousemove={handleMouseMove} on:mousedown={handleMouseDown}>
    {#if !isNaN(end)}
        {#each Array(lastVisibleMark-firstVisibleMark) as _, i (i) }
            {@const time = (firstVisibleMark + i) * devisionValue}
            {@const left = (time - start) * pixelsPerSec }
            <div class="mark" style="left: { left }px;"></div>
            <div class="mark-text" style="left: { left - 25}px;">{formatTime(time)}</div>
            {#if subMark1 > 0}
                {#each Array(subMark1) as _, j (j) }
                    {@const subTime = time + j * devisionValue/subMark1}
                    {@const subLeft = (subTime - start) * pixelsPerSec }
                    <div class="mark" style="left: { subLeft }px; height: 5px;"></div>
                {/each}
            {/if}
            {#if subMark2 > 0}
                {#each Array(subMark2) as _, j (j) }
                    {@const subTime = time + j * devisionValue/subMark2}
                    {@const subLeft = (subTime - start) * pixelsPerSec }
                    <div class="mark" style="left: { subLeft }px; height: 8px;"></div>
                {/each}
            {/if}
        {/each}
    {/if}

</div>

<style>
.ruller {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 24px;
    /*background-color: #3285D7;*/
    background-color: #1976D2;
}
.mark {
    position: absolute; 
    bottom: 0; 
    width: 0.5px; 
    height: 10px; 
    background-color: white;
}
.mark-text {
    position: absolute;  
    top: 0; 
    color:white; 
    text-align: center; 
    width:50px; 
    font-size:10px;
    font-family: 'Segoe UI';
    font-style: normal;
}
</style>