<script lang="ts">
    import { onMount } from "svelte";
    import {getDbAllUsersProfileStore} from "../stores/db"
    import { _onValue } from "../services/database";
    import {set,ref,getDatabase,get} from 'firebase/database'

    const store = getDbAllUsersProfileStore()

    let trustedDomainsTxt: string = ""
    let spamDomainsTxt: string = ""

    let trustedDomains: string[] = []
    let spamDomains: string[] = []

    $: usersList = Object.values($store.data ?? {})
    $: domains = Object.entries(
        usersList
            .filter(u => u?.email)
            .map(u => u.email.split("@")[1])
            .reduce((acc, val) => ({...acc,[val]:(acc[val]??0)+1}) , {})
    ).map(([domain, count]) => ({domain, count} as {domain: string, count: number}))
     .filter(d => d.count > 1)
     .filter(d => !splitTxt(trustedDomainsTxt).includes(d.domain) && !splitTxt(spamDomainsTxt).includes(d.domain))
     .sort((a, b) => b.count - a.count)

     onMount(() => {
        const un1 = _onValue<string[]>(`/trustedDomains`, setTrustedDomains)
        const un2 = _onValue<string[]>(`/spamDomains`,  setSpamDomains)
        return () => {
            un1()
            un2()
        }
     })

    function splitTxt(txt: string) {
        return txt.split("\n").map(d => d.trim()).filter(d => d !== "")
    }
    function joinTxt(arr: string[]) {
        return arr.map(r => r.trim()).join("\n")
    }

    function setTrustedDomains(val: string[] | null) {
        trustedDomains = val ?? []
        trustedDomainsTxt = joinTxt(trustedDomains)
    }

    function setSpamDomains(val: string[] | null) {
        spamDomains = val ?? []
        spamDomainsTxt = joinTxt(spamDomains)
    }

    function saveTrunsed() {
        trustedDomains = splitTxt(trustedDomainsTxt)
        set(ref(getDatabase(), '/trustedDomains'), trustedDomains)
    }
    function saveSpam() {
        spamDomains = splitTxt(spamDomainsTxt)
        set(ref(getDatabase(), '/spamDomains'), spamDomains)
    }

    function addTrusted(domain: string) {
        if(!trustedDomainsTxt.endsWith("\n") && trustedDomainsTxt.trim() !== "")
            (trustedDomainsTxt += "\n")
        trustedDomainsTxt += domain
    }

    function addSpam(domain: string) {
        if(!spamDomainsTxt.endsWith("\n") && spamDomainsTxt.trim() !== "")
            (spamDomainsTxt += "\n")
        spamDomainsTxt += domain
    }

    $: changedTrusted = joinTxt(trustedDomains).trim() !== joinTxt(splitTxt(trustedDomainsTxt)).trim()
    $: changedSpam = joinTxt(spamDomains).trim() !== joinTxt(splitTxt(spamDomainsTxt)).trim()

</script>

<div class="mails">
    <h1>Domains</h1>
    <h2>Domains for review</h2>
    {#each domains as {domain, count}}
        <div class="domain-row">
            <div><a href={`http://${domain}`} target="_blank">{domain}</a> - {count}</div>
            <button on:click={() => addTrusted(domain)}>Trust</button>
            <button on:click={() => addSpam(domain)}>Spam</button>
        </div>
    {/each}
    <h2>Trusted domains</h2>
    <textarea placeholder="list of trusted doamins..." bind:value={trustedDomainsTxt}></textarea>
    <button on:click={saveTrunsed} disabled={!changedTrusted}>Save</button>
    <h2>Spam domains</h2>
    <textarea placeholder="list of spam doamins..." bind:value={spamDomainsTxt} ></textarea>
    <button on:click={saveSpam} disabled={!changedSpam}>Save</button>
</div>

<style>
    .mails {
        padding: 20px;
        overflow: auto;
        width:100%; 
    }
    textarea {
        width: 400px;
        height: 200px;
        margin: 10px 0;
    }
    .domain-row {
        display: flex;
    }
    .domain-row * {
        margin: 5px;
    }
</style>