<script lang="ts" context="module">
    let mouseX = 10
    let mouseY = 10

    window.addEventListener("mousemove", (e) => {
        mouseX = e.clientX
        mouseY = e.clientY
    })

    export function getMousePosition() {
        //console.log("getMousePosition",mouseX, mouseY)
        return { x:mouseX, y:mouseY }
    }
    //TODO: consider use readable store instead
</script>

