<script lang="ts">
    import Popup from "./Popup.svelte";
    import LangSelectorPopup from "./LangSelectorPopup.svelte";
    import { createEventDispatcher } from "svelte";

    const dispatch = createEventDispatcher();

    export let disabled: boolean = false;
    export let likeLink: boolean = false;
    export let customEvent: boolean = false;

    export let title: string;
    export let items: {id:string, name:string}[]
    export let recentItems: {id:string, name:string}[] = []
    export let price: number = 0

    function handleOpen() {
        dispatch("open");
    }
    
    $: collapsedClass = likeLink ? "collapsed-link" : "collapsed"
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
{#if disabled}
    <div class={collapsedClass} class:disabled>{title}</div>
{:else if customEvent}
    <div class={collapsedClass} on:focus={handleOpen} on:click={handleOpen}>{title}</div>
{:else}
    <Popup let:close on:popup_open on:popup_close>
        <LangSelectorPopup slot="popup" close={close} title={title} items={items} recentItems={recentItems} price={price} on:select/>
        <div class={collapsedClass} class:disabled>{title}</div>
    </Popup>
{/if}



<style>
    .popup {
        background-color: white;
    }

    .collapsed {
        display: inline-block;
        width: 325px;
        height: 32px;
        border: 1px solid var(--light-gray);
        border-radius: 4px;
        background: #fff url("../assets/arrow_down.svg") 306px 13px no-repeat;
        /*padding: 6px 0 6px 8px;*/
        padding-left: 8px;
        padding-top: 6px;
        
        font-size: 14px;
        font-weight: 400;
        color: var(--light-gray);
    }

    .collapsed.disabled {
        background-color: gainsboro;
    }

    .collapsed-link { /*classes blue & text*/
        display: inline-block;
        width: 325px;
        /* margin-left: 9px; */ 
        font-size: 14px;
        font-weight: 400;
        color: var(--main-blue);
        cursor: pointer;
    }


</style>