import { writable } from "svelte/store"

function createStore() {
    const blockedElements = writable<HTMLElement[]>([])
    function add(el:HTMLElement) {
        blockedElements.update(bes => [...bes,el])
    }
    function remove(el:HTMLElement) {
        blockedElements.update(bes => bes.filter(e => e !== el))
    }
    return {
        subscribe: blockedElements.subscribe,
        add,
        remove
    }
}

export const voiceoverEditorStore = createStore()