<script lang="ts">
 import AuthLayout from "../layouts/Auth.svelte"
 import { Link, useNavigate, useLocation } from "svelte-navigator";
 import {firebaseErrors} from "../services/firebase_errors"

 import SvgGoogle from "../assets/google.svg"; 
 import SvgFacebook from "../assets/facebook.svg";
 import SvgApple from "../assets/apple.svg";

 import { authStore } from "../stores/auth";
 import {logLogin} from "../services/analytics";
 
 let userName:string
 let password:string
 let rememberMe = false
 let error = ""

 const navigate = useNavigate()
 const location = useLocation()


 async function login() {
    //console.log("login",userName,password,rememberMe)
    try {
        await authStore.login(userName,password)
        logLogin("email")
        navigate($location.state?.from ?? "/")
    } catch (e) {
        error = firebaseErrors[e.code] ?? e.message ?? "Internal error"
    }
}

async function loginGoogle() {
   //console.log("loginGoogle")
    try {
        await authStore.loginGoogle()
        logLogin("google")
        navigate($location.state?.from ?? "/")
    } catch (e) {
      error = firebaseErrors[e.code] ?? e.message ?? "Internal error"
    }
 }

 async function loginFacebook() {
  //console.log("loginFacebook")
    try {
        await authStore.loginFacebook()
        logLogin("facebook")
        navigate($location.state?.from ?? "/")
    } catch (e) {
      error = firebaseErrors[e.code] ?? e.message ?? "Internal error"
    }

 }

</script>

<AuthLayout>
    <div  class="account-caption">Please login</div>
    <input 
        type="text" 
        bind:value={userName}
        class="account-input" 
        placeholder="Email"
    />
    <input 
        type="password" 
        bind:value={password} 
        class="account-input" 
        placeholder="Password"
    />

    <span style="color:red;">{error}</span>

    <div class="check-group">
        <input class="custom-checkbox" bind:checked={rememberMe} type="checkbox" id="remember"/>
        <label class="text" for="remember">Remember me</label> 
    </div>
 
    <button type="button" on:click={login} class="full-button">Sign in</button>

    <div class="new-account">
      <span>Don`t have an account yet?</span>
      <Link to="/register">
        <button  type="button" class="button-small-blue-text">Register now!</button>
      </Link>
    </div>


    <div class = "div-or">
        <div class = "div-line small"></div>
        <span class = "div-text">OR</span>
        <div class = "div-line small"></div>
    </div>

    <div style="width: 100%; text-align: center;">
      <button type="button" class="login-button log-google" on:click={loginGoogle}>
        <SvgGoogle/>Login with Google
      </button>
    </div>
<!--
    <button type="button" class="login-button log-fb" on:click={loginFacebook}>
        <SvgFacebook/>Login with Facebook
    </button>

    <button type="button" class="login-button log-apple" disabled>
        <SvgApple/>Login with Apple
    </button>
    
-->
    <div class="div-line-center"></div>

    <div style=" display: flex; justify-content: center;">
      <Link to="/reset">
         <button type="button"  class="button-small-blue-text" style="color: black">Forgot password?</button>
      </Link>
    </div>

</AuthLayout>

<style>
.account-caption {
  font-size: 18px;
  margin-top: 12px;
  text-align: center;
}

.account-input {
  width: 336px;
  height: 40px;
  margin-top: 16px;
  border: 1px solid var(--light-gray);
  border-radius: 4px;
  padding: 9px 12px 7px 12px;
}

.check-group {
  display: flex;
  align-items: center;
  margin: 16px 0 20px 0;
}

.full-button {
  width: 100%;
  display: inline-block;
  margin-bottom: 16px;
}

.new-account {
  text-align: center;
}
.new-account *{
  display: inline-block;
  font-size: 14px;
}

.div-or {
  display: flex;
  margin: 3px 0 23px 0;
  align-items: center;
  justify-content: center;
  
}
.div-line {
  background-color: var(--light-gray);
  opacity: 0.1;
}
.small {
  width: 50px;
  height: 1px;
}
.div-text {
  color: var(--light-gray);
  opacity: 0.1;
  margin: 0 16px;

}

.login-button {
  width: 164px;
  height: 32px;
  background-color: #FFFFFF;
  border: 1px solid var(--light-gray);
  border-radius: 4px;
  padding: 0;
  /* Text*/
  font-weight: 400;
  font-size: 13px;
  color: var(--text-black);
  text-align: start;
  display: inline-flex;
  align-items: center;
}
.login-button:hover {
  background-color: #FFFFFF;
  border: 1px solid var(--hover-blue);
  color: var(--hover-blue);
}
.login-button:active {
  border: 1px solid var(--hover-blue);
  color: var(--hover-blue);
  outline: 4px solid var(--main-blue-op01);
}

.login-button:disabled {
  background-color: #FFFFFF !important;
  filter: grayscale(1) brightness(1.2);
  color: var(--light-gray) !important;
  border-color: var(--light-gray) !important;
  cursor: initial;
}

.log-google {
  margin-right: 4px;
}
.log-google :global(svg) {
  margin: 0 13px 0 17px;
}
.log-fb :global(svg) {
  margin: 0 8px 0 12px;
}
.log-apple {
  margin: 8px 0 0 85px;
}
.log-apple :global(svg) {
  margin: 0 18px 0 15px;
}

.div-line-center {
  width: 150px;
  height: 1px;
  background-color: var(--light-gray);
  opacity: 0.1;
  display: flex;
  margin: 16px 0 0 93px;
  justify-content: center;
}

.forgot-pswd {
  width: 169px;
  height: 40px;
  font-size: 14px;
  margin: 10px 0 0 87px;
  text-align: center;
  padding-top: 11px;
  background-color: #FFFFFF;
  color: var(--text-black);
  display: flex;
  justify-content: center;
}
.forgot-pswd:hover {
  background-color: #FFFFFF;
  color: var(--hover-blue);
}
.forgot-pswd:active {
  color: var(--hover-blue);
  background-color: var(--hover-blue-op01);
  border-radius: 4px;
}
</style>