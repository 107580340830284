<script lang="ts">
  import { Link } from "svelte-navigator";
  import SvgLogo from './assets/logo.svg';
  import SvgProj from './assets/proj.svg';
  import SvgAdd from './assets/add.svg';
  import SvgExit from './assets/exit.svg';
  import SvgHelp from './assets/help.svg';

  import AddMoney from "./dialogs/AddMoney.svelte";

  import { authStore } from "./stores/auth";
  import {balanceStore } from "./stores/balance";
  import {currentProject } from "./stores/currentProject";
  import { useNavigate } from "svelte-navigator";
   
  const navigate = useNavigate();

  let showAddMoneyDialog = false;
  $: credits = Math.round($balanceStore/60)

  function handleAddCredits() {
    showAddMoneyDialog = true; 
  }

  async function handleLogout() {
    await navigate("/", { replace: true });
    //currentProject.set(null);
    authStore.logout();
  }
</script>


<AddMoney bind:show={showAddMoneyDialog}/>

<div class="appbar">

<!--    <AddCreditsModal onClose={handleCloseAddCredits} isOpen={isOpenAddCreditsModal}/> -->

    <Link to="/">
      <div class="button">
        <SvgLogo/>
      </div>
    </Link>

    <div class="line"></div>

    <Link to="/">
      <div class="button">
        <SvgProj/>
        <span>Projects</span>
      </div>        
    </Link>

    <div class="filler"></div>

    <a href="https://dubwiz.com/help/" target="_blank" rel="noopener noreferrer">
      <div class="button">
        <SvgHelp/>
        <span>Help</span>
      </div> 
    </a>

  
    <div class="info">
      <span class="bignumber" class:red={credits===0}>{credits}</span><br>
      <span>mins remaining</span>
    </div>

    <!--TODO: repace it to button -->
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <div class="button" style="padding: 8px 0;" on:click={handleAddCredits}>
      <SvgAdd/>
      <span>Add more</span>
    </div>

    <div class="line"></div>

    <!--TODO: repace it to button -->
    <!-- svelte-ignore a11y-click-events-have-key-events -->
    <div class="button" on:click={handleLogout}>
      <SvgExit/>
      <span>Exit</span>
    </div> 


</div>
<style>
 .appbar {
    background-color: white;
    width: 100px;
    border-radius: 0px 12px 12px 0px;
    padding: 16px;
    display:flex;
    flex-direction: column;
  }

  .appbar .button {
    margin: 6px 0;
    align-items:center;
    display: flex;
    flex-direction: column;
    padding: 8px 5px;
  }

  .appbar .button:hover :global(svg){
    fill: var(--hover-blue);
  }

  .appbar .button:hover span{
    color: var(--hover-blue);
  }

  .appbar .button:active{
    border-radius: 4px;
    background-color: var(--main-blue-op01); 
  }

  .appbar span {
    margin-top: 12px;
    font-family: "Segoe UI", Arial, sans-serif;
    font-weight: 600;
    text-align: center;
    font-size: 15px;
    color: var(--text-black);
  }

  .appbar .bignumber {
    font-size: 24px!important;
    font-weight: 600!important;
    display: inline-block;
    margin-bottom: 8px;
    color: var(--main-blue);
  }

  .red {
    color: red !important;
  }
  
  .filler {
    flex-grow: 1; 
  }
  
  .info {
    margin-bottom: 14px;
    text-align: center
  }
  .line {
    width: 100%;
    height: 2px;
    background-color: #E0E0E0;
    justify-content: flex-start;
    margin-top: 18px;
    margin-bottom: 22px;
  }

  a {
    color: var(--text-black);
  }

</style>