<script lang="ts">
    import DeleteButton from "../../components/DeleteButton.svelte";
    import {createStore} from './downloadDialogStore'
    import Loader from "../../components/Loader.svelte";


    export let projectId: string;
    export let target: string|undefined;

    $:store = createStore(projectId,target)

    function handleDelete(target:string,resultId:string) {
        store.delete(target, resultId)
    }

</script>

{#if $store}
<table>
    <colgroup>
        <col style="width:85%;"/>
        <col style="width:15%;"/>
    </colgroup>
    <thead>
        <tr>
            <th>Project name</th>
            <th>  </th>
        </tr>
    </thead>
    <tbody>
        
            {#each $store as file}
                <tr >
                    {#if file.url && file.status === "READY"}
                        <td><a href={file.url} download>{file.name}</a></td>
                    {:else}
                        <td>{file.name}</td>
                    {/if}
                    {#if file.status === "READY"}
                        <td><DeleteButton big={false} on:delete={()=>handleDelete(file.target,file.id)}/></td>
                    {:else if file.status === "GENERATING"}
                        <td><Loader/></td>
                    {:else}
                        <td></td>
                    {/if}
                    
                </tr>
            {/each}
        
    </tbody>
</table>
{/if}
<style>
    table {
        width: 100%;
        border-collapse: collapse;
    }

    thead {
        background-color: #D1E4F6;
        font-size: 15px;
        text-align-last: left;
    }
    
    th, td:not(button) {
        padding: 5px 10px;
        /*border: 1px solid var(--main-blue);*/
    }

    tbody tr:nth-child(even) {
          background-color: var(--main-blue-op01);
    }

</style>