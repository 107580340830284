import {analytics} from "../firebase"
import { logEvent, setUserId } from "firebase/analytics";


export function logScreen(screenName:string) {
    logEvent(analytics, 'screen_view', {
        firebase_screen: screenName, 
        firebase_screen_class: ""
      });    
}

export function setUser(userId:string) {
    setUserId(analytics,userId) 
}

export function logSignUp(method:string) {
    logEvent(analytics, 'sign_up', {
        method
      });    
}

export function logLogin(method:string) {
    logEvent(analytics, 'login', {
        method
      });    
}