import {downloadYoutube as _downloadYoutube } from './functions'
import {database} from "../firebase"
import {ref,onValue, push,set,remove} from 'firebase/database'


export type YoutubeUpdateProgress = {
    name:string
    sourceVideo?:string
    downloaded_bytes:number
    total_bytes:number
}

export async function downloadYoutube(projectId:string, url:string, updateProgress: (update:YoutubeUpdateProgress) => void) {
    let fileName = ""

    const dbRef = ref(database, `projects/${projectId}/uploading`)
    const un = onValue(dbRef, (snapshot) => {
        const data = snapshot.val()
        if(data) { 
            updateProgress(data)
            fileName = data.fileName
        }
    })

    const res = await _downloadYoutube(projectId, url)
    console.log(res)
    un()
    await remove(dbRef)
    if(res.error) {
        throw new Error(res.error)
    }
    return fileName
}