<script lang="ts">
        import AppLayout from "../layouts/App.svelte"

        import ProjectsList from "../components/ProjectsList/ProjectsList.svelte";

    	import { Router, Link, Route, useLocation, useNavigate } from "svelte-navigator";

      import { projectsListStore,  } from "../stores/projectslist";
      import { getContext, onMount } from "svelte";
      import { loadingStore } from "../stores/loading";
      import { targetLanguagesLoaded, sourceLanguagesLoaded } from "../stores/langs";
      import { dbUserProfileStore } from "../stores/db";
      import { derived, type Readable } from "svelte/store";
      import DownloadDialog from "../dialogs/Download/Download.svelte";

      const location = useLocation();
      const navigate = useNavigate()


      let downloadProject = null
      let downloadDialogOpen = false
      const pageNumber = getContext("pageNumber") as Readable<number>

      onMount(()=>{
        const searchParams = new URLSearchParams($location.search);
        const download = searchParams.get("download")
        if(download) {
          downloadProject = download
          downloadDialogOpen = true
          navigate(".", {replace: true})
        }
      })

      const loading = derived([targetLanguagesLoaded, sourceLanguagesLoaded, dbUserProfileStore], 
        ([$targetLanguagesLoaded, $sourceLanguagesLoaded, $dbUserProfileStore]) => !$sourceLanguagesLoaded || !$targetLanguagesLoaded || $dbUserProfileStore.loading)

        loadingStore.add(loading)

        $: {
          if(!$loading) {
            if($projectsListStore.length === 0 && $pageNumber <= 2) {
              navigate("/create")
            }
          }
        }

</script>

<AppLayout>
    <div class="axis-y" style="height: 100%">
    
        <div class="caption">
          <Link to="/create">
            <button class="left-align">+ Create new project</button>        
          </Link>
        </div>

        <ProjectsList projects={$projectsListStore}/>
        
        <!--<ProjectList projects={projects} deleteProject={deleteProject}/>-->
      
    </div>  
    <DownloadDialog bind:show={downloadDialogOpen} projectId={downloadProject}/>
</AppLayout>



