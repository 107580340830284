import { DecoratorNode, type LexicalNode, type NodeKey } from "lexical";
import type {ComponentProps, SvelteComponent} from 'svelte';
import RecordTranslationHeader from "./RecordTranslationHeader.svelte";

type DecoratorTranslationHeaderType = {
    componentClass: typeof SvelteComponent;
    props: ComponentProps<RecordTranslationHeader>;
  };
  

export class TranslateHeaderNode extends DecoratorNode<DecoratorTranslationHeaderType> {
  __id:string 
 
    static getType(): string {
      return 'record-translation-header';
    }
  
    static clone(node: TranslateHeaderNode): TranslateHeaderNode {
      return new TranslateHeaderNode(node.__id, node.__key);
    }
  
    constructor(id:string, key?: NodeKey) {
      super(key);
      this.__id = id
    }

    isIsolated(): boolean { return true; }
/*
    setNumber(num:number):this {
        const self = this.getWritable();
        self.__num = num;
        return self;
    }

    getNumber():number {
        return this.__num;
    }
*/  
    createDOM(): HTMLElement {
        const el = document.createElement('div');
        el.classList.add('voiceover-record-translation-header');
        el.style.userSelect = 'none';
        return el
    }
  
    updateDOM(): false {
      return false;
    }
  
    decorate(): DecoratorTranslationHeaderType {
        return {
            componentClass: RecordTranslationHeader,
            props: {
                id: this.__id
            }
        }
      
    }
  }
  
  export function $createTranslateHeaderNode(id:string): TranslateHeaderNode {
    return new TranslateHeaderNode(id);
  }
  
  export function $isTranslateHeaderNode(node: LexicalNode): boolean {
    return node instanceof TranslateHeaderNode;
  }