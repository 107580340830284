import { getFunctions, httpsCallable } from "firebase/functions";
import type { FnTypes } from "common";

export async function test() {
    const functions = getFunctions();
    const testFn = httpsCallable(functions, 'test-function');
    const res = await testFn({
        param1:"string value",
        param2: false,
        param3: 42
    })

    console.log(res)

}


export async function analyze(projectId:string) : Promise<FnTypes.analyzeResults> {
    const functions = getFunctions();
    const analyzeFn = httpsCallable(functions, 'analyze');
    const res = await analyzeFn({
        projectId
    })

    return (res as any).data 
}

export async function createProject(projectId:string, projectName:string, srcLang:string, tgtLangs:string[],customDic:string,oneClick:boolean) {

    const functions = getFunctions();
    const createProjectFn = httpsCallable<FnTypes.createProjectParams,FnTypes.createProjectResults>(functions, 'create-project', {timeout:300000});
    const res = await createProjectFn({
        projectId,
        projectName,
        srcLang,
        tgtLangs,
        customDic,
        oneClick
    })

    return res.data
} 

export async function addCredits(userId:string,amount:number) {
    const functions = getFunctions();
    const addCreditsFn = httpsCallable(functions, 'addCredits');
    const res = await addCreditsFn({
        userId,
        amount,
    })

    return (res as any).data 
}

export async function getProjectUrls(projectId:string) : Promise<FnTypes.getProjectUrlsResult> {
    const functions = getFunctions();
    const getProjectUrlsFn = httpsCallable(functions, 'getProjectUrls');
    const res = await getProjectUrlsFn({
        projectId
    })

    return (res as any).data 
}

export async function voiceoverInit(projectId:string, target:string) : Promise<FnTypes.voiceoverInitResults> {
    const functions = getFunctions();
    const voiceoverInitFn = httpsCallable(functions, 'voiceoverInit');
    const res = await voiceoverInitFn({
        projectId,
        target
    })

    return (res as any).data 
}

export async function generateVoiceoverSound(projectId:string, target:string, recordId:string) : Promise<FnTypes.generateVoiceoverSoundResults> {
    const functions = getFunctions();
    const generateVoiceoverSoundFn = httpsCallable(functions, 'generateVoiceoverSound');
    const res = await generateVoiceoverSoundFn({
        projectId,
        target,
        recordId
    })

    return (res as any).data 
}


export async function generateVideo(projectId:string, target:string, resultId:string, fileName:string, format:string) : Promise<FnTypes.generateVideoResults> {
    const functions = getFunctions();
    const generateVideoFn = httpsCallable(functions, 'generateVideo');
    const res = await generateVideoFn({
        projectId,
        target,
        resultId,
        fileName,
        format
    })

    return (res as any).data 
}

export async function getDownloads(projectId:string) : Promise<FnTypes.getDownloadsResults> {
    const functions = getFunctions();
    const getDownloadsFn = httpsCallable(functions, 'getDownloads');
    const res = await getDownloadsFn({
        projectId
    })

    return (res as any).data 
}

export async function downloadYoutube(projectId:string,url:string)  {
    const functions = getFunctions();
    const downloadYoutubeFn = httpsCallable(functions, 'youtube_dl',{timeout:300000});
    const res = await downloadYoutubeFn({
        projectId,
        url
    })

    return (res as any).data 
}

export async function rewriteRecord(action:"longer" | "shorter", language: string,text: string ):Promise<FnTypes.rewriteResults>  {
    const functions = getFunctions();
    const rewriteRecordFn = httpsCallable(functions, 'rewriteRecord');
    const res = await rewriteRecordFn({
        action,
        language,
        text
    })

    return (res as any).data 
}

export async function sendFeedback(feedback:string) {
    const functions = getFunctions();
    const sendFeedbackFn = httpsCallable<FnTypes.sendFeedbackParams,FnTypes.sendFeedbackResults>(functions, 'send-feedback');
    const res = await sendFeedbackFn({
        feedback
    })

    return res.data
}

export async function adminGetUsersList() {
    const functions = getFunctions();
    const adminGetUsersListFn = httpsCallable<FnTypes.getUsersListParams,FnTypes.getUsersListResults>(functions, 'fb-admin-get-users-list');
    const res = await adminGetUsersListFn({})

    return res.data 
}

export async function translateAll(projectId:string,target:string,engine:string) {
    const functions = getFunctions();
    const translateAllFn = httpsCallable<FnTypes.translateAllParams,FnTypes.translateAllResults>(functions, 'function-fb-transalte-all',{timeout:300000});
    const res = await translateAllFn({
        projectId,
        target,
        engine
    })

    return res.data 
}

export async function translateRecord(text:string,srcLang:string,tgtLang:string,speakerGender?:string) {
    const functions = getFunctions();
    const translateRecordFn = httpsCallable<FnTypes.translateRecordParams,FnTypes.translateRecordResults>(functions, 'function-fb-transalte-record');
    const res = await translateRecordFn({
        text,
        srcLang,
        tgtLang,
        speakerGender
    })  

    return res.data
}