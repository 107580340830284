// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

function readConfig() {
  const request = new XMLHttpRequest();
  request.open("GET", "/firebase_config.json", false); // `false` makes the request synchronous
  request.send(null);

  if (request.status !== 200) {
    throw new Error("error read config")
  }

  const config = JSON.parse(request.responseText).result.sdkConfig
  return config
}

//console.log(halt(readConfig()))




// Your web app's Firebase configuration
/*
const firebaseConfig = {
  apiKey: import.meta.env.VITE_apiKey,
  authDomain: import.meta.env.VITE_authDomain,
  databaseURL: import.meta.env.VITE_databaseURL,
  projectId: import.meta.env.VITE_projectId,
  storageBucket: import.meta.env.VITE_storageBucket,
  messagingSenderId: import.meta.env.VITE_messagingSenderId,
  appId: import.meta.env.VITE_appId
};
*/
const firebaseConfig = readConfig()

export const projectId = firebaseConfig.projectId

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const database = getDatabase(app);
export const storage = getStorage(app)
export const auth = getAuth(app);
export const analytics = getAnalytics(app);