import {ElementNode,TextNode,type EditorConfig,type LexicalNode, type NodeKey, type SerializedElementNode, type SerializedTextNode, type Spread} from 'lexical';


export type SerializedTranslationTextNode = Spread<
  {
    type: 'voiceover-translation-text';
    version: 1;
  },
  SerializedTextNode
>;

export class TranslationTextNode extends TextNode {

    static getType(): string {
        return 'voiceover-translation-text';
    }
  
    static clone(node: TranslationTextNode): TranslationTextNode {
        return new TranslationTextNode(node.__text, node.__key);
    }

    constructor(text:string, key?: NodeKey) {
        super(text,key);
    }
    
    createDOM(config: EditorConfig): HTMLElement {
        const element = super.createDOM(config); //document.createElement('div')
        element.classList.add("voiceover-record-translation-text")
        return element;
      }

    updateDOM(prevNode: TranslationTextNode, dom: HTMLElement,config: EditorConfig): boolean {
        const isUpdated = super.updateDOM(prevNode, dom, config);
        return isUpdated;
    }

    exportJSON(): SerializedTranslationTextNode {
        return {
            ...super.exportJSON(),
            type: 'voiceover-translation-text',
            version: 1,
        };
    }
        
}


export function $createTranslationTextNode(text:string): TranslationTextNode {
    return new TranslationTextNode(text);
}
  
export function $isTranslationTextNode(node: LexicalNode): boolean {
   return node instanceof TranslationTextNode;
}
