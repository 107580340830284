<script lang="ts">
    import { getContext } from "svelte";
    import {timelineStoreSymbol,timesampToScreen} from "./store";
    import type {TimelineStore} from "./store";

    type Overlap = {
        start:number,
        end:number,
    }

    export let overlaps:Overlap[]

    const ctx = getContext<TimelineStore>(timelineStoreSymbol);
</script>

{#each overlaps as overlap}
{@const s_start = Math.max(timesampToScreen($ctx,overlap.start),0)}
{@const s_end = Math.min(timesampToScreen($ctx,overlap.end),$ctx.width)}
{@const s_duartion = s_end - s_start}
    {#if s_duartion > 0}
        <div class="overlap" style="width:{s_duartion}px; left:{s_start}px">
        </div>
    {/if}
{/each}



<style>
    .overlap {
        position: absolute;
        background-color: red;
        opacity: 0.2;
        top:28px;
        height: 32px;
        
    }
</style>