<script lang="ts">
  import { generateVideo } from "../stores/voiceover";
    import Dialog from "../components/Dialog.svelte";
    import { useNavigate, useLocation } from "svelte-navigator";
    import SvgFile from "../assets/file.svg";
    export let show: boolean = true;


    const navigate = useNavigate();

    const formats = [
        {name:".mp4", format: "mp4"},
        {name:".mp3", format: "mp3"},
    ]

    let activeFormat = null

    function handleGenerate() {
        generateVideo(activeFormat.format)
        navigate("/projects",{state:{
            projectId: "test",
            target: "en"
        }});
    }
    function handleCancel() {
        show = false
    }

    function changeActive(f) {
        activeFormat = f
    } 
</script>
   
<Dialog bind:show={show} title="Generate as:">
<!-- 
    <div>Export dialog content</div>
    <div class="axis-x">
        {#each formats as format}
            <div class="format" class:active={format === activeFormat} on:click={() => changeActive(format) }>{format.name}</div>
        {/each}
    </div> -->
    
    <div class="axis-x" style="margin-bottom: 32px;">
        {#each formats as format}
            <div class:selected={format === activeFormat} class="file-type-button" on:click={() => changeActive(format) }>
                <SvgFile/>
                <span>{format.name}</span>
            </div>
        {/each}
    </div>

    <div class="axis-x">
        <button on:click={handleCancel} class="button-blue-text" style="margin-right: 24px;">Cancel</button>
        <button on:click={handleGenerate} disabled={!activeFormat}>Generate</button>
    </div>
</Dialog>
   
<style>

.file-type-button {
     width: 64px;
     height: 78px;
     padding: 6px 16px;
     background-color: transparent;
     border-radius: 4px;
     border: 1px solid var(--disabled-gray);
}

.file-type-button:not(:last-child) {
    margin-right: 24px;
}


.file-type-button:hover :global(svg) {
    color: var(--hover-blue);
}


.file-type-button:hover {
    border: 1px solid var(--hover-blue);
    color: var(--hover-blue);
}    
/* .file-type-button:active {
    background-color: var(--main-blue-op04);
}
   */
.selected {
    background-color: var(--main-blue); 
    color: white;
} 


</style>   