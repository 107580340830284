<script lang="ts">
    import { createEventDispatcher } from "svelte";
    import { clickOutside } from "../utils/clickOutside";

    // @ts-ignore
    import SvgCloseExtraSmall from "../assets/close_extra_small.svg"
    // @ts-ignore
    import SvgBin from "../assets/bin.svg"
    // @ts-ignore
    import SvgCheckSmall from "../assets/check_small.svg"

    export let big=false;

    const dispatch = createEventDispatcher()

    let overlayOpen = false


    function handleClick() {
        overlayOpen = true
    }

    function handleOk() {
        dispatch("delete")
        overlayOpen = false
    }
    function handleCancel() {
        overlayOpen = false
    }
</script>

<div class="container" class:big={big}>
    <button on:click={handleClick} class={big?"red-bin":"squre20-button"}>
        <SvgBin style="margin: 0 auto"/>
    </button>
    
    {#if overlayOpen}
        <div class="overlay axis-x" use:clickOutside on:click_outside={handleCancel}>
            <span style="margin: 5px 7px 0 20px">Are you sure?</span>
            <button on:click={handleOk} class="svg-button squre16-button" style="margin: 2px 3px 0 0"><SvgCheckSmall style="margin: 0 auto"/></button>
            <button on:click={handleCancel} class="svg-button squre16-button" style="margin-top: 2px"><SvgCloseExtraSmall style="margin: 0 auto"/></button>
        </div>
    {/if}
</div>


<style>
    
    .container {
        position: relative; 
        display: inline-block;
    }

    .red-bin {
        width: 32px;
        height: 32px;
        background-color: var(--red-bin);
    }

    :global(.red-bin svg path),
    :global(.red-bin svg rect) {
        stroke: white;
    }

    .container>button {
        border: none;
        padding: 0;
        margin: 0;
        position: relative;
        z-index: 2;
    } 

    .container:not(.big)>button:hover:not(:disabled) {
        background: none; 
    }
    .container.big >button:hover:not(:disabled) {
        background-color: var(--red-bin);
    }
        
    .container:not(.big)>button:active:not(:disabled) {
        background-color: var(--red-bin-op01); 
        outline: none; 
    } 
    .container.big>button:active:not(:disabled) {
        outline: 2px solid var(--red-bin-op01); 
    } 

    .overlay {
        position: absolute;
        width: 118px;
        height: 20px;
        font-size: 9px;
        font-weight: 400;
        box-shadow: 2px 2px 6px 2px rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        left: 0;
        top: 0;
        z-index: 1;
        background-color: white;
    }

    .big .overlay { 
        left: 17px;
        top: 5px;
    }

/*     .overlay>button:active:not(:disabled) {
        outline: none; 
    }
 */
    /* .squre16 {
        width: 16px;
        height: 16px;
        padding: 0;
        background-color: transparent;
        border-radius: 4px;
    } */

</style>