import { writable, derived, get,  } from "svelte/store";
import { editorMode } from "./editorMode";
import { fragments, voiceoverSounds } from "./voiceover";

export const videoFileUrl = writable<string | null>(null);
export const cleanedAudioUrl = writable<string | null>(null);

export const volume = writable<number>(1);

export const videoFile = videoFileUrl //derived(project, ($project) => $project.loaded ? $project.videoUrl : null);
//export const videoDuration = derived(project, ($project) => $project.loaded ? $project.duration : 0);
const cleanedAudio = derived(cleanedAudioUrl, ($cleanedAudioUrl) => $cleanedAudioUrl ? new Audio($cleanedAudioUrl): null);

const _currentPos = writable(0);

export const currentPos = (_currentPos);
export const isPlaying = writable(false);

let _audioContext: AudioContext | null = null;
let _mainGainNode: GainNode | null = null;
function getAudioContext() {
    if (!_audioContext) {
        _audioContext = new AudioContext();
        _mainGainNode = _audioContext.createGain()
        _mainGainNode.gain.value = get(volume)
        _mainGainNode.connect(_audioContext.destination)
        _audioContext.suspend()

        console.log("recreate AudioContext")
        console.trace()
    }
    
    return {
        context:_audioContext,
        gain:_mainGainNode
    }
}

volume.subscribe(v => {
    if(_mainGainNode && Number.isFinite(v)) _mainGainNode.gain.value = v
})

let videoNode: MediaElementAudioSourceNode | null = null;
let videoGainNode: GainNode | null = null;
function getVideoNode() {
    const video = get(videoEl);
    if(!video) return null
    if (!videoNode) {
        const audioContext = getAudioContext();
        videoNode = audioContext.context.createMediaElementSource(video);
        videoGainNode = audioContext.context.createGain()
        videoGainNode.connect(audioContext.gain)
        videoNode.connect(videoGainNode)
    }

    return {
        video,
        videoNode,
        videoGainNode
    }

}
function ressetVideoNode() {
    videoNode?.disconnect()
    videoNode = null
    videoGainNode?.disconnect()
    videoGainNode = null
}

let cleanedAudioNode: MediaElementAudioSourceNode | null = null;
let cleanedAudioGainNode: GainNode | null = null;
function getCleanedAudioNode() {
    const audio = get(cleanedAudio);
    if(!audio) return null
    if (!cleanedAudioNode) {
        console.log("recreate CleanedAudioNode")
        console.trace()
        const audioContext = getAudioContext();
        audio.crossOrigin = "anonymous";
        cleanedAudioNode = audioContext.context.createMediaElementSource(audio);
        cleanedAudioGainNode = audioContext.context.createGain()
        cleanedAudioGainNode.connect(audioContext.gain)
        cleanedAudioNode.connect(cleanedAudioGainNode)
    }

    return {
        audio,
        cleanedAudioNode,
        cleanedAudioGainNode
    }

}

function ressetAudioNode() {
    cleanedAudioNode?.disconnect()
    cleanedAudioNode = null
    cleanedAudioGainNode?.disconnect()
    cleanedAudioGainNode = null
}

cleanedAudioUrl.subscribe(() => {
    console.log("cleanedAudioUrl.subscribe")
    ressetAudioNode()
})


export const videoEl = (()=>{
    const { subscribe, set } = writable<HTMLVideoElement | null>(null);
    return {
        subscribe,
        set: (el: HTMLVideoElement | null) => {
            //console.log("videoEl.set", el)
            ressetVideoNode()

            if (el) {
                el.src = get(videoFileUrl);

                el.addEventListener("loadedmetadata", () => {
                    set(el);
                });

                el.addEventListener("timeupdate", (e) => {_currentPos.set(el.currentTime)})

                el.addEventListener("ended", () => isPlaying.set(false))

                //------------------------------------
                el.addEventListener("canplay", () => console.log("canplay"))
                el.addEventListener("canplaythrough", () => console.log("canplaythrough"))
                el.addEventListener("complete", () => console.log("complete"))
                el.addEventListener("durationchange", () => console.log("durationchange"))
                el.addEventListener("emptied", () => console.log("emptied"))
                el.addEventListener("ended", () => console.log("ended"))
                el.addEventListener("error", () => console.log("error"))
                el.addEventListener("loadeddata", () => console.log("loadeddata"))
                el.addEventListener("loadedmetadata", () => console.log("loadedmetadata"))
                el.addEventListener("pause", () => console.log("pause"))
                el.addEventListener("play", () => console.log("play"))
                el.addEventListener("playing", () => console.log("playing"))
                el.addEventListener("progress", () => console.log("progress"))
                el.addEventListener("ratechange", () => console.log("ratechange"))
                el.addEventListener("seeked", () => console.log("seeked"))
                el.addEventListener("seeking", () => console.log("seeking"))
                el.addEventListener("stalled", () => console.log("stalled"))
                el.addEventListener("suspend", () => console.log("suspend"))
                el.addEventListener("timeupdate", () => console.log("timeupdate"))
                el.addEventListener("volumechange", () => console.log("volumechange"))
                el.addEventListener("waiting", () => console.log("waiting"))
                //------------------------------------

            } else {
                set(null);
            }
        }
    }
})()

export const videoDuration = derived(videoEl, ($videoEl) => $videoEl?.duration || 0);

videoFileUrl.subscribe((url) => {
    const el = get(videoEl);
    if (el) {
        el.src = url;
    }

});

function seekMedia(media: HTMLMediaElement, pos: number) {
    if(media.currentTime === pos)
        return Promise.resolve()
    media.currentTime = pos;
    return waitForEvent(media, "seeked")
}

function __play(muted: boolean = false) {
    const audioContext = getAudioContext();
    const vn = getVideoNode();
    if (vn) {
        isPlaying.set(true);

        vn.videoGainNode.gain.value = muted ? 0 : 1;//.setValueAtTime(1, audioContext.currentTime);
        vn.video.play();
        console.log("resume")
        audioContext.context.resume();
    }
}

function __pause() {
    isPlaying.set(false);
    get(videoEl)?.pause();
    getAudioContext().context.suspend();
}

function __seek(pos: number) {
    const vn = getVideoNode();
    if (vn) {
        seekMedia(vn.video, pos);
    }
}

export function play() {
    if(get(editorMode) === "voiceover") 
        return __playTimeline();
    else
        return __play();
}

export function pause() {
    if(get(editorMode) === "voiceover")
        return __pauseTimeline();
    else
        return __pause();
}

export async function seek(pos: number) {
    if(get(editorMode) === "voiceover")
        return __seekTimeline(pos);
    else
        return __seek(pos);
}

function subscribe(el: EventTarget,event:string, fn: () => void) {
    el.addEventListener(event, fn);
    return () => el.removeEventListener(event, fn);
}

function waitForEvent(el: EventTarget,event:string, condition?: () => boolean) {
    return new Promise<void>((resolve) => {
        const unsubscribe = subscribe(el, event, () => {
            if (condition && !condition()) return;
            unsubscribe();
            resolve();
        });
    });
}

function playTill(t:number) {
    let pauseOff = () => {};
    let timeUpdateOff = () => {};
    return new Promise<void>((resolve,reject) => {
        const video = get(videoEl);
        if(!video) 
            reject ("No video element");
            
        pauseOff = subscribe(video, "pause", () => resolve());
        timeUpdateOff = subscribe(video, "timeupdate", () => {
            if (video.currentTime >= t) {
                pause()
            }
        })
        //error
        __play()

    }).finally(() => {
        pauseOff();
        timeUpdateOff();
    })

}

export async function playRange(start: number, end: number) {
    const video = get(videoEl);
    if(!video) throw ("No video element");
    await seek(start);
    await playTill(end);
}

export async function playSound(url: string, start: number) {
    console.log("playSound", start)
    const vn = getVideoNode()
    const audioContext = getAudioContext();
    console.log("playSound", audioContext.context.state)
    await seek(start);
    let audioBuffer = await preCacheSoundUrl(url)
   

    const source = audioContext.context.createBufferSource();
    source.buffer = audioBuffer;
    source.connect(audioContext.gain);


    __play(true);
    source.start();
    //const audioBuffer
    await Promise.any([
        waitForEvent(source, "ended"),
        waitForEvent(vn.videoNode.mediaElement, "pause")
    ])
    source.stop()
    source.disconnect();
    __pause();

}

editorMode.subscribe((mode) => {
    if(get(isPlaying))
        pause();
})

const SoundCache = new Map<string, AudioBuffer>();


export async function preCacheSoundUrl(url: string) {
    const audioContext = getAudioContext();
    let audioBuffer = SoundCache.get(url) 
    if(!audioBuffer) {
        console.log("preCacheSoundUrl", url)
        const res = await fetch(url);
        const buffer = await res.arrayBuffer();
        audioBuffer = await audioContext.context.decodeAudioData(buffer);
        SoundCache.set(url, audioBuffer);
    }
    return audioBuffer;
}

function _play() {
    const audioContext = getAudioContext();
    if(audioContext.context.state === "running") {
        throw new Error("Already playing")
    }


} 
//audioCtx.state === "suspended"
//audioCtx.state === "running"

//range
//background
//sounds

//playOriginal
//PlayTTS
//playTimeline

const groupBy = <T>(xs:T[], fn:(a:T)=>string ) : {[key:string]:T[]} => {
    return xs.reduce(function(rv, x) {
      (rv[fn(x)] = rv[fn(x)] || []).push(x);
      return rv;
    }, {});
  };

const plannedSounds:AudioBufferSourceNode[] = []
async function __playTimeline() {
    console.log("__playTimeline")

    const sounds = get(voiceoverSounds)
    await Promise.all(sounds.filter(s=>s.url).map(s => preCacheSoundUrl(s.url)))

    const audioContext = getAudioContext();
    const vn = getVideoNode();
    const ca = getCleanedAudioNode();
    if (!vn || !ca)  {
        console.log("No video node")
        return;
    }

    const startTime = vn.video.currentTime
    console.log("seeking", startTime)
    await seekMedia(ca.cleanedAudioNode.mediaElement, startTime);
    console.log("seeked")

    //audioContext.currentTime

    const videoVolumes = [
        {timestamp : - startTime, volume: 1},
    ]
    const audioVolumes = [
        {timestamp : - startTime, volume: 0},
    ]

    
    for(const f of get(fragments).sort((a,b)=> a.start - b.start) ) {
        if(f.cleaned) {
            videoVolumes.push({timestamp: f.start - startTime, volume: 0})  
            audioVolumes.push({timestamp: f.start - startTime, volume: 1 + f.volume})
        } else {
            videoVolumes.push({timestamp: f.start - startTime, volume: 1 + f.volume})  
            audioVolumes.push({timestamp: f.start - startTime, volume: 0})
        }

        //videoVolumes.push({timestamp: f.start, volume: 1})
        videoVolumes.push({timestamp: f.start + f.duration  - startTime, volume: 1})
        audioVolumes.push({timestamp: f.start + f.duration  - startTime, volume: 0})

    }
    const grouppedVideoVolumes = groupBy(videoVolumes, (a)=>a.timestamp <= 0 ? "0" : a.timestamp.toString())
    const grouppedAudioVolumes = groupBy(audioVolumes, (a)=>a.timestamp <= 0 ? "0" : a.timestamp.toString())

    const startVideoVolume = grouppedVideoVolumes["0"][grouppedVideoVolumes["0"].length - 1].volume
    const startAudioVolume = grouppedAudioVolumes["0"][grouppedAudioVolumes["0"].length - 1].volume

    console.log("startVideoVolume", startVideoVolume)
    console.log("startAudioVolume", startAudioVolume)

    const filteredVideoVolumes =  Object.entries(grouppedVideoVolumes).filter(([k,v])=>k!=="0").map(([_,v]) => v[v.length-1] ).sort((a,b)=>a.timestamp - b.timestamp)
    const filteredAudioVolumes =  Object.entries(grouppedAudioVolumes).filter(([k,v])=>k!=="0").map(([_,v]) => v[v.length-1] ).sort((a,b)=>a.timestamp - b.timestamp)
    

    vn.videoGainNode.gain.value = startVideoVolume;
    ca.cleanedAudioGainNode.gain.value = startAudioVolume;

   for(const v of filteredVideoVolumes) {
         //console.log("video", v.timestamp, v.volume)
         //vn.videoGainNode.gain.linearRampToValueAtTime(v.volume, v.timestamp);
         vn.videoGainNode.gain.setValueAtTime(v.volume, v.timestamp + audioContext.context.currentTime);
   }

    for(const v of filteredAudioVolumes) {
        //console.log("audio", v.timestamp, v.volume)
        //ca.cleanedAudioGainNode.gain.linearRampToValueAtTime(v.volume, v.timestamp);
        ca.cleanedAudioGainNode.gain.setValueAtTime(v.volume, v.timestamp + audioContext.context.currentTime);
    }

    for(const s of sounds) {
        if(!s.url) continue;
        const audioBuffer = SoundCache.get(s.url) 

        if(!audioBuffer) {
            throw new Error("Sound not cached")
        }
        const start = s.start - startTime
        if(start + s.duration < 0) continue;

        const source = audioContext.context.createBufferSource();
        source.buffer = audioBuffer;
        source.connect(audioContext.gain);
        
        if(start <0) {
            source.start(audioContext.context.currentTime, -start);
        } else {
            source.start(start + audioContext.context.currentTime);
        }
        
        console.log("playing sound", s.start - startTime + audioContext.context.currentTime)
        plannedSounds.push(source)
    }


    isPlaying.set(true);
    await audioContext.context.resume();
//    vn.video.play();
    //ca.audio.play();
    ca.cleanedAudioNode.mediaElement.play()
    vn.videoNode.mediaElement.play()

    console.log(audioContext)


}

async function __pauseTimeline() {
    console.log("__pauseTimeline")
    const audioContext = getAudioContext();
    isPlaying.set(false);
    const vn = getVideoNode();
    const ca = getCleanedAudioNode();
    if (!vn || !ca)  {
        console.log("No video node")
        return;
    }
    //vn.video.pause();
    //ca.audio.pause();
    ca.cleanedAudioNode.mediaElement.pause()
    vn.videoNode.mediaElement.pause()
    await audioContext.context.suspend();
    vn.videoGainNode.gain.cancelScheduledValues(audioContext.context.currentTime)
    ca.cleanedAudioGainNode.gain.cancelScheduledValues(audioContext.context.currentTime)
    for(const s of plannedSounds) {
        s.stop()
        s.disconnect()
    }
}

async function __seekTimeline(pos: number) {
    console.log("__seekTimeline", pos)

    const playing = get(isPlaying)

    const vn = getVideoNode();
    const ca = getCleanedAudioNode();
   if (!vn || !ca)  {
        console.log("No video node")
        return;
    }

    if(playing) {
        await __pauseTimeline()
    }
    console.log("seeking video", pos)
    await seekMedia(vn.video, pos);
    console.log("video seeked")
    console.log("seeking audio", pos)
    await seekMedia(ca.audio, pos);
    console.log("audio seeked")
    if(playing) {
        __playTimeline()
    }

}