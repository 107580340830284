<script lang="ts">
    export let id:string;
    export let lang:string;

    import { Router, Link, Route } from "svelte-navigator";
  
    import Transcript from "./Transcript.svelte";
    import Translate from "./Translate.svelte";
    import Voiceover from "./Voiceover.svelte";
    import AppLayout from "../layouts/App.svelte"

    import RedirectRoute from "../navigate/RedirectRoute.svelte";
    import { onMount } from "svelte";
    import { project, forceRwSwtitch } from "../stores/project";
    import { loadingStore } from "../stores/loading";
    import { currentProject } from "../stores/currentProject";
    import { derived, writable } from "svelte/store";
    import ReadonlyBanner from "../components/ReadonlyBanner.svelte";

    const mounted = writable(false)
    const loading = derived([project,mounted], ([$project,$mounted]) => !$mounted || !$project.loaded)
    loadingStore.add(loading)

    onMount(() => {
      currentProject.set({projectId:id,targetCode:lang})
      forceRwSwtitch.set(false)
      mounted.set(true)
    });



</script>

<AppLayout>
  <Route path="transcript"><Transcript /></Route>
  <Route path="translate"><Translate /></Route>
  <Route path="voiceover"><Voiceover /></Route>
  <RedirectRoute path="*" to="/"/>
</AppLayout>

<ReadonlyBanner/>