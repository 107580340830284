<script>
    import SvgBigLogo from '../assets/big_logo0.svg';
</script>

<div class="big-loader">
    <div>
        <SvgBigLogo color="#1976D2"/>
        <div class="big-dot-elastic"></div>
    </div>
</div>

<style>
.big-loader {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;    
    z-index: 10;
    /*background-color: white;
    border: 1px solid black;*/
}
  
  .big-dot-elastic {
    position: relative;
    z-index: 10;
    left: 100px;
    top: -145px;
    width: 21px;
    height: 39px;
    border-radius: 20px;
    background-color: #1976D2;
    color: #1976D2;
    animation: big-dot-elastic 3s infinite linear;
  }
  .big-dot-elastic::before, .big-dot-elastic::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }
  .big-dot-elastic::before {
    left: -42px;
    width: 21px;
    height: 39px;
    border-radius: 20px;
    background-color: #1976D2;
    color: #1976D2;
    animation: big-dot-elastic-before 3s infinite linear;
  }
  .big-dot-elastic::after {
    left: 42px;
    width: 21px;
    height: 39px;
    border-radius: 20px;
    background-color: #1976D2;
    color: #1976D2;
    animation: big-dot-elastic-after 3s infinite linear;
  }
  
  @keyframes big-dot-elastic-before {
    0% {
      transform: scale(1, 1);
    }
    25% {
      transform: scale(1, 2);
    }
    50% {
      transform: scale(1, 0.5);
    }
    75% {
      transform: scale(1, 1);
    }
    100% {
      transform: scale(1, 1);
    }
  }
  
  @keyframes big-dot-elastic {
    0% {
      transform: scale(1, 1);
    }
    25% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1, 2);
    }
    75% {
      transform: scale(1, 1);
    }
    100% {
      transform: scale(1, 1);
    }
  }
  
  @keyframes big-dot-elastic-after {
    0% {
      transform: scale(1, 1);
    }
    25% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1, 0.5);
    }
    75% {
      transform: scale(1, 2);
    }
    100% {
      transform: scale(1, 1);
    }
  }
</style>