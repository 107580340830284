import { mergeRegister } from "@lexical/utils"
import { $getRoot, $isLineBreakNode, $isTextNode, $getNodeByKey,$getSelection,$isRangeSelection, COMMAND_PRIORITY_LOW, createCommand, type RangeSelection, type EditorState, type LexicalEditor, type LexicalNode,type NodeMutation } from "lexical"
import type { NodeDebugInfo } from "../../lexer-editor/debugger"
import type { VoiceoverRecord } from "../../stores/voiceover"
import { $createRecordNode, $isRecordNode, RecordNode } from "./RecordNode"
import { $isTranslationTextNode, TranslationTextNode } from "./TranslationTextNode"


export type CommandSetSpeakerArgs = {
    recordId : string,
    speaker : string,
}

export type CommandRenameSpeakerArgs = {
    oldName : string,
    newName : string,
}

export type CommandSetTranslationArgs = {
    recordId : string,
    translation : string,
}

export const SET_SPEAKER_COMMAND = createCommand<CommandSetSpeakerArgs>();
export const RENAME_SPEAKER_COMMAND = createCommand<CommandRenameSpeakerArgs>();
export const FORCE_SAVE_COMMAND = createCommand();
export const SET_TRANSLATION_COMMAND = createCommand<CommandSetTranslationArgs>();


export function initEditorState(editor:LexicalEditor, voiceover:VoiceoverRecord[]) {
    editor.update(() => {
        const root = $getRoot();
        root.clear();
        voiceover.forEach((record) => {
            root.append($createRecordNode(record.id, record.speaker, record.voiceoverText))
        })
    })
}

export function getRecordKeyById(editor:LexicalEditor, id:string) {
    return editor.getEditorState().read(()=>{
        return ($getRoot().getChildren() as RecordNode[]).find(r => r.getId() === id)?.getKey()
    })
}

function assignHoverListeners (
    editor: LexicalEditor,
    handleMouseRecordEnter: (recordId:string) => void,
    handleMouseRecordLeave: (recordId:string) => void,
) {
    return function (mutations: Map<string, NodeMutation>) {
        const registeredElements: WeakSet<HTMLElement> = new WeakSet();
        editor.getEditorState().read(() => {
            for (const [key, mutation] of mutations) {
                const element: null | HTMLElement = editor.getElementByKey(key);
                const record = $getNodeByKey<RecordNode>(key)
                const recordId = record?.getId()
                if (
                // Updated might be a move, so that might mean a new DOM element
                // is created. In this case, we need to add and event listener too.
                (mutation === 'created' || mutation === 'updated') &&
                element !== null &&
                !registeredElements.has(element)
                ) {
                    registeredElements.add(element);
                    element.addEventListener('mouseenter', (event: Event) => {
                        handleMouseRecordEnter(recordId)
                        //console.log("MOUSE ENTER", event)
                    });
                    element.addEventListener('mouseleave', (event: Event) => {
                        //console.log("MOUSE LEAVE", event)
                        handleMouseRecordLeave(recordId)
                    });
                }
            }
        });

    }
}


function transformRecord(node:RecordNode) {
    
    //console.log("breakRecord", node.getKey())
    const recordItems = node.getChildren().slice(5)
    if(recordItems.length === 1) return

    if(recordItems.length === 2) {
        if($isTranslationTextNode(recordItems[0]) && $isTranslationTextNode( recordItems[1])) {
            //console.log("merge")
            recordItems[0].mergeWithSibling(recordItems[1])
        } else if ($isTranslationTextNode(recordItems[0]) && $isLineBreakNode(recordItems[1])) {
            const newRecordNode = $createRecordNode(crypto.randomUUID(), node.getSpeaker(), " ")
            node.insertAfter(newRecordNode)
            newRecordNode.getChildAtIndex(4).selectNext(1,0)
            recordItems[1].remove()
            //console.log("add line after")
        } else if ($isLineBreakNode(recordItems[0]) && $isTranslationTextNode(recordItems[1])) {
            const newRecordNode = $createRecordNode(crypto.randomUUID(), node.getSpeaker(), " ")
            node.insertBefore(newRecordNode)
            newRecordNode.getChildAtIndex(4).selectNext(1,0)
            recordItems[0].remove()
            //console.log("add line before")
        } else {
            //console.error("unexpected 2")
            console.log(recordItems[0], recordItems[1])
        }

    } else   if(recordItems.length === 3) {
        if( $isTranslationTextNode(recordItems[0]) 
            && $isLineBreakNode(recordItems[1])
            && $isTextNode( recordItems[2])) {
                //console.log("split")
                const newRecordNode = $createRecordNode(crypto.randomUUID(), node.getSpeaker(), recordItems[2].getTextContent())
                node.insertAfter(newRecordNode)
                recordItems[1].remove()
                recordItems[2].remove()
                node.selectNext(1,1)
            } else {
                //console.error("unexpected 3")
            }
    } else {
        //console.error("unexpected ?")
    }
    //console.log(recordItems)
}
function stateToVoiceover(editorState: EditorState) : VoiceoverRecord[] {
    return editorState.read(() => {
        const res:VoiceoverRecord[] = []

        const records = $getRoot().getChildren() as RecordNode[];
        for(const record of records) {
            console.log(record)
            console.log(record.getChildren())
            const rec = {
                id : record.getId(),
                speaker : record.getSpeaker(),
                voiceoverText : record.getChildAtIndex(5)?.getTextContent() ?? "",
                startTime: 0,
                ratio: -1,
            }
            res.push(rec)
        }
        return res
    })
}

let firstUpdate = true
function handleChange(editorState: EditorState,dirtyElements:any, dirtyLeaves: Set<string>, onChange: (voiceover : VoiceoverRecord[]) => void) {
    //console.log("handleChange",dirtyElements,dirtyLeaves)
    if(firstUpdate) {
        firstUpdate = false
        return
    }
    if(dirtyLeaves.size === 0 && dirtyElements.size === 0) return

    const voiceover = stateToVoiceover(editorState)
    onChange(voiceover)
}

function selectionChange(
    editor: LexicalEditor,
    editorState:EditorState,
    onSelectionChange: (recordId:string | null) => void,
    ) {
    const selection = editorState.read(() => {
        const selection = $getSelection();
        
        if(!$isRangeSelection(selection)) {
            return null
        }
        const sel = selection as RangeSelection

        const node = $getNodeByKey(sel.focus.key)
        const parent = node.getParent();
        if($isRecordNode(parent)) {
            return parent.getId()
        }

        //console.log(node)
        return null

      });
    onSelectionChange(selection)

}


function commandSetSpeaker(editor: LexicalEditor,onChange: (voiceover : VoiceoverRecord[]) => void) {
    return function ({recordId,speaker}: CommandSetSpeakerArgs) {
        editor.update(() => {
            const record = $getRoot().getChildren().find(c => $isRecordNode(c) && c.getId() === recordId)
            record.setSpeaker(speaker)
        },{onUpdate: ()=>{
            const voiceover = stateToVoiceover(editor.getEditorState())
            onChange(voiceover)
        }})
        return false;
    }
}

function commandRenameSpeaker(editor: LexicalEditor,onChange: (voiceover : VoiceoverRecord[]) => void) {
    return function ({oldName,newName}: CommandRenameSpeakerArgs) {
        editor.update(() => {
            const records = $getRoot().getChildren() as RecordNode[]
            for(const record of records) {
                if(record.getSpeaker() === oldName) {
                    record.setSpeaker(newName)
                }
            }

        },{onUpdate: ()=>{
            const voiceover = stateToVoiceover(editor.getEditorState())
            onChange(voiceover)
        }})
        return false;
    }
}

function commandForceSave(editor: LexicalEditor,onChange: (voiceover : VoiceoverRecord[]) => void) {
    return function () {
        const voiceover = stateToVoiceover(editor.getEditorState())
        onChange(voiceover)
        return false;
    }
}

function commnadSetTranslation(editor: LexicalEditor,onChange: (voiceover : VoiceoverRecord[]) => void) {
    return function ({recordId,translation}: CommandSetTranslationArgs) {
        //console.log("SET_TRANSLATION_COMMAND",recordId,translation)
        editor.update(() => {
            const record = $getRoot().getChildren().find(c => $isRecordNode(c) && c.getId() === recordId)
            record.getChildAtIndex(5).setTextContent(translation)
        },{onUpdate: ()=>{
            const voiceover = stateToVoiceover(editor.getEditorState())
            onChange(voiceover)
        }})
        return false;
    }
}


export function registerTransformers(
        editor:LexicalEditor, 
        onChange: (voiceover : VoiceoverRecord[]) => void,
        handleMouseRecordEnter: (key:string) => void,
        handleMouseRecordLeave: (key:string) => void,
        onSelectionChange: (recordId:string) => void,
    ) {

    firstUpdate = true

    editor.registerCommand(SET_SPEAKER_COMMAND,     commandSetSpeaker(editor,onChange),    COMMAND_PRIORITY_LOW );
    editor.registerCommand(RENAME_SPEAKER_COMMAND,  commandRenameSpeaker(editor,onChange), COMMAND_PRIORITY_LOW );
    editor.registerCommand(FORCE_SAVE_COMMAND,      commandForceSave(editor,onChange),     COMMAND_PRIORITY_LOW );
    editor.registerCommand(SET_TRANSLATION_COMMAND, commnadSetTranslation(editor,onChange), COMMAND_PRIORITY_LOW );


    return mergeRegister(
        editor.registerUpdateListener(({editorState,dirtyElements,dirtyLeaves}) => handleChange(editorState,dirtyElements,dirtyLeaves,onChange) ),

        editor.registerMutationListener(RecordNode, assignHoverListeners(editor,handleMouseRecordEnter,handleMouseRecordLeave)),
        editor.registerUpdateListener(({editorState}) => selectionChange(editor,editorState,onSelectionChange)),


        //editor.registerNodeTransform(TranslationTextNode, breakRecord),
        editor.registerNodeTransform(RecordNode, transformRecord),
    )
     

}

export function getRecordElementById(editor:LexicalEditor, id:string) {
    return editor.getEditorState().read(() => {
        const record = $getRoot().getChildren().find(c => $isRecordNode(c) && c.getId() === id)
        if(!record) return undefined
        return editor.getElementByKey(record.getKey())
    })
}


export function customNodesInfo(node:LexicalNode): NodeDebugInfo | undefined {

    
    if($isRecordNode(node)) { 
        return {
            nodeType : "record",
            nodeData : `id: ${node.getId()} speaker: ${node.getSpeaker()}`
        }
    }
    if($isTranslationTextNode(node)) { 
        return {
            nodeType : "voiceover-translation-text",
            nodeData : `text: ${node.getTextContent()}`
        }
    }



    return undefined
}