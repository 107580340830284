<script lang="ts">
  import { getFunctions, httpsCallable } from "firebase/functions";
  import DropdownButton from "../controls/DropdownButton.svelte";

  let sys = "" 
  let user = ""   

  let res = ""

  let processing = false

  type FnParams = {
  sysMsg:string
  userMsg:string
}

type FnResults = {
  translated:string
}

  async function submit() {
            try{
                processing = true
                res = ""
                const functions = getFunctions();
                const fn = httpsCallable<FnParams,FnResults>(functions, 'function-fb-sandbox');
                const result = await fn({sysMsg:sys, userMsg:user})
                res = result.data.translated
            } finally {
                processing = false
            }

        }


    let actions = [
        {name: "action 1", action: () => console.log("action 1")},
        {name: "action 2", action: () => console.log("action 2")},
        {name: "action 3", action: () => console.log("action 3")},
        {name: "action 4", action: () => console.log("action 4")},
    ]


</script>


<div class="container">
    <textarea bind:value={sys} rows="10" cols="50" disabled={processing}></textarea>
    <textarea bind:value={user} rows="10" cols="50" disabled={processing}></textarea>
    {#if res != ""}
    <div>
        <p>{res}</p>
    </div>
    {/if}
    <button on:click={submit} disabled={processing}>Submit</button>


    <DropdownButton {actions}/>
</div>

<style>

    .container{
        padding: 100px;
        width: 100%;
        height: 100%;
       }
  
</style>