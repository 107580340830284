/** Dispatch event on click outside of node */
/*
export function clickOutside(node) {
  
    const handleMouseUp = event => { 
        document.removeEventListener('mouseup', handleMouseUp);
        if (node && !node.contains(event.target) && !event.defaultPrevented) {
            node.dispatchEvent(
              new CustomEvent('click_outside', node)
            )
          }

    }

    const handleMouseDown = event => {
        document.addEventListener('mouseup', handleMouseUp);
    }
  
      document.addEventListener('mousedown', handleMouseDown, true);
    
    return {
      destroy() {
        document.removeEventListener('mousedown', handleMouseDown, true);
      }
    }
}
*/

export function clickOutside(node) {
  const handleMouseDown = event => {
      if (node && !node.contains(event.target) && !event.defaultPrevented) {
          node.dispatchEvent(
            new CustomEvent('click_outside', node)
          )
        }

  }

    document.addEventListener('mousedown', handleMouseDown, true);
  
  return {
    destroy() {
      document.removeEventListener('mousedown', handleMouseDown, true);
    }
  }
}
