<script lang="ts">
    import Header from "./Header.svelte";
    import Record from "./Record.svelte";

    import type { ProjectPreview } from "../../types/projectPreview";

    export let projects:ProjectPreview[];

    let popupOpen = false
    function handleLangPopupOpen() {
        popupOpen = true
    }

    function handleLangPopupClose() {
        popupOpen = false
    }

</script>
<div class="project-list" class:popup-open={popupOpen}>
    <table>
        <Header/>
        {#each projects as project, index}
            <Record project={project} odd={index%2 === 0} on:popup_open={handleLangPopupOpen} on:popup_close={handleLangPopupClose}/>
        {/each}
    </table>    
</div>


<style>
    .project-list.popup-open {
        overflow: visible;
        margin-right: 8px;
    }

    .project-list {
        overflow-y: auto;
        overflow-x: hidden; 
    }

    table {
      width: 100%;
      font-size: 14px;
      font-weight: 400;
      text-align: left;
      border-spacing: 0;
      background-color: white;
    }

 
</style>