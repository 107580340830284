<script lang="ts">
    // @ts-ignore
    import SvgMinus from "../../assets/minus.svg";

    import type { ProjectPreview } from "../../types/projectPreview";

    import LangSelector from "../LangSelector.svelte";
    
    import Loader from "../Loader.svelte";
    import { createEventDispatcher } from "svelte";
    import RecordTarget from "./RecordTarget.svelte";
    import { targetLanguages,recentTargetLanguages } from "../../stores/langs";
    import  * as db from "../../services/database"
    import { balanceStore, reduceExpectedBalance } from "../../stores/balance";
    import AddMoney from "../../dialogs/AddMoney.svelte";
    import { cacheAddedTarget } from "../../stores/projectslist";

    export let project: ProjectPreview;
    export let odd: boolean;

    const dispatch = createEventDispatcher();


    function handleCollapse() {
      dispatch("collapse")
    }

    $: durationDisplay = `${project.duration.toFixed(1)}s`

    $: rowspan = Math.max(project.targets.length+1,2)
    let showAddMoneyDialog = false
    $: notEnoughMoney = $balanceStore < Math.ceil(project.duration)

    $: targetLangs = $targetLanguages.filter(t => !project.targets.map(pt=>pt.langCode).includes(t.id)).map(item => ({id:item.id,name:item.displayName}))
    $: targetLangsRecent = $recentTargetLanguages.filter(t => !project.targets.map(pt=>pt.langCode).includes(t.id)).slice(0,5).map(item => ({id:item.id,name:item.displayName}))


    

  function handleChangeTarget(e: CustomEvent<any>): void {
    reduceExpectedBalance(Math.ceil(project.duration))

    const order = Math.max(...[...project.targets.map(t => t.order),-1]) + 1
    db.addTarget(project.id, e.detail, order)
    cacheAddedTarget(project.id, e.detail, order)
  }

  function handleAddMoney() {
      showAddMoneyDialog = true
    }


</script>

<AddMoney bind:show={showAddMoneyDialog}/>

<tr class:blue-background={odd} style="vertical-align: top; ">
  <td rowspan={rowspan} class="button-plus"><button class="svg-button round24-button" on:click={handleCollapse}><SvgMinus/></button></td>
  <td rowspan={rowspan}>{project.name}</td>
  <td rowspan={rowspan}>{durationDisplay}</td>
  <td rowspan={rowspan}>{project.created}</td>
  <td rowspan={rowspan}>{project.srcLang}</td>

  
  {#if project.targets.length > 0}
    <RecordTarget target={project.targets[0]} projectId={project.id}/>
  {:else}
    <!--coll-span-->
    <td colspan={4}></td>
  {/if}
</tr>

{#each project.targets.slice(1) as target}
  <tr class:blue-background={odd}>
    <RecordTarget target={target} projectId={project.id}/>
  </tr>
{/each}

<tr class:blue-background={odd}>
    <td colspan={4} class="blue">
      <LangSelector 
        title="Add language" 
        customEvent = {notEnoughMoney}
        items={targetLangs} 
        recentItems={targetLangsRecent}
        on:select={handleChangeTarget}
        on:open={handleAddMoney}
        price = {Math.ceil(project.duration/60.0)}
        on:popup_open
        on:popup_close
        likeLink/>
    </td>
</tr>

<style>
  .blue-background {
    background-color: #E8F1FB;
  }

  .button-plus {
    padding-left: 17px;
    padding-top: 5px;
  }

  td {
    padding: 5px 0;
  }
</style>
