<script lang="ts">
    import {videoFileUrl,videoFile,videoEl,play,pause,currentPos, isPlaying,videoDuration, seek, cleanedAudioUrl,volume} from "../stores/player";
    import Slider from "./Slider.svelte";
  
    import SvgMinimize from "../assets/minimize.svg";
    import SvgMaximize from "../assets/maximize.svg";
    import SvgPlayBig from "../assets/play_big.svg";
    import SvgPlay from "../assets/play.svg";
    import SvgPauseBig from "../assets/pause_big.svg"; 
    import SvgPause from "../assets/pause.svg";  
    import { project } from "../stores/project";
  import PreviewWarning from "./PreviewWarning.svelte";


    let big = false;

    //$: console.log("!!!videoFile:",$videoFile)
    //$: console.log("dbProjectStore",$dbProjectStore)

    $: $videoFileUrl = $project.videoUrl
    $: $cleanedAudioUrl = $project.cleanedAudioUrl

    $: $videoEl?.addEventListener("error", function (e) {
        console.log("videoFile error",e)
    });
    //crossorigin="anonymous"
    $: vertical = $project.height > $project.width


  function handleError(event: Event & { currentTarget: EventTarget & Element; }) {
    console.log("videoFile error",event)
  }

  function toggleBig() {
    big = !big;
  }

  function handleKeydown(event: KeyboardEvent) {
    if (event.key === " " && event.ctrlKey) {
      event.preventDefault();
      if ($isPlaying) {
        pause();
      } else {
        play();
      }
    }
  }

  function formatTime(time: number) {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes.toString().padStart(2, "0")}:${seconds.toFixed(2).padStart(5, "0")}`;
  }



  function handlePosChange(e: CustomEvent<{value:number}>): void {
    console.log("handlePosChange",e.detail.value)
    seek(e.detail.value)
  }
</script>


<svelte:window on:keydown={handleKeydown} />

<div class="player-container" class:vertical={vertical}>
    
  {#if !vertical}
    <div class="axis-x" style="margin: 5px">
      {#if big}
        <button class="svg-button svg-text-button small-text" on:click={toggleBig}><SvgMinimize/><span>&nbsp;</span>Minimize</button>
      {:else}
        <button class="svg-button svg-text-button small-text" on:click={toggleBig}><SvgMaximize/><span>&nbsp;</span>Maximize</button>
      {/if}
    </div>
  {/if}

   
    {#if $videoFile}
      <div class="video-mask">
        {#key $videoFile}
        <!-- svelte-ignore a11y-media-has-caption -->
        {#if vertical}
          <video bind:this={$videoEl} crossorigin="anonymous" height={"240px"} on:error={handleError} on:contextmenu|preventDefault>
            Your browser does not support the video tag.
          </video>
        {:else}
          <video bind:this={$videoEl} crossorigin="anonymous" width={big?"720px":"320px"} on:error={handleError} on:contextmenu|preventDefault>
            Your browser does not support the video tag.
          </video>
        {/if}
        {/key}
      </div>
    {/if}

    <Slider width="100%" thumbSize="14px" height="8px" color="var(--main-blue)" min={0} max={$videoDuration} step={0.1} value={$currentPos} stepAlign={false} on:change={handlePosChange}/>
    <div class="axis-x" style={big ? "justify-content: space-between; align-items: center; padding-top:12px;" : "justify-content: space-between; align-items: center; padding-top:8px;"}>

    {#if $isPlaying }
      {#if big}
        <button class="svg-button squre26-button" on:click={() => pause()}><SvgPauseBig/></button>
      {:else}
        <button class="svg-button squre20-button" on:click={() => pause()}><SvgPause/></button>
      {/if}

      {:else}
        {#if big}
          <button class="svg-button squre26-button" on:click={() => play()}><SvgPlayBig/></button>
        {:else}
          <button class="svg-button squre20-button" on:click={() => play()}><SvgPlay/></button>
        {/if}
    {/if}
       
      <!--Speed
      <div class="axis-y" style="margin: 12px 0 0 29px;">
        <div style="font-size: 16px; display: block; margin: 0 auto">1x</div>
        <div style="font-size: 11px; position: relative; top: -7px;">speed</div>
      </div>
      -->
      
      <div class={big ? "current-pos-max" : "current-pos-min"}> 
        {formatTime($currentPos)}
      </div>
      
     <div class="axis-x" style="width:20%; align-items:center;"> 
        {#if big}
          <div style="font-size: 14px; margin-right: 4px;">volume</div>
          <Slider bind:value={$volume} min={0} max={2} step={0.01}/>
        {:else}
          <Slider bind:value={$volume} min={0} max={2} step={0.01} width="100%"/> 
        {/if}
      </div>

    </div>
    {#if !vertical}
      <PreviewWarning/>
    {/if}
    
</div>

<style>
.player-container {
  position: relative;
  margin: 0 32px 0 16px;
}
.video-mask{
    border-radius: 14px; 
    overflow: hidden; 
    height: fit-content;
    width: fit-content;
}

.current-pos-max {
  font-size: 36px; 
  position:absolute; 
  left:50%; 
  transform:translateX(-50%); 
}

.current-pos-min {
  font-size: 20px; 
  position:absolute;  
  left:50%; 
  transform:translateX(-50%);   
}

</style>