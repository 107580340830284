<script lang="ts">
    import Portal from "svelte-portal/src/Portal.svelte";

     // @ts-ignore
     import SvgClose from "../assets/close.svg";

    
    export let show: boolean = true;
    export let title: string = "Dialog";

    function handleClose() {
        show = false;
    }


  function handleKeyDown(event: KeyboardEvent & { currentTarget: EventTarget & Window; }) {
    if (event.key === "Escape") {
      handleClose();
      event.preventDefault();
      event.stopPropagation();
    }
  }
 
</script>


<svelte:window on:keydown={handleKeyDown} />

<!-- svelte-ignore a11y-click-events-have-key-events -->
<Portal target="body">
    {#if show}
        <div class = "dialog-modal"> 
            <div class="dialog-backgorund" on:click={handleClose}></div>
            <div class="dialog-content">
                <div class="axis-x" style="padding-bottom:32px">
                    <h1>{title}</h1> 
                    <button on:click={handleClose} class="svg-button squre26-button" style="margin-right:-20px"><SvgClose/></button>
                </div>
        
                <slot></slot>
            </div>
        </div>
    {/if}
</Portal>

<style>
    .dialog-modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1000;
    }
    .dialog-backgorund {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
    }
    .dialog-content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: white;
        border-radius: 4px;
        padding: 16px 32px 32px;
        box-shadow: 2px 2px 6px 2px rgba(0, 0, 0, 0.15);
    }

</style>