<script lang="ts">
    import { Route  } from "svelte-navigator";

    import Redirect from "./Redirect.svelte";

    export let path:string;
    export let to:string;
</script>

<Route {path}>
    <Redirect {to}/>
</Route>
