<script lang="ts">
  import { onMount, setContext } from "svelte";
  import {calcRatioOnScreen, calcThumbWidth, createTimelineStore,timelineStoreSymbol,durationToScreen,timesampToScreen} from "./store";
  import Portal from "svelte-portal/src/Portal.svelte";
  import Marker from "./MarkerImpl.svelte";
  import type {MarkerInfo} from "./store";

    //TODO: zoom limitation ???
    //TODO: ruller zoom
    //TODO stripe move limits
    //TODO: readonly mode
    //TODO: click event
    //TODO: popups
    //TODO: overlaps
    //TODO: zoom after resize


    export let duration:number;
    export let markers:MarkerInfo[] = [];

    const store = createTimelineStore(duration);

    setContext(timelineStoreSymbol, store);

    let width:number;
    let timelineEl: HTMLDivElement;
    let rect:DOMRect = new DOMRect(0,0,0,0);

    $: store.setDuration(duration);
    $: store.setWidth(width);

    function handleWheel(e:WheelEvent) {
        if(e.ctrlKey) {
            if(e.deltaY > 0 ) store.zoomOut(e.x - rect.left,1.01);
            if(e.deltaY < 0 ) store.zoomIn(e.x - rect.left,1.01);
        } else {
            if(Math.abs(e.deltaY) > Math.abs(e.deltaX))
                store.scrollDelta(e.deltaY);
            else
                store.scrollDelta(e.deltaX);
        }
        e.preventDefault()
        e.stopPropagation()
    }

    let parentScroll = 0

    function addPlayerObserver(ro:ResizeObserver) {
        const player = document.querySelector(".player")
        if(player) {
            ro.observe(player);
            console.log("Added observer to player")
        } else {
            setTimeout(() => addPlayerObserver(ro),100)
        }

    }

    onMount(() => {
        function handleScroll(e) {
            console.log(e)
        }
        const ro = new ResizeObserver(entries => {
            rect = timelineEl.getBoundingClientRect()
        })
        ro.observe(document.body);
        addPlayerObserver(ro)

        timelineEl.parentElement?.addEventListener("scroll",handleScroll)
        parentScroll = timelineEl.parentElement?.scrollTop
        return () => {
            ro.disconnect()
            timelineEl.parentElement?.removeEventListener("scroll",handleScroll)
        }
    })

    
    $: console.log("SCROLL", parentScroll)

    $: internalMarkers = Object.getOwnPropertySymbols($store.markers).map(key => $store.markers[key])
</script>

<Portal target="body">
    <div class="overlay" style="top:{rect.top + parentScroll}px; left:{rect.left}px; width:{rect.width}px; height:{rect.height}px; ">
        <slot name="overlay"></slot>
        <div class="markers-layer">
            {#each markers as marker}
                <Marker {...marker} />
            {/each}
            {#each internalMarkers as marker}
                <Marker {...marker} />
            {/each}

        </div>
    </div>
</Portal>

<div bind:this={timelineEl} class="timeline" draggable={false}>
    <div class="content" bind:clientWidth={width} on:wheel={handleWheel}>
        <slot></slot>
    </div>
</div>

<style>
    .timeline {
        position: relative;
        width: 100%;
        user-select: none;
    }
    .content {
        width: 100%;
        height: 100%;
        border-radius: 8px 8px 0 0;
        overflow: hidden;
    }
    .overlay {
        position: absolute;
        /*background-color: rgba(0,0,0,0.5);*/
        pointer-events: none;
        /*z-index: 0;*/
    }
    .markers-layer {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: calc(100% + 50px);
        margin-top: -50px;
        overflow: hidden;
        z-index: 1;
    }
</style>