<script lang="ts">
    import { createEventDispatcher } from 'svelte';
    import {splitBySearch} from './search'
    import SvgCloseSmall from "../../assets/close_extra_small.svg";
    
    export let tag: string
    export let search = ""
    export let withButton = false


    const dispatcher = createEventDispatcher()

    $:textSplitted = splitBySearch(tag,search)

    function clickTag() {
        dispatcher("addTag",{tag})
    }

    function clickButton() {
        dispatcher("delTag",{tag})
    }

</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<span class="tag" class:with-button={withButton} on:click|stopPropagation = {clickTag}>
    {#if withButton}
        <button class="tag-button" on:click={clickButton}><SvgCloseSmall/></button>
    {/if}<!--
    -->{textSplitted[0]}<span class="found-text">{textSplitted[1]}</span>{textSplitted[2]}
</span>

<style>
    .tag {
        display: inline-block;
        background-color: var(--disabled-gray);
        padding: 2px 10px 4px;
        border-radius: 15px;
        margin-left: 5px;
        cursor: pointer;
        font-weight: 600;
    }
    .tag:hover {
        background-color: var(--main-blue-op04);
    }
    .with-button {
        cursor: default;
    }
    .found-text {
        background-color: yellow;
    }

    button {
        color:black;
        background-color: transparent;
        border-radius: 0;
        padding: 0;
        display: inline-block;
    }

    :global(.tag-button:hover) {
        stroke: var(--hover-blue);
    }

    button:active {
        outline: none;
    }

    .tag:has(button:active)  {
        background-color: var(--hover-blue-op01)
    }

</style>