<script lang="ts">
    import Dialog from "../components/Dialog.svelte";
    export let show: boolean = true;
    export let texts: boolean
    export let voices: boolean
</script>

<Dialog bind:show={show} title="Warning ">
    {#if texts} 
        <div>
            Write translation for each block, or use autotranslation 
        </div>
    {/if}
    {#if voices} 
        <div>
            Define voice for every speaker
        </div>
    {/if}
</Dialog>