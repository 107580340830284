import { derived, get, readable, writable, type Readable, type Writable } from "svelte/store";
import type { Transcript, TranscriptRecord } from "../types/transcript";
import { currentProject } from "./currentProject";
import { dbTargetStore, dbVoicesStore } from "./db";
import { transcript } from './transcript';
import type { DbTypes } from 'common';
import { project } from "./project";
import * as fn from '../services/functions';
import * as db from '../services/database';
import type { Fragment } from "../types/fragment";
import { preCacheSoundUrl } from "./player";
import { currentUserId } from "./auth";
import { getSourceLanguageById, getTargetLanguageById } from "./langs";


// voiceover = {voiceoverid, [voiceoverRecord], voices, fragments, volume}
// voiceoverRecord = {id, speaker, transcriptText, transcriptStartTime, transcriptEndTime, autoTranslatedText, voiceoverText, startTime, duration}


export type TranscriptInfo = {
    text :string
    startTime :number
    endTime :number
}

export type VoiceoverRecord = {
    id :string
    speaker :string
    voiceoverText :string
    startTime :number
    ratio: number
}

export type VoiceoverFullRecord = {
    transcript?: TranscriptInfo
    translation?: string
    duration?: number 
    estimatedDuration: number
    soundReady:boolean
    voiceDefined:boolean
    generatingSound:boolean
} & VoiceoverRecord


function makeTranscriptInfo(transcript:TranscriptRecord) :TranscriptInfo {
    return {
        text : transcript.words.map(w => w.word).join(""),  
        startTime : transcript.words[0].startTime, 
        endTime : transcript.words[transcript.words.length - 1].endTime
    };
}

const savedTranscript = writable<TranscriptRecord[]|null>(null);

export function initVoiceover() {
    const {projectId, targetCode} = get(currentProject);
    const ts = get(transcript);
    //fn.voiceoverInit(projectId, targetCode);

    const lines = ts.records.map(t => t.words.map(w => w.word).join(' '))
    const fragments = ts.records.map(t => ({
        id: crypto.randomUUID(),
        cleaned: true,
        volume: 0,
        start: t.words[0].startTime,
        end: t.words[t.words.length-1].endTime,
    }))

    const initialRecords = ts.records.map((t,i) => ({ 
    id: t.id,
    speaker: t.speaker,
    text: lines[i],
    //translation: translations[i],
    start: t.words[0].startTime,
    end: t.words[t.words.length-1].endTime,
    //ratio: translations[i].length !==0 ? (t.words[t.words.length-1].endTime - t.words[0].startTime) / translations[i].length : 0
    ratio: 0
    }))
    
    const status = "translated"

    const voiceover = initialRecords.map(r => ({
        id: r.id,
        speaker: r.speaker,
        start: r.start,
        ratio: r.ratio,
        text: " ",
    }))

    db.updateTarget(projectId, targetCode, {
        status,
        speakers: ts.speakers ?? null,
        fragments,
        initialRecords,
        voiceover


    });
}



const generatingInProgress = writable<string[]>([]);

export const generatingAnySound = derived(generatingInProgress, $generatingInProgress => $generatingInProgress.length !== 0)

export const voices = derived([currentProject,dbVoicesStore], ([$currentProject,$dbVoicesStore]) => $dbVoicesStore.loading ? [] : (Object.values($dbVoicesStore.data) as DbTypes.Voice[] ).filter(v => v.langId === $currentProject.targetCode));

export const voiceoverReady = derived(project, ($project) => $project.loaded && $project?.targetDb?.status !== "created", false); 




const dbVoiceoverStore = dbTargetStore.sub(() => "voiceover");
const dbVoiceoverSound = dbTargetStore.sub(() => "voiceoverSound");

const dbSpeakers = dbTargetStore.sub(() => "speakers");

dbSpeakers.subscribe(v => console.log("dbSpeakers", v));


export const voiceoverInitial = derived([project], ([$project]) => {
    const voiceoverRecords = ($project.targetDb?.initialRecords ?? []).map(r => ({
        id: r.id,
        speaker: r.speaker,
        transcript: {
            text: r.text,
            startTime: r.start,
            endTime: r.end
        },
        voiceoverText: " ",
        startTime: r.start,
        translation: r.translation,
        duration: undefined as number|undefined,
        estimatedDuration: r.end-r.start,
        soundReady: false,
        voiceDefined: false,
        generatingSound: false,
        ratio:r.ratio,
    }) )

    return voiceoverRecords
},[] as VoiceoverFullRecord[]);

const dbVoiceDefinition = dbTargetStore.sub(() => "voiceDefinition");

const definedVoices = derived(dbVoiceDefinition, ($dbVoiceDefinition) => {
    if(!$dbVoiceDefinition.data) return {} as DbTypes.VoiceDefinitionSet;
    const db = $dbVoiceDefinition.data as DbTypes.VoiceDefinitionSet;
    return db
})


export const voiceover = derived([dbVoiceoverStore, voiceoverInitial,dbVoiceDefinition,generatingInProgress,dbVoiceoverSound,definedVoices,dbVoicesStore], ([$dbVoiceoverStore, $voiceoverInitial,$dbVoiceDefinition,$generatingInProgress,$dbVoiceoverSound,$definedVoices,$dbVoicesStore]) => {
    if(!$dbVoiceoverStore.data) return $voiceoverInitial;
    const res:VoiceoverFullRecord[] = [];

    //console.log(">>>>>>>>>VOICEOVER")
    //console.log($voiceoverInitial)
    //console.log($dbVoiceoverStore.data)
    for(const v of $dbVoiceoverStore.data) {
        const rec = v as DbTypes.VoiceoverRecord;
        const initRec = $voiceoverInitial.find(t => t.id === rec.id);

        const ratio = (!v.ratio || v.ratio === -1) ? 0.03 : v.ratio
        const textLenth = rec.text.trim().length || 0;
        const estimatedDuration = (textLenth === 0 ? initRec?.estimatedDuration : textLenth * ratio) ?? 0;
        //console.log("text",initRec?.transcript?.text?.trim())
        //console.log("textLenth", textLenth)
        //console.log("estimatedDuration", estimatedDuration)

        const voice = ($dbVoicesStore.data ?? {})[$definedVoices[rec.speaker]?.voice]
        const sound = $dbVoiceoverSound.data?.[rec.id] as DbTypes.VoiceoverSound | undefined;
        
        

        const soundReady = voice !== undefined && 
            sound !== undefined && 
            sound.provider === voice.provider &&
            JSON.stringify(sound.voiceData) === JSON.stringify(voice.voiceData) &&
            sound.text === rec.text 
        //voice

        const fullRec = {
            id: rec.id,
            speaker: rec.speaker,
            transcript: initRec?.transcript ,
            voiceoverText: rec.text ?? " ",
            startTime: rec.start,
            translation: initRec?.translation,
            duration: soundReady ? sound?.duration : undefined,
            estimatedDuration: estimatedDuration,
            soundReady,
            voiceDefined: $dbVoiceDefinition.data?.[rec.speaker] !== undefined,
            generatingSound: $generatingInProgress.find(id => id === rec.id) !== undefined,
            ratio: rec.ratio,
        }
        res.push(fullRec);
    }   
    return res;
});

export function addSpeakerInfo(speaker) {
    const {projectId,targetCode} = get(currentProject);
    db.setTargetSpeakerInfo(projectId,targetCode,speaker.name,speaker.gender)

}

export function updateSpeakerInfo(oldSpeaker,newSpeaker) {
    const {projectId,targetCode} = get(currentProject);
    if(oldSpeaker.name !== newSpeaker.name) {
        db.deleteTargetSpeakerInfo(projectId,targetCode,oldSpeaker.name)
    }
    db.setTargetSpeakerInfo(projectId,targetCode,newSpeaker.name,newSpeaker.gender)
}


export const speakers = derived([voiceover,dbSpeakers], ([$voiceover,$dbSpeakers]) => $voiceover.map(t => t.speaker).filter((v,i,a) => a.indexOf(v) === i).map(s => ({name:s, gender:$dbSpeakers.data?.[s]?.gender ?? "unknown" })));


export const speakerVoices = derived([speakers, definedVoices], ([$speakers, $definedVoices]) => {
    return $speakers.map(s => ({speaker: s.name, voice: $definedVoices[s.name]?.voice ?? "", gender: s.gender ?? "unknown" }));
});

export function defineSpeakerVoice(speaker:string, voice:string) {
    //definedVoices.update(v => ({...v, [speaker]: voice}));
    const {projectId, targetCode} = get(currentProject);
    db.defineSpeakerVoice(projectId, targetCode, speaker, voice);
}


export function saveVoiceoverText(voiceover : VoiceoverRecord[]) {
    console.log('saveVoiceoverText')
    console.trace()
    console.log(voiceover)
    const records = voiceover.map(v => ({
        id: v.id,
        speaker: v.speaker,
        text: v.voiceoverText,
        start: v.startTime,
        ratio: v.ratio ?? -1,
    }));
    console.log(records)
    dbVoiceoverStore.set(records);  
}


//const voiceoverStore = derived([dbVoiceoverStore, voiceover], ([$dbVoiceoverStore, $voiceover]) => {

const dbFragments = dbTargetStore.sub(() => "fragments");



function createFragmentsStore() {
    const store = derived([dbFragments], ([$dbFragments]) => {
        if(!$dbFragments.data) return [] as Fragment[];
        const db = $dbFragments.data as DbTypes.Fragment[];
        return db.map(f => ({
            id: f.id,
            start: f.start,
            duration: f.end - f.start,
            cleaned: f.cleaned,
            volume: f.volume
        }));
    });

    function set(fragments:Fragment[]) {
        const _fragments = fragments?.map(f => ({
            id: f.id,
            start: f.start,
            end: f.start + f.duration,
            cleaned: f.cleaned,
            volume: f.volume
        })) ?? []; 
    
        const {projectId, targetCode} = get(currentProject);
        db.saveFragments(projectId, targetCode, _fragments);
    }

    return {
        subscribe: store.subscribe,
        set
    }
}

export const fragments = createFragmentsStore();

const voiceoverSoundsGeneratedUrls = writable({} as {[recordId:string]:{url:string}});
const voiceoverSoundsUrls = derived([project,voiceoverSoundsGeneratedUrls], ([$project,$voiceoverSoundsGeneratedUrls]) => ({...($project.voiceoverSounds ?? {}), ...$voiceoverSoundsGeneratedUrls }));
voiceoverSoundsUrls.subscribe(v => console.log("voiceoverSoundsUrls", v));

const downloadedVoiceoverSounds = writable(new WeakMap<{url:string},ArrayBuffer>());

function calcRatio(recordId:string, duration:number) {
    const record = get(voiceover).find(r => r.id === recordId)
    if(!record) return 0.03 
    const textLenth = record.voiceoverText.trim().length
    return duration / textLenth
}

export async function generateSound(recordId:string) {
    const {projectId, targetCode} = get(currentProject);
    const voiceoverRecordIndex = get(voiceover).findIndex(r => r.id === recordId)
    if(voiceoverRecordIndex === -1) return

    generatingInProgress.update(v => [...v, recordId]);
    const res = await fn.generateVoiceoverSound(projectId, targetCode, recordId);
    console.log("generateSound", res);
    const ratio = calcRatio(recordId, res.duration)
    db.setVoiceoverRecordRation(projectId, targetCode, voiceoverRecordIndex, ratio )

    generatingInProgress.update(v => v.filter(id => id !== recordId));
    voiceoverSoundsGeneratedUrls.update(v => ({...v, [recordId]: {url: res.url}}));
    preCacheSoundUrl(res.url);
}

export const voiceoverRecordsReady = derived(voiceover,$voiceover => $voiceover.every(v => v.soundReady));

export function getSoundUrl(recordId:string) {
    return get(voiceoverSoundsUrls)[recordId]?.url;
}

export async function downloadSound(recordId:string) {
    const urlObj = get(voiceoverSoundsUrls)[recordId];
    if(!urlObj) return;
    const cached = get(downloadedVoiceoverSounds).get(urlObj);
    if(cached) return cached;
    const res = await fetch(urlObj.url);
    const buffer = await res.arrayBuffer();
    downloadedVoiceoverSounds.update(v => v.set(urlObj, buffer));
    return buffer;
}

export const allRecordsHaveText = derived(voiceover, $voiceover => $voiceover.every(r => r.voiceoverText.trim() !== "")) 
export const allVoicesDefined = derived(voiceover, $voiceover => $voiceover.every(r => r.voiceDefined)) 

export function generateAllSounds() {
    const _voiceover = get(voiceover);
    const idsToGenerate = _voiceover.filter(v => !v.soundReady)
                                    .filter(v => v.voiceDefined)
                                    .filter(v => v.voiceoverText.trim() !== "")
                                    .map(v => v.id);

    return Promise.all(idsToGenerate.map(id => generateSound(id)));
}

export const voiceoverSounds = derived([voiceover,voiceoverSoundsUrls], ([$voiceover,$voiceoverSoundsUrls]) => $voiceover.map(v => ({
    id: v.id,
    url: v.soundReady ? $voiceoverSoundsUrls[v.id]?.url : undefined,
    start: v.startTime,
    duration: v.duration,
})));

export function moveVoiceoverRecord(recordId:string, delta:number) {
    const v = get(voiceover);
    const recordIndex = v.findIndex(r => r.id === recordId);
    if(recordIndex === -1) return;
    const {projectId, targetCode} = get(currentProject);
    const time = v[recordIndex].startTime + delta;

    db.moveVoiceoverRecord(projectId, targetCode, recordIndex, time);
}

function formatDateYYMMDDHHMM(d:Date) {
    const YY = String(d.getFullYear() % 100).padStart(2, '0')
    const MM = String(d.getMonth() + 1).padStart(2, '0')
    const DD = String(d.getDate()).padStart(2, '0')

    const hh = String(d.getHours()).padStart(2, '0')
    const mm = String(d.getMinutes()).padStart(2, '0')

    return `${YY}${MM}${DD}-${hh}${mm}`
}

export async function generateVideo(format:string) {
    const userId = currentUserId()
    const {projectId, targetCode} = get(currentProject);
    const {name} = get(project)
    const resultsId = crypto.randomUUID();
    await db.setTargetPreviewProcessing(userId,projectId, targetCode);
    const fileName = `${formatDateYYMMDDHHMM(new Date())}_${targetCode}_${name}.${format}`
    console.log(fileName)
    await db.setTargetProcessing(projectId, targetCode,resultsId, fileName)
    await fn.generateVideo(projectId, targetCode, resultsId, fileName, format);
}

export async function rewriteLonger(recordId:string) {
    const {projectId, targetCode} = get(currentProject);
    const record = get(voiceover).find(r => r.id === recordId)
    if(!record)
        return ""
    const res = await fn.rewriteRecord("longer",targetCode,record.voiceoverText)
    console.log("REWRITE",res.text)
    return res.text
}

export async function rewriteShorter(recordId:string) {
    const {projectId, targetCode} = get(currentProject);
    const record = get(voiceover).find(r => r.id === recordId)
    if(!record)
        return ""
    const res = await fn.rewriteRecord("shorter",targetCode,record.voiceoverText)
    return res.text
    
}

export async function translateRecord(recordId:string) {
    const {targetCode} = get(currentProject);
    const prj = get(project)
    const record = get(voiceover).find(r => r.id === recordId)
    if(!record)
        return ""

    const _srcLang = getSourceLanguageById(prj.sourceLanguage)
    const sourceLanguage = _srcLang ? `${_srcLang.displayName} [${prj.sourceLanguage}]` : prj.sourceLanguage

    const _tgtLang = getTargetLanguageById(targetCode)
    const targetLanguage = _tgtLang ? `${_tgtLang.displayName} [${targetCode}]` : targetCode

    const _speakers = get(speakers)
    const speakerGender = _speakers.find(s => s.name === record.speaker)?.gender
    const res = record.transcript ? await fn.translateRecord(record.transcript.text, sourceLanguage, targetLanguage, speakerGender ) : {text:""}
    return res.text
}

export const hoverRecordId = writable<string | null>(null); //>to index
export const activeRecordId = writable<string | null>(null); //>to index


export const overlaps = derived(([voiceover]),([$voiceover]) => {
    const res = []

    for(const rec1 of $voiceover) {
        for(const rec2 of $voiceover) {
            if(rec1 === rec2)
                continue
            if(typeof(rec1.duration) === "undefined")
                continue
            if(rec1.startTime > rec2.startTime)
                continue
            if(rec1.startTime + rec1.duration < rec2.startTime)
                continue

            res.push({
                rec1,
                rec2,
                start: rec2.startTime,
                end: rec1.startTime + rec1.duration
            })
            

        }
    }

    return res
})

