<script lang="ts">
    import Placeholder from "../Placeholder.svelte";
</script>

<div class="project-layout">
    <div class="title"> 
        <slot name="title">
            <Placeholder text="TITLE" color="skyblue"/>
        </slot>
    </div>
    <div class="editor">
        <slot name="editor">
            <Placeholder text="EDITOR" color="azure"/>
        </slot>
    </div>
    <div class="player"> 
        <slot name="player">
            <Placeholder text="PLAYER" color="thistle" width="500px"/>
        </slot>
    </div>
    <div class="timeline"> 
        <slot name="timeline">
            <Placeholder text="TIMELINE" color="wheat"/>
        </slot>
    </div>
</div>

<style>
.project-layout {
    display: grid;
    grid-template-columns: 1fr 0fr;
    grid-template-rows: 0fr 1fr 0fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    height: 100%;
}

    .title { grid-area: 1 / 1 / 2 / 3; }
    .editor { 
        grid-area: 2 / 1 / 3 / 2; 
        overflow: auto;
    }
    .player { grid-area: 2 / 2 / 3 / 3; }
    .timeline { grid-area: 3 / 1 / 4 / 3; }


</style>