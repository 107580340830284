//import type { Action } from "svelte/action";

export type PanMoveEvent = CustomEvent<{x: number, y: number, dx: number, dy: number, id?: any}>;
export type PanStartEvent = CustomEvent<{x: number, y: number, id?: any}>;
export type PanEndEvent = CustomEvent<{x: number, y: number, id?: any}>;

export function pannable(node:HTMLElement,id?:any) {
	let x:number;
	let y:number;

	function handleMousedown(event:MouseEvent) {
		x = event.clientX;
		y = event.clientY;

		node.dispatchEvent(new CustomEvent('panstart', {
			detail: { x, y, id }
		}));

		window.addEventListener('mousemove', handleMousemove);
		window.addEventListener('mouseup', handleMouseup);

        //?????
        event.stopPropagation();
	}

	function handleMousemove(event:MouseEvent) {
		const dx = event.clientX - x;
		const dy = event.clientY - y;
		x = event.clientX;
		y = event.clientY;

		node.dispatchEvent(new CustomEvent('panmove', {
			detail: { x, y, dx, dy, id }
		}));
	}

	function handleMouseup(event:MouseEvent) {
		x = event.clientX;
		y = event.clientY;

		node.dispatchEvent(new CustomEvent('panend', {
			detail: { x, y, id }
		}));

		window.removeEventListener('mousemove', handleMousemove);
		window.removeEventListener('mouseup', handleMouseup);
	}

	node.addEventListener('mousedown', handleMousedown);

	return {
		destroy() {
			node.removeEventListener('mousedown', handleMousedown);
		}
	};
}