<script lang="ts">
    const SHOW_CREATE_ONE_CLICK = false
    import AppLayout from "../../layouts/App.svelte"

    import { Router, Link, Route,navigate } from "svelte-navigator";
    import { onMount } from "svelte";
    import { loadingStore } from "../../stores/loading";
    import {status,reset,uploadFile, projectName,targets,uploadingStatus,fileInfo,sourceLanguage,createProject,customDictionary,/*isStatusPending,statusConfirm,*/ urlDownload} from "../../stores/createProject";
    import Dropzone from "./Dropzone.svelte";
    import { dbSourceLanguagesStore, dbTargetLanguagesStore } from "../../stores/db";
    import Progress from "../../components/Progress.svelte";
    import LangSelector from "../../components/LangSelector.svelte";
    import DeleteButton from "../../components/DeleteButton.svelte";


    import { sourceLanguages, sourceLanguagesLoaded, targetLanguages, targetLanguagesLoaded, 
        getSourceLanguageById,getTargetLanguageById,recentSourceLanguages,
        recentTargetLanguages
        } from "../../stores/langs";

    // @ts-ignore
    import SvgCloseSmall from "../../assets/close_small.svg"
    import AddMoney from "../../dialogs/AddMoney.svelte";
  import { balanceStore } from "../../stores/balance";
  import { derived } from "svelte/store";


        reset()

        let errorMessage = "";

        let isAdvancedEnabled = false
        let showAddMoneyDialog = false

        $:targetCost = Math.ceil( $fileInfo?.duration ?? 0)
        $:summaryCost = $targets.length * targetCost

        $: notEnoughMoney = $balanceStore - summaryCost < Math.ceil( $fileInfo?.duration ?? 0)

    $: displaySourceLanguage = $sourceLanguage ? getSourceLanguageById($sourceLanguage).displayName : "Select Source Language"
    function handleChangeSource(e) {
        $sourceLanguage = e.detail
    }

    const loading = derived([targetLanguagesLoaded, sourceLanguagesLoaded], 
        ([$targetLanguagesLoaded, $sourceLanguagesLoaded]) => !$sourceLanguagesLoaded || !$targetLanguagesLoaded)

        loadingStore.add(loading)

        

        $: canCreate = $projectName.length > 0 && $targets.length > 0 && $sourceLanguage && $fileInfo.audioExists
        $: canSelectLanguage = $status === "READY_TO_CREATE"


    $: targetLangs = $targetLanguages.filter(t => !$targets.includes(t.id)).map(item => ({id:item.id,name:item.displayName}))

    $: targetLangsRecent = $recentTargetLanguages.filter(t => !$targets.includes(t.id)).slice(0,5).map(item => ({id:item.id,name:item.displayName}))

    
    

  function handleFilesSelect(e) {
    const { acceptedFiles, fileRejections } = e.detail;

    if(fileRejections.length > 0) {
        errorMessage = "File is too big";
        return;
    } 

    errorMessage = "";
    uploadFile(acceptedFiles[0]);
  }

  function handleStopUpload() {

  }


  function addTargetLanguage(langId:string) {
        targets.add(langId)
  }

  function removeTargetLanguage(langId:string) {
        targets.remove(langId)
  }



  function handleChangeTarget(e) {
    console.log(e)
    targets.add(e.detail)
    //selectedTarget = ""
  }

  //navigate
  function handleCreate() {
    createProject(false)
    navigate("/projects")
  }

  //navigate
  function handleCreateOneClick() {
    createProject(true)
    navigate("/projects")
  }

    function handleAddMoney() {
        showAddMoneyDialog = true
    }

 

    let projectNameEl:HTMLInputElement
    $: projectNameEl?.focus()

    let fileUrl: string = ""
    async function handleUrlDownload() {
        try{
            await urlDownload(fileUrl)
        } catch(e) {
            errorMessage = e.message
            $status = "SELECTING_FILE"
        }  
    }


    function handleUrlKeyDown(event: KeyboardEvent & { currentTarget: EventTarget & HTMLInputElement; }) {
        if (event.key === "Enter" && fileUrl.trim().length > 0) {
            handleUrlDownload()
        }
    }
</script>

<AddMoney bind:show={showAddMoneyDialog}/>


<AppLayout>
    <div class="axis-x caption">
        <h1>Create new project</h1>
        <button on:click={handleCreate} disabled={!canCreate}>Create</button>
        {#if SHOW_CREATE_ONE_CLICK}
            <button on:click={handleCreateOneClick} disabled={!canCreate}>Create one click</button>
        {/if}
    </div>
    <div class="content-data">

    <!--
    {#if !import.meta.env.PROD}
    Status: {$status} <button disabled={!$isStatusPending} on:click={statusConfirm}>Next</button> <br/>
    {/if}
    -->    
    {#if $status === "SELECTING_FILE"}
        <!--Upload-->

        {#if errorMessage}
            <p style="color: red;">{errorMessage}</p>
        {/if}
        <Dropzone on:drop={handleFilesSelect}/>

        <div class="block-form">
            <div>
                Drag and drop your video to the uploading area or click it to open the upload menu or click Download via URL to download video from the supported sites.
            </div>
            <input bind:value={fileUrl} on:keydown={handleUrlKeyDown} placeholder="Enter video URL to download video directly from one of the supported sites"/>
            <button on:click={handleUrlDownload} disabled={fileUrl.trim().length === 0}>Download</button>
        </div>
        
        
    {:else if $status === "WAITING"}    
        <Progress/>
    {:else}

        <!--ProjectName-->
        <div class="action">Project name</div>
        <input class="name-input" bind:value={$projectName} bind:this={projectNameEl}/><br/>
        
        
        {#if $status === "UPLOADING_FILE"}
            {#if $uploadingStatus}
                <!--UploadingStatus-->
                <div class="status-information">
                    <Progress progress={$uploadingStatus.progress}/>
                    <div class="action file-status axis-x">
                        <span style="margin-right: 12px;">
                            Uploading {($uploadingStatus.bytesTransferred/1000000).toFixed(1)} MB from {($uploadingStatus.totalBytes/1000000).toFixed(1)}MB ({$uploadingStatus.progress.toFixed(2)}%)  
                        </span>
                        <button on:click={handleStopUpload} class="svg-button squre20-button"><SvgCloseSmall/></button>
                    </div>
                </div>
            {/if}
        {:else if $status === "ANALYZING_FILE"}
        
        <!--AnalyzingStatus-->
        <div class="status-information">
            <Progress/>
            <div class="action file-status">Analyzing...</div>
        </div>
            
        {:else}
            {#if $fileInfo}
                <!--FileInfo-->
                <div class="status-information">
                    <div class="action file-status">File info: {$fileInfo.fileName} {$fileInfo.width}x{$fileInfo.height} {$fileInfo.duration}s</div>    
                    {#if !$fileInfo.audioExists}
                        <div class="action file-status" style="color: red;">Audio stream doesn`t exist</div>
                    {/if}
                </div>
            {/if}
        {/if}
        
        <div class="axis-x" style="margin-top: 20px;">
            <!--SourceLang-->
            <div>
                <div class="action">Source language</div>
                <LangSelector 
                    disabled = {!canSelectLanguage}
                    title={displaySourceLanguage} 
                    items={$sourceLanguages.map(item => ({id:item.id,name:item.displayName})) }
                    recentItems={$recentSourceLanguages.slice(0,5).map(item => ({id:item.id,name:item.displayName}))}
                    on:select={handleChangeSource}
                />
            </div>
            <!--Target-->
            <div style="margin-left: 20px;">
                <div class="action">Target language</div>
                <div style="align-items: center; margin-bottom: 16px;">
                    <LangSelector 
                        disabled = {!canSelectLanguage}
                        customEvent = {notEnoughMoney}
                        title= {$targets.length ? "Add Target Language"  : "Select Target Language"}
                        items={targetLangs} 
                        recentItems={targetLangsRecent}
                        on:select={handleChangeTarget}
                        on:open={handleAddMoney}
                        price = {Math.ceil(targetCost/60)}
                    />
                </div>
            </div>
        </div>

        <!--Target languages-->        
            <div class="lang-list">
                {#each $targets as t, i}
                    <div class="lang-item" class:delimeter = {(i % 4 !== 3) && (i < $targets.length - 1 || $targets.length >= 4) }>
                        <div class="lang">{getTargetLanguageById(t).displayName}</div>
                        <DeleteButton on:delete={()=>removeTargetLanguage(t)}/>
                    </div>
                {/each}
            </div>
        {#if canSelectLanguage && $targets.length > 0}
            <span>
                Summary cost - <span class="blue">{Math.ceil(summaryCost/60)}</span> mins <br/>
            </span>
        {/if}


        
        
        
        <!--AdvancedOption-->
        <!-- <div>
            <br/>
            <div class='check-group'>
                <input type="checkbox" class='custom-checkbox' bind:checked={isAdvancedEnabled} id="advanced" />
                <label for="advanced">advanced</label>
            </div>
            {#if isAdvancedEnabled} 
                <br/>
                Custom dictionary
                <br/>
                <textarea bind:value={$customDictionary}/>
            {/if}
        </div> -->
            
        {/if}
    </div>

</AppLayout>


<style>

 .action {
  font: 14px;
  font-weight: 600;
  margin: 5px 0;
  /* border: 1px solid transparent; */
 }

 .block-form>* {
    display: block;
    margin: 20px auto;
 }

 .name-input {
  width: 50%;
  min-width: 400px;
  height: 32px;
  border: 1px solid #605E5C;
  color: #605E5C;
  border-radius: 2px;
  /*margin: 0 0 8px 0;*/
  padding: 6px 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.status-information {
  height: 78px;
  width: 100%;
}
.file-status {
  margin-bottom: 16px;
}

.lang-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 902px;    
    margin-bottom: 20px;
}

.lang-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    margin-right: 7px;
}

.delimeter.lang-item::after {
    content: "";
    display: inline-block;
    margin-left: 16px;
    width: 2px;
    height: 28px;
    background-color: var(--disabled-gray);;
}

.lang {
    width: 150px;
    margin-right: 30px;
    font-weight: 400;
    line-height: 1.43;
    margin-bottom: 8px;
}

.caption button:last-of-type {
    margin-left: 20px;
}

</style>