<script>
    import SvgLogo from '../assets/logo.svg';
</script>

<div class="auth-dialog">
    <SvgLogo/>
    <slot/>
    <div class="copyright">Copyright © DubWiz 2022</div>
</div>


<style>
  .auth-dialog>:global(svg) {
    display: block;
    margin: 0 auto; 
  }

.auth-dialog {
    width: 400px;
    height: max-content;
    background: #FFFFFF;
    border-radius: 4px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -287px 0px 0px -200px;
    padding: 32px;
    font-weight: 600;
  }

  .copyright {
    font-size: 12px;
    font-weight: 400;
    margin-top: 12px;
    color: var(--light-gray);
    opacity: 0.1;
    text-align: center;
  }
  
</style>