<script lang="ts">
     import AuthLayout from "../layouts/Auth.svelte"
     import { authStore } from "../stores/auth";
     import { Link, useNavigate, useLocation } from "svelte-navigator";
     import {firebaseErrors} from "../services/firebase_errors"

     const navigate = useNavigate()
     const location = useLocation()

     
     $: code = $location.state?.["oobCode"]
     $: console.log("CODE",code)

     let error = ""
     let password1:string = ""
     let password2:string = ""

     $: if(password1 != password2) 
            error = "Passwords are different" 
        else 
            error = ""
     
     async function resetPassword() {
        try{
            console.log(code,password1)
            await authStore.confirmResetPassword(code,password1)
            navigate( "/")
        } catch (e) {
            error = firebaseErrors[e.code] ?? e.message ?? "Internal error"
        }
     }

</script>

<AuthLayout>
    <div  class="account-caption">New Password</div>

    <input 
        type="password" 
        bind:value={password1} 
        class="account-input" 
        placeholder="Password"
    />
    <input 
        type="password" 
        bind:value={password2} 
        class="account-input" 
        placeholder="Password"
    />

    <br/>
    <span style="color:red;">{error}</span>
    <br/>
    
    <button type="button" on:click={resetPassword} class="full-button" disabled={password1 === "" || password1 !== password2 }>Reset Password</button>


</AuthLayout>

<style>
    .account-caption {
        font-size: 18px;
        margin-top: 12px;
        text-align: center;
    }

    .account-input {
        width: 336px;
        height: 40px;
        margin-top: 16px;
        border: 1px solid var(--light-gray);
        border-radius: 4px;
        padding: 9px 12px 7px 12px;
    }

    .full-button {
         width: 100%;
        display: inline-block;
        margin-bottom: 16px;
    }
</style>