<script lang="ts">
    import { getContext } from "svelte";
    import {timelineStoreSymbol,timesampToScreen,durationToScreen,screenToDuration} from "./store";
    import type {TimelineStore} from "./store";
    import { pannable } from "../utils/pannable";
    import { createEventDispatcher } from 'svelte';
    import Marker from "./Marker.svelte";
  import { clickOutside } from "../utils/clickOutside";


    export let data: any[]; //
    export let selection : string|null = null; //BIND
    export let readonly = false;

    const dispatch = createEventDispatcher();

    let move =0;

    const ctx = getContext<TimelineStore>(timelineStoreSymbol);


    function handlePanStart(event) {
        //console.log("handlePanStart",event)
        selection = event.detail.id;
        move = 0;
	}

	function handlePanMove(event) {
        //console.log("handlePanMove",event)
        move += event.detail.dx;
	}

	function handlePanEnd(event) {
        //console.log("handlePanEnd",event)
        dispatch("move",{cueId:event.detail.id, position:screenToDuration($ctx,move)})
        move = 0;
	}

    function handleClickOutside(event) {
        //console.log("handleClickOutside",event)
        selection = null;
    }


</script>

<div class="stripe" use:clickOutside on:mousedown={handleClickOutside} on:click_outside={handleClickOutside}>
    {#each data as cue, i}
        {#if readonly}
            <div class="cue" 
                use:pannable={cue.id} 
                on:panstart={handlePanStart}
                class:selected={selection === cue.id}
                style="left:{timesampToScreen($ctx,cue.start)+(selection === cue.id ? move : 0)}px; width:{durationToScreen($ctx,cue.duration)}px;">

                <slot cue={cue} index={i}/>

            </div>
        {:else}
            <div class="cue" 
                use:pannable={cue.id} 
                on:panstart={handlePanStart}
                on:panmove={handlePanMove}
                on:panend={handlePanEnd}
                class:selected={selection === cue.id}
                style="left:{timesampToScreen($ctx,cue.start)+(selection === cue.id ? move : 0)}px; width:{durationToScreen($ctx,cue.duration)}px;">

                <slot cue={cue} index={i}/>

            </div>
        {/if}
        {#if cue.id === selection && move !== 0}
            <Marker position={cue.start + screenToDuration($ctx,move)} width={0.5}/>
            <Marker position={cue.start + cue.duration + screenToDuration($ctx,move)} width={0.5}/>
        {/if}
    {/each} 


    <Marker position={0} width={2}/>
    <Marker position={$ctx.duration} width={2}/>
</div>

<style>
.stripe {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 40px;
    border-bottom: 1px solid #E0E0E0;
}
.cue {
    position: absolute;
    top: 0;
    height: 100%;
    
}

.selected {
   
}
</style>