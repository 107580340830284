<script lang="ts">
  import { voiceover } from "../../stores/voiceover";
  import {formatTime} from "../../utils/time"
  
  export let id: string;

    //export let transcript : TranscriptInfo
    $: transcript = $voiceover.find((v) => v.id === id)?.transcript
    $: startTime = transcript?.startTime ?? 0
    $: endTime = transcript?.endTime ?? 0
    $: duration = endTime - startTime

    $:startTimeStr = formatTime(startTime)
    $:endTimeStr = formatTime(endTime)
    $:durationStr = duration.toFixed(1)

</script>

{#if transcript}
  <div class="voiceover-record-transcript-header time">{startTimeStr} - {endTimeStr} ({durationStr} sec)</div>
{/if}


<style>
.time {
    font-weight: 600;
    font-size: 14px;
    padding-bottom: 6px;
    text-align: center;
}

</style>