<script lang="ts">

</script>

<!--
<button class="svg-button squre26-button" {...$$props} on:click>
    <slot/>
</button>

fill="#2897f1"
-->
<button  {...$$props} on:click>
    <slot/>
</button>

<style>
    button {
        background-color: transparent;
        outline: 0px !important;
        padding: 2px;
    }

    button:active {
        background-color: var(--main-blue-op01);
    }

    button:active :global(svg path),
    button:active :global(svg rect) {
        color: var(--hover-blue) !important;
    }

    button:disabled :global(svg path),
    button:disabled :global(svg rect) {
        color: var(--disabled-gray) !important;
    }

    button :global(svg path),
    button :global(svg rect) {
        color: var(--text-black);
    }

    button:hover :global(svg path),
    button:hover :global(svg rect) {
        color: var(--hover-blue);
    }

</style>