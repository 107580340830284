<script lang="ts">
  import {currentProject} from "../../stores/currentProject";
  import { saveVoiceoverText, voiceover, type VoiceoverRecord, hoverRecordId, activeRecordId } from "../../stores/voiceover";
  import { onMount } from "svelte";
  import { getEditor } from "../../lexer-editor/editorContext";
  import { FORCE_SAVE_COMMAND, initEditorState, registerTransformers, SET_TRANSLATION_COMMAND, getRecordElementById } from "./editor";
    import { voiceoverEditorStore } from "./store";
    import { readOnly  } from "../../stores/project";
    import * as fn from "../../services/functions"


    const editor = getEditor();

    $: editor.setEditable($voiceoverEditorStore.length === 0 && !$readOnly) 
    
    export function save() {
      editor.dispatchCommand(FORCE_SAVE_COMMAND,{})
    }

    export async function translateAll(engine:string) {
      console.log("translate all",engine)
      try{
        voiceoverEditorStore.add(document.body)
        const translation = await fn.translateAll($currentProject.projectId, $currentProject.targetCode,engine)
        console.log(translation)

        
        for(let t of translation.translation) {
          editor.dispatchCommand(SET_TRANSLATION_COMMAND,{recordId: t.id, translation: t.text })
        }
      } finally {
        voiceoverEditorStore.remove(document.body)
      }
    }

    function handleChange(voiceover : VoiceoverRecord[]) {
      console.log("handleChange")
      console.log(voiceover)
      console.log($voiceover)
      for(const [i,v] of voiceover.entries()) {
        const recIndex = $voiceover.findIndex((v2) => v2.id === v.id) 
        if(recIndex === -1) {
          if(i === 0) {
            v.startTime = 0  
          } else {
            /*
            const dur = $voiceover[i - 1].duration ?? $voiceover[i - 1].estimatedDuration;
            const ratio = voiceover[i - 1].voiceoverText.length !== 0 ? 
                          $voiceover[i - 1].voiceoverText.length / (voiceover[i - 1].voiceoverText.length) : 0;
            */
            
            console.log("SPLIT")
            console.log($voiceover[i - 1])
            console.log(v)
            v.startTime = $voiceover[i - 1].startTime + ($voiceover[i - 1].voiceoverText.length - v.voiceoverText.length) * $voiceover[i - 1].ratio
          }
        } else {
          v.startTime = $voiceover[recIndex].startTime
          v.ratio = $voiceover[recIndex].ratio
        }
      }
      saveVoiceoverText(voiceover)
    }

 //----------------- hover ----------------- 
 function handleMouseRecordEnter(recordId:string) {
    $hoverRecordId = recordId
  }

  function handleMouseRecordLeave(recordId:string) {
    $hoverRecordId = null
  }

  let prevHoverRecordEl = null
  $: {
    const hoverEl = getRecordElementById(editor,$hoverRecordId)
    if (prevHoverRecordEl) {
      prevHoverRecordEl.classList.remove("hover")
    }
    if (hoverEl) {
      hoverEl.classList.add("hover")
    }
    prevHoverRecordEl = hoverEl
  }
  //----------------- active ----------------- 
  function handleSelectionChange(recordId:string) {
    if($activeRecordId !== recordId) {
      $activeRecordId = recordId
    }
  }

  let prevActiveRecordEl = null
  $: {
    const activeRecordEl = getRecordElementById(editor,$activeRecordId)
    if (prevActiveRecordEl) {
      prevActiveRecordEl.classList.remove("active")
    }
    if (activeRecordEl) {
      activeRecordEl.classList.add("active")
    }
    prevActiveRecordEl = activeRecordEl
  }

    onMount(() => {
        console.log("VoiceoverPlugin onMount",$voiceover)
        initEditorState(editor,$voiceover)
        return registerTransformers(
            editor,
            handleChange,
            handleMouseRecordEnter,
            handleMouseRecordLeave,
            handleSelectionChange
        )
  })

</script>