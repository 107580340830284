<script lang="ts">
    import Loader from "../components/Loader.svelte";
    import SvgBigLogo from '../assets/big_logo0.svg';

    export let animate: boolean = false

</script>

<div class="container">
    {#if animate}
        <div class="loader">
            <Loader/>
        </div>
    {:else}
        <svg width={28} height={28} viewBox="0 0 28 28">
            <rect x="7" y="9" width="3" height="6" rx="1.5" fill="currentColor"/>       
            <rect x="13" y="7" width="3" height="10" rx="1.5" fill="currentColor"/>
            <rect x="19" y="9" width="3" height="6" rx="1.5" fill="currentColor"/>
        </svg>
    {/if}
    <SvgBigLogo  width={28} height={28} viewBox="0 0 221 213" color="#1976D2"/>
</div>

<style>
    .container {
        position: relative;
        width: 28px;
        height: 28px;
        zoom: 0.714;
    }
    .loader {
        position: absolute; 
        top: 2px; 
        left: 3px;
    }
    .container :global(svg) {
        position: absolute; 
        top: 0px; 
        left: 0px;
    }
</style>