<script lang="ts">
  import Stripe from "../../timeline/Stripe.svelte";
  import Timeline from "../../timeline/Timeline.svelte";
  import TimelineRuller from "../../timeline/TimelineRuller.svelte";
  import WaveformStripe from "../../timeline/WaveformStripe.svelte";
  import Scrollbar from "../../timeline/Scrollbar.svelte";
  import DebugInfo from "../../timeline/DebugInfo.svelte";
  import ButtonZoomIn from "../../timeline/ButtonZoomIn.svelte";
  import ButtonZoomOut from "../../timeline/ButtonZoomOut.svelte";
  import OverlapsOverlay from "../../timeline/OverlapsOverlay.svelte";

  import {project,readOnly} from "../../stores/project";
  import {currentPos} from "../../stores/player";
  
  // @ts-ignore
  import SvgZoomIn from "../../assets/bx_zoom-in.svg";
  // @ts-ignore
  import SvgZoomOut from "../../assets/bx_zoom-out.svg";
  import { voiceover, fragments, moveVoiceoverRecord, hoverRecordId, activeRecordId, overlaps } from "../../stores/voiceover";
  import Cue from "./Cue.svelte";
  import { seek, isPlaying } from "../../stores/player";
  import PositionMarker from "./PositionMarker.svelte";

  //$: positionMarker = {position: $currentPos, color: "red", width: 1, label: $currentPos.toFixed(2)};

  $: data = $voiceover.map(record => {
    return {
      id: record.id,
      speaker: record.speaker,
      start: record.startTime,
      duration: record.duration ?? record.estimatedDuration ?? 0,
      text: record.voiceoverText.trim().length > 0 ? record.voiceoverText : record.transcript?.text ?? "",
    }
  })

  function handleMoveCue(e: CustomEvent<{ cueId: string; position: number; }> ): void {
    moveVoiceoverRecord(e.detail.cueId, e.detail.position)
  }
  function handleRullerClick(e: CustomEvent<number>): void {
    seek(e.detail)
  }
</script>

<Timeline duration={$project.duration}>
    <PositionMarker position={$currentPos}/>
    <TimelineRuller showMarker={true} on:click={handleRullerClick}/>
    <Stripe data={data} on:move={handleMoveCue} let:cue let:index readonly={$isPlaying || $readOnly} bind:selection={$activeRecordId}>
      <Cue cue={cue} index={index} on:mouseenter={()=>$hoverRecordId=cue.id} on:mouseleave={()=>$hoverRecordId=null} hover={$hoverRecordId === cue.id}/>
    </Stripe> 
    <WaveformStripe waveformData={$project.originalWaveform} waveformCleanedData={$project.cleanedWaveform} bind:fragments={$fragments} readonly={$isPlaying || $readOnly}/>
    <svelte:fragment slot="overlay">
        <Scrollbar/>
        <div class="controls">
            <ButtonZoomIn className="svg-button round24-button"> <SvgZoomIn/> </ButtonZoomIn>
            <ButtonZoomOut className="svg-button round24-button"> <SvgZoomOut/> </ButtonZoomOut>
        </div>
        <OverlapsOverlay overlaps = {$overlaps}/>
        <!--<DebugInfo dy={-120}/>-->
    </svelte:fragment>
</Timeline>

<style>
  .controls {
    position: absolute;
    top: 0;
    left: 0;
    width: 40px;
    height: 100px;
    margin-left: -30px;
  }
  .test {
    width:100%;
    height:32px;
    border: 1px solid #605E5C;
    border-radius: 4px;
    box-sizing: border-box;
    margin: 4px -0 0 0;
}
</style>


