<script lang="ts">
    import { clickOutside } from "../utils/clickOutside";
    import { createEventDispatcher } from "svelte";

    const dispatch = createEventDispatcher();


    let popupOpen = false

    function openPopup() {
        if(popupOpen) return
        popupOpen = true
        dispatch("popup_open")
    }

    function closePopup() {
        if(!popupOpen) return
        popupOpen = false
        dispatch("popup_close")
    }

    function handleKeyDown(event: KeyboardEvent) {
        if (event.key === "Escape") {
            closePopup()
    
        }
    }
    
</script>
<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-no-noninteractive-tabindex -->
<div class="selector">
    <div tabindex=0 class="button" on:focus={openPopup} on:click={openPopup}>
        <slot/>
    </div>
    {#if popupOpen}
        <div class="popup" use:clickOutside on:click_outside={closePopup}>
            <slot name="popup" close={closePopup}/>
        </div>
    {/if}
    
</div>

<svelte:window on:keydown={handleKeyDown}/>


<style>
    .selector {
        position: relative;
        display: inline;
    }
    .popup {
        position: absolute;
        left: 0;
        top: 0;
        /*width: 100%;*/
        z-index: 20;
    }

    .button {
        display: inline-block;
    }



</style>