<script lang="ts">
    import Dialog from "../components/Dialog.svelte";
    import { speakerVoices } from "../stores/voiceover"; 
    import SelectVoiceDialog from "./SelectVoice/SelectVoice.svelte";
    import { voices } from "../stores/voiceover";


    export let show: boolean = true;



    let showSelectDialog: boolean = false; 
    let speaker = "";
    let gender = "unknown";
    function selectVoice(s:string,g:string) {
        showSelectDialog = true;
        speaker = s;
        gender = g;
    }

</script>
   
<!-- <Dialog bind:show={show} title="Define voices"> -->
<Dialog bind:show={show} title="Define voices">    
    <div class="define-voice">
        {#each $speakerVoices as speaker}
            {@const voice = $voices.find(v => v.id === speaker.voice)}
            <div class="speaker" on:click={() => selectVoice(speaker.speaker,speaker.gender)}>
                <div class="speaker-num">{speaker.speaker}</div> 
                <div>{voice?.name ?? "<not defined>"}</div>
            </div>
        {/each}
    </div>
</Dialog>

<SelectVoiceDialog bind:show={showSelectDialog} speaker={speaker} speakerGender={gender}/>

<style>
    .define-voice {
        width: 250px;
        /*   */  
        white-space: normal;
    }

    .speaker {
        border-radius: 4px;
        margin: -5px 0 0 -5px;
        padding: 5px 8px 8px 8px;
    }

    .speaker-num {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 4px;
        color: var(--main-blue);
    }

    .speaker:hover {
       cursor: pointer;
       background-color: var(--main-blue-op01);

    }

</style>
   