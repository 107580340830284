<script lang="ts">
    import { afterUpdate, onMount } from "svelte";
    import { getContext } from "svelte";
    import {timelineStoreSymbol,timesampToScreen,durationToScreen,screenToDuration} from "./store";
    import type {TimelineStore} from "./store";


    export let position:number 
    export let color="red"
    export let label:string = null
    //export let ystart: number|null = null;
    //export let yend: number|null = null;
    export let width = 1

    const ctx = getContext<TimelineStore>(timelineStoreSymbol);
    const key = Symbol("marker")

    onMount(() => {
        ctx.addorUpdateMarker(key,{
            position,
            color,
            label,
            width
        })

      
        return () => {
            ctx.removeMarker(key)
        }
    })

    afterUpdate(() => {
        ctx.addorUpdateMarker(key,{
            position,
            color,
            label,
            width
        })
    })

</script>