<script lang="ts">
    import { afterUpdate, createEventDispatcher, onDestroy, onMount } from "svelte";
    import { pannable, type PanEndEvent, type PanMoveEvent, type PanStartEvent } from "../utils/pannable";
    

    export let min=0
    export let max=100
    export let value=30
    export let step=0.1
    export let stepAlign = true

    export let width = "500px"
    export let height = "6px"
    export let thumbSize = "12px"
    export let color="var(--text-black)"

    const dispatch = createEventDispatcher()

    let sliderEl : HTMLDivElement
    let isMounted = false

    $: len = max - min
    $: percent = (value - min) / len

    function nomalizeValue(value: number) {
        let newVal = stepAlign ? Math.round(value / step) * step : value
        if (newVal < min) {
            newVal = min
        } else if (newVal > max) {
            newVal = max
        }
        return newVal
    }

    let clientWidth = 0

    let prevValue = -1
    let moveDelta = 0

    let drag = false

    function setValue(val: number) {
        const newValue = nomalizeValue(val)
        const needNotify = (Math.abs(newValue - value) > 0.01 * step) && isMounted
        if (value !== newValue) {
            value = newValue
        }
        if(needNotify){
            dispatch("change", { value })
        }
    }

	function handlePanStart(_event: PanStartEvent) {
        prevValue = value
        moveDelta = 0
        drag = true
    }

	function handlePanMove(event: PanMoveEvent) {
        moveDelta += event.detail.dx
        const percentMove = moveDelta / clientWidth
        setValue(prevValue + percentMove * len)
    }

	function handlePanEnd(_event: PanEndEvent) {
        drag = false
    }
    function handleMouseDown(event: MouseEvent) {
        const { clientX } = event
        const { left, width } = sliderEl.getBoundingClientRect()
        const percent = (clientX - left) / width
        setValue(min + percent * len)
    }

    function handleMouseWheel(event: WheelEvent) {
        const { deltaY } = event
        if (deltaY > 0) {
            setValue(value - step)
        } else if (deltaY < 0) {
            setValue(value + step)
        }
    }

    onMount(() => {
        isMounted = true
    })
    onDestroy(() => {
        isMounted = false
    })


    afterUpdate(() => {
        setValue(value)
    })

    $:sliderStyle = `width:${width}; --thumb-size:${thumbSize}; --track-height:${height}; --color:${color};`
</script>

<div class="slider" bind:clientWidth={clientWidth} on:mousedown={handleMouseDown} bind:this={sliderEl} on:wheel={handleMouseWheel} style={sliderStyle}>
    <div class="track-left" style="width: {percent*100}%;"/>
    <div class="track-right" style="width: {(1-percent)*100}%;" />
    <div 
        class="thumb" 
        class:drag={drag}
        use:pannable 
        on:panstart={handlePanStart}
	    on:panmove={handlePanMove}
	    on:panend={handlePanEnd}
        style="left: {percent*100}%;"
    />

</div>

<style>
    .slider {
        position: relative;
        /*background-color: red;*/
        /*width: 500px;*/
        height: var(--thumb-size);
    }

    .track-left {
        position: absolute;
        background-color: var(--color);
        width: 50%;
        height: var(--track-height);
        left: 0;
        margin-top: calc(var(--thumb-size) / 2 - var(--track-height) / 2);
        border-radius: 4px 0 0 4px ;
    }

    .track-right {
        position: absolute;
        background-color: var(--disabled-gray);
        right:0;
        width: 50%;
        height: var(--track-height);
        margin-top: calc(var(--thumb-size) / 2 - var(--track-height) / 2);
        border-radius: 0 4px 4px 0;
    }
    .thumb {
        position: absolute;
        background-color: var(--color);
        width: var(--thumb-size);
        height: var(--thumb-size);
        border-radius: 50%;
        
        /*left: 50%;*/

        transform: translateX(-50%);
    }

    .thumb:hover {
        outline: 1px solid var(--color);
        /*background-color: yellow;*/
    }
    .thumb.drag {
        outline: 1px solid var(--color);
        /*background-color: yellow;*/
    }

</style>