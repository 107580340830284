import { writable,get } from "svelte/store";
import { auth } from "../firebase"
import { FacebookAuthProvider, type User } from "firebase/auth"

import { createUserWithEmailAndPassword, signInWithEmailAndPassword ,signInWithPopup, GoogleAuthProvider, signOut, sendPasswordResetEmail, confirmPasswordReset } from "firebase/auth";

type AuthStoreType = {
    user: User | null,
    loading: boolean,
}

function createAuthStore() {
    const { subscribe, set, update } = writable<AuthStoreType>({
        user: null,
        loading: true,
    })

    auth.onAuthStateChanged(user => {
        console.log("auth.onAuthStateChanged", user)
        set({user, loading: false})
    })

    async function login(username:string, password:string) {
        return signInWithEmailAndPassword (auth, username, password)
    }

    async function loginGoogle() {
        const provider = new GoogleAuthProvider();
        return signInWithPopup(auth, provider)
    }

    async function loginFacebook() {
        const provider = new FacebookAuthProvider()
        return signInWithPopup(auth, provider)
    }

    async function logout() {
        return signOut(auth)
    }

    async function register(email:string, password:string) {
        return createUserWithEmailAndPassword (auth, email, password)
    }

    async function sendResetPasswordMail(email:string) {
        return sendPasswordResetEmail(auth,email) 
    }

    async function confirmResetPassword(code:string, newPassword: string) {
        return confirmPasswordReset(auth, code,newPassword)
    }


    return {
        subscribe,
        login,
        register,
        loginGoogle,
        loginFacebook,
        sendResetPasswordMail,
        confirmResetPassword,
        logout,
    }
}

export const authStore = createAuthStore()

export const currentUserId = () => get(authStore).user?.uid
