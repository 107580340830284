<script lang="ts">
    import {transcript} from '../../stores/transcript';
    //export let num:number;
    export let id:string;

    $: num = $transcript.records.findIndex(r => r.id === id) + 1;
</script>



<div class="num">{num}</div>

<style>
    .num {
        width: 36px;
        user-select: none
        /*padding-top: 4px;*/
    }
</style>