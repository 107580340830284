import { $getNodeByKey, $getRoot, $getSelection, $isLineBreakNode, $isRangeSelection, $isTextNode, COMMAND_PRIORITY_LOW, createCommand, TextNode, 
    type EditorState, type LexicalCommand, type LexicalEditor, type LexicalNode, type NodeMutation, type RangeSelection, type ElementNode } from "lexical";
import { $createWordNode, $isWordNode, WordNode } from "./WordNode";
import { $createRecordNode, $isRecordNode, RecordNode } from "./RecordNode";
import type { TranscriptRecord } from "../../types/transcript";
import { $isHeaderNode } from "./HeaderNode";
import type { NodeDebugInfo } from "../../lexer-editor/debugger";
import { $isRecordTextNode, RecordTextNode } from "./RecordTextNode";
import { $isRecordMarginNode } from "./MarginNode";
import { mergeRegister } from "@lexical/utils";


//re

export type CommandSetSpeakerArgs = {
    recordId : string,
    speaker : string,
}

export type CommandRenameSpeakerArgs = {
    oldName : string,
    newName : string,
}

export const SET_SPEAKER_COMMAND = createCommand<CommandSetSpeakerArgs>();
export const RENAME_SPEAKER_COMMAND = createCommand<CommandRenameSpeakerArgs>();
export const FORCE_SAVE_COMMAND = createCommand();


export function initEditorState(editor:LexicalEditor, transcript:TranscriptRecord[]) {
    editor.update(() => { 
        const root = $getRoot();
        root.clear()
        for(const item of transcript) {
            const recordNode = $createRecordNode(item.id,item.speaker); //id, speaker

            const textNode = recordNode.getChildAtIndex(2)
            if(!$isRecordTextNode(textNode)) 
                throw new Error("Invalid record text node")

            for(const word of item.words ?? []) {
                const wordNode = $createWordNode(word.word, word.startTime, word.endTime, word.confidence);
                textNode.append(wordNode);
            }

            root.append(recordNode);
        }

    })
}


function getRecord(textNode:RecordTextNode) {
    const record  = textNode.getParent() as RecordNode

    const _header = record.getChildAtIndex(0)
    if(!$isHeaderNode(_header)) 
        throw new Error("Invalid record structure (header)")
    const header = _header as RecordNode

    const _margin = record.getChildAtIndex(1)
    if(!$isRecordMarginNode(_margin))
        throw new Error("Invalid record structure (margin)")
    const margin = _margin as RecordNode

    const _text = record.getChildAtIndex(2)
    if(!$isRecordTextNode(_text))
        throw new Error("Invalid record structure (text)")
    const text = _text as RecordTextNode

    return {record, header, margin, text}

}

//let initialUpdate = true;
let prevSelection : HTMLElement | null = null;
//selection change
function selectionChange(
    editor: LexicalEditor,
    editorState:EditorState,
    onSelectionChange: (recordId:string | null, wordIndex:number| null) => void,
    ) {
    const selection = editorState.read(() => {
        const selection = $getSelection();
        
        if(!$isRangeSelection(selection)) {
            return {recordId:null, wordIndex:null}
        }
        const sel = selection as RangeSelection

        const node = $getNodeByKey(sel.focus.key)
        if($isWordNode(node)) {
            const index = node.getPreviousSiblings().length
            const {record} = getRecord(node.getParent())
            return {recordId:record.getId(), wordIndex:index}
        }

        //console.log(node)
        return {recordId:null, wordIndex:null}

        //other cases?? record node? 
        
/*         const el = editor.getElementByKey(sel?.focus.key)
        if(prevSelection != el) {
            if(prevSelection) prevSelection.classList.remove("active")
            if(el) el.classList.add("active")
            prevSelection = el
        }
 */      });
    onSelectionChange(selection.recordId, selection.wordIndex)

}

//detect changes and mark user confidence 
function setUserConfidence(node: WordNode) {
    if(node.getConfidence() !== -1 && node.getInitialText() !== node.getTextContent()) 
        node.setConfidence(-1)
}

//detect space inside word and split
function splitWord(node: WordNode) {
    const text = node.getTextContent()
    const firstLetter = text.search(/\S|$/)
    const spaceInside = text.indexOf(' ',firstLetter)

    if(spaceInside !== -1) {
        console.log("SPACE INSIDE")
        const word1 = text.slice(0,spaceInside) 
        const word2 = text.slice(spaceInside)

        const sel = $getSelection()
        
        const ratio = word1.trim().length / (word1.trim().length + word2.trim().length)
        const brakePoint = node.getStart() + ratio * (node.getEnd() - node.getStart())

        const n1 = node.replace($createWordNode(word1, node.getStart(), brakePoint, -1))
        const n2 = n1.insertAfter($createWordNode(word2, brakePoint, node.getEnd(), -1))


        if($isRangeSelection(sel)) {
            const selRange = sel as RangeSelection

            const offset = selRange.focus.offset - word1.length
            console.log("OFFSET", offset)
            
           n1.selectNext(offset,offset)

        }
    }
}

function assignHoverListeners (
    editor: LexicalEditor,
    handleMouseRecordEnter: (recordId:string) => void,
    handleMouseRecordLeave: (recordId:string) => void,
) {
    return function (mutations: Map<string, NodeMutation>) {
        const registeredElements: WeakSet<HTMLElement> = new WeakSet();
        editor.getEditorState().read(() => {
            for (const [key, mutation] of mutations) {
                const element: null | HTMLElement = editor.getElementByKey(key);
                const record = $getNodeByKey<RecordNode>(key)
                const recordId = record?.getId()
                if (
                // Updated might be a move, so that might mean a new DOM element
                // is created. In this case, we need to add and event listener too.
                (mutation === 'created' || mutation === 'updated') &&
                element !== null &&
                !registeredElements.has(element)
                ) {
                    registeredElements.add(element);
                    element.addEventListener('mouseenter', (event: Event) => {
                        handleMouseRecordEnter(recordId)
                        //console.log("MOUSE ENTER", event)
                    });
                    element.addEventListener('mouseleave', (event: Event) => {
                        //console.log("MOUSE LEAVE", event)
                        handleMouseRecordLeave(recordId)
                    });
                }
            }
        });

    }
}

//merge words
function mergeWords(node: WordNode) {
    //empty word... remove?
    if(node.getTextContentSize() === 0) return
    if(node.getTextContent()[0] !== ' ' && node.getPreviousSibling() && $isWordNode(node.getPreviousSibling())) {
        const prev = node.getPreviousSibling()
        const text = prev.getTextContent() + node.getTextContent()
        console.log("MERGE WORDS", text)
        node.remove()
        const n = prev.replace($createWordNode(text, prev.getStart(), node.getEnd(), -1))
        //n.selectNext(1,1)
    }
}

function deleteEmpty(node:RecordTextNode) {
    if(node.getChildren().length === 0) {
        const prev = node.getParent().getPreviousSibling() as ElementNode
        const next = node.getParent().getNextSibling() as ElementNode
        node.getParent().remove()
        //prev.selectPrevious()
        const prevText = prev.getChildAtIndex(2) as RecordTextNode
        //console.log()
        prevText.getLastChild().selectNext()
        //console.log(prev.getChildren())
    }
}

/*
//update margin
function updateMargin(node:RecordTextNode) {
    const {record,margin} = getRecord(node)
    margin.setNumber(record.getIndexWithinParent() + 1)
}
*
/*
function updateHeader(node:RecordTextNode) {
    //console.log('transform', node)

    const {record,header} = getRecord(node)
    
    const words = node.getChildren()
    
    header.setCount(words.length)
    const w0 = words[0]
    if($isWordNode(w0)) {
        header.setStart(w0.getStart())
    }
    const w1 = words[words.length-1]
    if($isWordNode(w1)) {
        header.setEnd(w1.getEnd())
    }

    header.setNumber(record.getIndexWithinParent() + 1)
    
    //console.log(node.getChildren())
}
*/

function breakRecord(node:RecordTextNode) {
    const {record} = getRecord(node)
        
    const words = node.getChildren()
    const lineBreak = words.findIndex(w => $isLineBreakNode(w))
    if(lineBreak === -1) return


    console.log("LINE BREAK")
    //check for last??? 

    words[lineBreak].remove()

    const nextRecord = $createRecordNode(crypto.randomUUID(),record.getSpeaker())
    record.insertAfter(nextRecord)
    const nextRecordText = nextRecord.getChildAtIndex(2) as RecordTextNode
    nextRecordText.append(...words.slice(lineBreak+1))

    console.log("LINE BREAK", lineBreak, words.length)
    if(lineBreak === words.length - 1) {
        console.log("last word")
        const newWord = $createWordNode(' ', words[lineBreak-1].getEnd(), words[lineBreak-1].getEnd(), -1)
        nextRecordText.append(newWord)
        newWord.selectNext(1,1)
    }

    if(lineBreak === 0) {
        const newWord = $createWordNode(' ', words[lineBreak+1].getEnd(), words[lineBreak+1].getEnd(), -1)
        node.append(newWord)
    }

    
    if(!$isWordNode(nextRecordText.getChildAtIndex(0))) {
        console.log("splitted word")

        if(!$isWordNode(words[lineBreak-1]) || !$isTextNode(nextRecordText.getChildAtIndex(0))) return
        const prevWord = words[lineBreak-1] as WordNode
        const nextWord = nextRecordText.getChildAtIndex(0) as TextNode

        const ratio = prevWord.getTextContent().trim().length / (prevWord.getTextContent().trim().length + nextWord.getTextContent().trim().length) 
        const brakePoint = prevWord.getStart() + ratio * (prevWord.getEnd() - prevWord.getStart())

        prevWord.replace($createWordNode(prevWord.getTextContent(),prevWord.getStart(),brakePoint, -1))
        nextWord.replace($createWordNode(nextWord.getTextContent(),brakePoint,prevWord.getEnd(), -1))
    }
}

function stateToTranscript(editorState: EditorState) {
    return editorState.read(() => {
        const res: TranscriptRecord[] = [] 
        const records = $getRoot().getChildren() as RecordNode[]
        for(const record of records) {
            const textNode = record.getChildAtIndex(2) as RecordTextNode
            const words = textNode?.getChildren() ?? [] as WordNode[]

            const tr = {
                id: record.getId(),
                speaker: record.getSpeaker(),
                words: words.map(w => ({
                    id: "",
                    startTime: w.getStart(),
                    endTime: w.getEnd(),
                    word: w.getTextContent(),
                    confidence: w.getConfidence(),
                })),
            }
            res.push(tr)
        }

        return res;
    })
}

let firstUpdate = true
function handleChange(editorState: EditorState,dirtyElements:any, dirtyLeaves: Set<string>, onChange: (transcript : TranscriptRecord[], force:boolean) => void) {
    if(firstUpdate) {
        firstUpdate = false
        return
    }
    if(dirtyLeaves.size === 0 && dirtyElements.size === 0) return
    
    const transcript = stateToTranscript(editorState)
    onChange(transcript,false)
}

function commandSetSpeaker(editor: LexicalEditor,onChange: (transcript : TranscriptRecord[], force:boolean) => void) {
    return function ({recordId,speaker}: CommandSetSpeakerArgs) {
        editor.update(() => {
            const record = $getRoot().getChildren().find(c => $isRecordNode(c) && c.getId() === recordId)
            record.setSpeaker(speaker)
        },{onUpdate: ()=>{
            const transcript = stateToTranscript(editor.getEditorState())
            onChange(transcript,true)
        }})
        return false;
    }
}

function commandRenameSpeaker(editor: LexicalEditor,onChange: (transcript : TranscriptRecord[], force:boolean) => void) {
    return function ({oldName,newName}: CommandRenameSpeakerArgs) {
        editor.update(() => {
            const records = $getRoot().getChildren() as RecordNode[]
            for(const record of records) {
                if(record.getSpeaker() === oldName) {
                    record.setSpeaker(newName)
                }
            }

        },{onUpdate: ()=>{
            const transcript = stateToTranscript(editor.getEditorState())
            onChange(transcript,true)
        }})
        return false;
    }
}

function commandForceSave(editor: LexicalEditor,onChange: (transcript : TranscriptRecord[], force:boolean) => void) {
    return function () {
        const transcript = stateToTranscript(editor.getEditorState())
        onChange(transcript,true)
        return false;
    }
}

export function registerWordTransormers(
    editor: LexicalEditor,
    handleMouseRecordEnter: (key:string) => void,
    handleMouseRecordLeave: (key:string) => void,
    onChange: (transcript : TranscriptRecord[], force:boolean) => void,
    onSelectionChange: (recordId:string, wordIndex:number) => void,
    ) {
    firstUpdate = true

    editor.registerCommand(SET_SPEAKER_COMMAND,    commandSetSpeaker(editor,onChange),    COMMAND_PRIORITY_LOW );
    editor.registerCommand(RENAME_SPEAKER_COMMAND, commandRenameSpeaker(editor,onChange), COMMAND_PRIORITY_LOW );
    editor.registerCommand(FORCE_SAVE_COMMAND,     commandForceSave(editor,onChange),     COMMAND_PRIORITY_LOW );

    return mergeRegister(
        editor.registerUpdateListener(({editorState,dirtyElements,dirtyLeaves}) => handleChange(editorState,dirtyElements,dirtyLeaves,onChange) ),
        

        editor.registerMutationListener(RecordNode, assignHoverListeners(editor,handleMouseRecordEnter,handleMouseRecordLeave)),

        //selection change
        editor.registerUpdateListener(({editorState}) => selectionChange(editor,editorState,onSelectionChange)),
    
        //detect changes and mark user confidence 
        editor.registerNodeTransform(WordNode, setUserConfidence),


        //detect space inside word and split
        editor.registerNodeTransform(WordNode, splitWord),

        //merge words
        editor.registerNodeTransform(WordNode, mergeWords),

        //update margin
        //editor.registerNodeTransform(RecordTextNode, updateMargin),

        //update header
        //editor.registerNodeTransform(RecordTextNode, updateHeader),

        editor.registerNodeTransform(RecordTextNode, deleteEmpty),
        
        //break record 
        editor.registerNodeTransform(RecordTextNode, breakRecord),

    )
}

export function getRecordElementById(editor:LexicalEditor, id:string) {
    return editor.getEditorState().read(() => {
        const record = $getRoot().getChildren().find(c => $isRecordNode(c) && c.getId() === id)
        if(!record) return undefined
        return editor.getElementByKey(record.getKey())
    })
}

export function getWordElementByIndex(editor:LexicalEditor, recordId:string, wordIndex) {
    return editor.getEditorState().read(() => {
        const record = $getRoot().getChildren().find(c => $isRecordNode(c) && c.getId() === recordId)
        if(!record) return undefined
        const recordText = record.getChildAtIndex(2) as RecordTextNode
        const word = recordText.getChildAtIndex(wordIndex)
        if(!word) return undefined
        return editor.getElementByKey(word.getKey())
    })
}

export function customNodesInfo(node:LexicalNode): NodeDebugInfo | undefined {

    if($isWordNode(node)) {
        return {
            nodeType : "word",
            nodeData :`"${node.getTextContent()}" (${node.getStart()},${node.getEnd()}) confidence: ${node.getConfidence().toFixed(2)}`
        }
    }
    if($isHeaderNode(node)) {
        return {
            nodeType : "header",
            nodeData : ""
        }
    }
    
    if($isRecordNode(node)) { 
        return {
            nodeType : "record",
            nodeData : ""
        }
    }

    if($isRecordTextNode(node)) { 
        return {
            nodeType : "record-text",
            nodeData : ""
        }
    }

    if($isRecordMarginNode(node)) { 
        return {
            nodeType : "record-margin",
            nodeData : `${node.getNumber()}`
        }
    }


    return undefined
}