<script lang="ts">
    import { timelineStoreSymbol, type TimelineStore, type MarkerInfo, screenToTimestamp, timesampToScreen, durationToScreen } from "../../timeline/store";
    import { getContext, onDestroy, onMount } from "svelte";

    export let position:number

    const RIGHT_MARGIN = 50

    const marketId = Symbol("position marker")
    const context = getContext<TimelineStore>(timelineStoreSymbol)

    function updateMarker(info:MarkerInfo) {
        
        const scrolledPos = timesampToScreen($context, position)
        if(scrolledPos < 0) {
            context.scrollDelta(scrolledPos)
        }

        
        const border = screenToTimestamp($context,$context.width - RIGHT_MARGIN) 
        if(position > border) {
            const secsOut = position - border
            const pixsOut = durationToScreen($context,secsOut)
            context.scrollDelta(pixsOut)
        }

        context.addorUpdateMarker(marketId,info)
    }

    $: markerInfo = {position, color: "red", width: 1, label: position.toFixed(2)}
    $: updateMarker(markerInfo)

    onMount(() => {
        context.addorUpdateMarker(marketId,markerInfo)
    })

    onDestroy(() => {
        context.removeMarker(marketId)
    })

</script>