<script lang="ts">
   	import { Link } from "svelte-navigator";
    import ProjectLayout from "../layouts/Project.svelte";

    import VoiceoverTimeline from "../components/timelines/VoiceoverTimeline.svelte";
    import { project } from "../stores/project";
    import DefineVoice from "../dialogs/DefineVoice.svelte";
    import Export from "../dialogs/Export.svelte";
    import VideoPlayer from "../components/VideoPlayer.svelte";
    // @ts-ignore
    import SvgVoices from "../assets/voices.svg";
    import VoiceoverEditor from "../components/VoiceoverEditor/VoiceoverEditor.svelte";
    import SvgAutotranslate from "../assets/autotranslate.svg";
  import { onDestroy, onMount } from "svelte";
  import { generateAllSounds, initVoiceover, voiceoverReady, voiceoverRecordsReady,allRecordsHaveText,allVoicesDefined, generatingAnySound } from "../stores/voiceover";
   import { loadingStore } from "../stores/loading";
  import { derived } from "svelte/store";
  import { editorMode } from "../stores/editorMode";
  import { readOnly } from "../stores/project";
  import DubAllWarningDialog from "../dialogs/DubAllWarning.svelte";
  import DropdownButton from "../controls/DropdownButton.svelte";

  import SvgIconAutotranslate from "../assets/autotranslate.svg?raw";

  const loading = derived(voiceoverReady, ($voiceoverReady) => !$voiceoverReady)
    loadingStore.add(loading)

    let editor : VoiceoverEditor | undefined;

    let showDefineVoice: boolean = false;
    function defineVoice() {
        showDefineVoice = true;
    }

    let showExport: boolean = false;
    function exportVideo() {
        showExport = true;
    }
    onMount(() => {
        $editorMode = "voiceover"
        
        if(!$voiceoverReady)
            initVoiceover();
    })
    onDestroy(() => {
        $editorMode = null
    })

    function handleAutoTranslate() {
        editor?.translateAll("gpt-3.5");
    } 

    function handleAutoTranslateGoogle() {
        editor?.translateAll("google");
    } 


    let showDubAllWarnings: boolean = false
    function handleDubAll() {
        if($allRecordsHaveText && $allVoicesDefined) {
            generateAllSounds()
        } else {
            showDubAllWarnings = true
        }
    }

    let actions = [
        {name: "gpt-3.5", icon: SvgIconAutotranslate, action: handleAutoTranslate},
        {name: "google",  icon: SvgIconAutotranslate, action: handleAutoTranslateGoogle},
    ]
</script>

<ProjectLayout>
    <div slot="title" class="title-project-page axis-x">
        <Link to="../transcript"><button>Back</button></Link>
        <!--
            <button disabled={$readOnly} on:click={handleAutoTranslate} class="button-white" style="margin-left: 32px"><SvgAutotranslate/><span>&#032;Autotranslate</span></button>
            <button disabled={$readOnly} on:click={handleAutoTranslateGoogle} class="button-white" style="margin-left: 32px"><SvgAutotranslate/><span>&#032;Google</span></button>
        -->

        <span class="shim"></span>

        <DropdownButton {actions}/>

        <span class="page-header">
            {$project.name}
        </span>
        <button disabled={$readOnly} on:click={defineVoice} class="button-white" style="margin-right: 32px;"><SvgVoices/><span>Define voices</span></button>
        {#if $voiceoverRecordsReady}
            <button disabled={$readOnly} on:click={exportVideo}>Generate video</button>
        {:else}
            <button disabled={$readOnly || $generatingAnySound} on:click={handleDubAll} class="button-white">Dub all</button>
        {/if}
        
        
        
    </div>
    <VoiceoverTimeline slot="timeline"/>
    <VideoPlayer slot="player"/>
    <VoiceoverEditor bind:this={editor} slot="editor"/>
</ProjectLayout>

<DefineVoice bind:show={showDefineVoice} />
<Export bind:show={showExport}/>
<DubAllWarningDialog bind:show={showDubAllWarnings} texts={!$allRecordsHaveText} voices={!$allVoicesDefined}/>

<style>
    .shim {
        width:30px;
    }
</style>