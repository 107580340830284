<script lang="ts">
  import { Link } from "svelte-navigator";
  import Loader from "../Loader.svelte";
  import SvgDownload from "../../assets/download.svg";

  import type { TargetPreview } from "../../types/projectPreview";
  import * as db from "../../services/database"
  import { cacheRemovedTarget } from "../../stores/projectslist";
  import DeleteButton from "../DeleteButton.svelte";
  import DownloadDialog from "../../dialogs/Download/Download.svelte";

  let showDownloadDialog = false;


    export let target:TargetPreview
    export let projectId:string;

    function handleDeleteTarget() {
        db.deleteTarget(projectId, target.langCode)
        cacheRemovedTarget(projectId, target.langCode)
    }

    function handleDownload() {
        showDownloadDialog = true;
    }

</script>


<td  style="vertical-align: middle;">
    {#if target.processing}
        {target.langName}
    {:else if target.status === "created"}
        <Link to={`/project/${projectId}/${target.langCode}/transcript`}>{target.langName}</Link>
    {:else}
        <Link to={`/project/${projectId}/${target.langCode}/voiceover`}>{target.langName}</Link>
    {/if}
</td>


<td>
    <div class="status ">{target.status}</div>
</td>

<td style="text-align:center;">
    {#if target.processing}
        <div style="margin-left: 17px; margin-top:10px">
            <Loader/>
        </div>
    {:else}
        <DeleteButton on:delete={handleDeleteTarget}/>
    {/if}
</td>

<td style="text-align: center; padding-top:5px;">
    {#if target.download}
        <button on:click={handleDownload} class="squre20-button download-button">
          <SvgDownload/>
        </button>
    {/if}
</td>

<DownloadDialog bind:show={showDownloadDialog} projectId={projectId} target={target.langCode}/>

<style>
    .status {
        width: 70px;
        background-color: white;
        color: var(--main-blue);
        border: 1px solid var(--main-blue);
        border-radius: 4px; 
        padding: 2px;
        margin: 5px 0; 
        text-align: center;
        font-size: 11px;
        font-weight: 600;
        text-transform: capitalize;
    }

    .status-blue {
        background-color: var(--main-blue);
        color: white;
        border: 1px solid var(--main-blue);
    }
    
</style>