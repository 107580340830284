<script lang="ts">
import { createEventDispatcher } from "svelte";    

// @ts-ignore
import SvgArrowUp from "../assets/arrow_up.svg";


const dispatch = createEventDispatcher()

export let close: () => void;
export let title: string
export let items: {id:string, name:string}[]
export let recentItems: {id:string, name:string}[] = []
export let price: number = 0

let filter=""
$:filteredItems = items.filter(item => item.name.toLowerCase().includes(filter.toLowerCase()))
//$:filteredRecentItems = items.filter(item => item.name.toLowerCase().includes(filter.toLowerCase()))


function handleSelect(id: string) {
    console.log("handleSelect", id)
    dispatch("select", id)
    close()
}


let searchInputEl: HTMLInputElement
$: searchInputEl?.focus()

</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<div class="sel-lang-poup text">
    <div on:mousedown={close} class="selected-lang axis-x">
        {title}
        <SvgArrowUp/>
    </div>

    <input class="search" type="text" bind:value={filter} bind:this={searchInputEl}/>

    {#if recentItems.length > 0}
        <div class="hh2" style="margin-bottom: 6px;">Recent</div>
        {#each recentItems as item}
            <div class="row" on:click={() => handleSelect(item.id)}>
                <div class="lang">{item.name}</div>
            </div>
        {/each}

        <div style="border: 1px solid transparent">
            <div class="divider"></div>
        </div>
    {/if}
    <div class="all-lang">
        {#each filteredItems as item}
        <div class="axis-x row" on:click={() => handleSelect(item.id)}>
            <div class="lang">{item.name}</div>
            {#if price !== 0}
                <div class="blue-minutes">Add +{price} min</div>
            {/if}
        </div>
        {/each}
    </div>
    
</div>


<style>

    .sel-lang-poup {
        /*width: 325px;
        height: 298px;*/
        padding: 6px 8px 10px;
        margin-top: -7px;
        border: 1px solid var(--light-gray);
        border-radius: 4px;
        background: #FFFFFF;
        z-index: 10;
    }

    .selected-lang {
        color: var(--light-gray);
        justify-content: space-between;
        align-items: center; 
        margin-bottom: 12px;
    }

    .search {
        width: 309px;
        height: 32px;
        border: 1px solid var(--light-gray);
        border-radius: 4px;
        margin-bottom: 6px;
        background: #fff url("../assets/search.svg") 284px 7px no-repeat; 
    }

    .search:hover {
        border: 1px solid var(--hover-blue);
    }

    .lang {
        margin-bottom: 6px;
        color: var(--text-black);
    }
    
    .row:hover {
        background: var(--main-blue);
        color:#FFFFFF !important;
        margin: -3px -8px -1px -8px;
        padding: 3px 8px 1px 8px;
    }

    .row {
        justify-content: space-between; 
        align-items:center;
    }

    .row:hover *{
        color:#FFFFFF !important;
    }

    .divider {
        width: 268px;
        height: 1px;
        background-color: var(--disabled-gray);
        margin: 1px 22px 7px 16px;
    }

    .blue-minutes {
        font-size: 12px;
        font-weight: 400;
        color: var(--main-blue);
        margin: 0 8px 6px 0;
    }

    .all-lang {
        max-height: 97px;
        overflow-y: scroll;
        overflow-x: hidden;
    }

</style>