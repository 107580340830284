<script lang="ts">
    import { useLocation, useNavigate  } from "svelte-navigator";
     import { onMount } from "svelte";

    export let to:string;

    const navigate = useNavigate()

    onMount(() => {
        navigate(to)
    })
    

    const location = useLocation();
    $: console.log($location);
</script>