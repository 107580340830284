<script lang="ts">
    export let text = "PLACEHOLDER"
    export let color = "white"
    export let width = "100%"
    export let height = "100%"
</script>

<div class="placeholder" style="background-color: {color}; width: {width}; height:{height};">
    {text}
</div>

<style>
    .placeholder {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
</style>