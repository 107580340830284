import {get, writable, type Readable} from 'svelte/store'
import { derived } from 'svelte/store';

export type LoadingStoreType = {
    loading: boolean
    firstLoading: boolean
}


function createLoadingStore() {
    const pending = writable(0)
    const first = writable(true)
    const firstLoading = writable(true)

    pending.subscribe((v) => {
        console.log("loading store:","pending:",v)
    })

    first.subscribe((v) => {
        console.log("loading store:","first:",v)
    })

    firstLoading.subscribe((v) => {
        console.log("loading store:","firstLoading:",v)
    })

    const loading = derived(([pending,first]), ([$pending,$first]) => $pending > 0 || $first)

    loading.subscribe((v) => {
        console.log("loading store:","loading:",v)

        if(!v) {
            firstLoading.set(false)
        }
    })

    const state = derived([loading, firstLoading], ([$loading, $firstLoading]) => ({firstLoading:$firstLoading, loading:$loading }))
    

    return {
        subscribe: state.subscribe,
        add: (store: Readable<boolean>) => {
            console.log("loading store:",get(store))
            
            if(!get(store)) {
                first.set(false)
                return
            }

            pending.update(v => v + 1)
            first.set(false)
            
            const un = store.subscribe((v) => {
                if(!v) {
                    pending.update(v => v - 1)
                    un()
                }
            })
        }
    }
}

export const loadingStore = createLoadingStore()