<script lang="ts">
    import Dropzone from "svelte-file-dropzone";
    import { createEventDispatcher } from "svelte";
    import SvgCloud from "../../assets/cloud-upload.svg";

    const dispath = createEventDispatcher()

    let dragOverDoc = false
    let dragOverDocCounter = 0
    function handleDocDragEnter() {
        dragOverDocCounter++
        dragOverDoc = true
    }
    function handleDocDragLeave() {
        dragOverDocCounter--
        if (dragOverDocCounter === 0) {
            dragOverDoc = false
        }
    }

    function handleDocDragFinish() {
        dragOverDoc = false
    }

   let dragOver = false
   let dragOverCounter = 0
    function handleDragEnter() {
            dragOverCounter++
            dragOver = true
    }
    function handleDragLeave() {
        dragOverCounter--
        if (dragOverCounter === 0) {
            dragOver = false
        }
    }
    function handleDrop(e) {
        dragOver = false
        dispath("drop",e.detail)
    }


   $: dropZoneClasses = "video-dropzone " + (dragOverDoc ? "video-dropzone-doc-drag " : "" ) + (dragOver ? "video-dropzone-drag " : "" )

</script>

<svelte:window 
    on:drop={handleDocDragFinish}  
    on:dragenter={handleDocDragEnter}
    on:dragleave={handleDocDragLeave}
/>

<Dropzone 
    multiple={false}  accept="video/*" disableDefaultStyles={true}
    on:drop = {handleDrop}
    on:dragenter={handleDragEnter}
    on:dragleave={handleDragLeave} 
    containerClasses={dropZoneClasses} >
        <div style="text-align: center;"><SvgCloud/></div>
        {#if dragOver}
            <div>
                <h3 class="drug-message">Drop file here</h3>
            </div>
        {:else}
            <div>
                <h3 class="drug-message">Drag and drop your video here</h3>
            </div>
        {/if}
</Dropzone>

<style>
    :global(.video-dropzone) {
        padding: 50px;
        border: 3px dashed gray;
        border-radius: 8px;
        background-color: var(--disabled-gray-op05);
    }

    :global(.video-dropzone-doc-drag) {
        position: absolute;
        left: 1px;
        right: 1px;
        top: 1px;
        bottom: 1px;
    }

    :global(.video-dropzone-drag) {
        border-color: blue;
    }

    .drug-message {
        color: gray;
        font-size: 25px;
        text-align: center;
        font-weight: 400;
    }
</style>