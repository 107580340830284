<script>
    import { useNavigate, useLocation } from "svelte-navigator";
    import { authStore } from "../stores/auth";
  
    const navigate = useNavigate();
    const location = useLocation();
  
    $: if (!$authStore.user && !$authStore.loading) {
      navigate("/login", {
        state: { from: $location.pathname },
        replace: true,
      });
    }
  </script>
  
  {#if $authStore.user}
    <slot />
  {/if}
  