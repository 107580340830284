<script lang="ts">
  import { FORCE_SAVE_COMMAND, getRecordElementById, getWordElementByIndex, registerWordTransormers } from "./editor";
  import { onDestroy, onMount } from "svelte";
  import { getEditor } from "../../lexer-editor/editorContext";
  import { initEditorState } from "./editor";

  import { hoverRecordId, activeRecordId, activeWordIndex, saveTranscript } from "../../stores/transcript";
  import type { TranscriptRecord } from "src/types/transcript";

  import { transcript } from "../../stores/transcript";
  
  import {isPlaying,currentPos, seek} from '../../stores/player'
  import { readOnly  } from "../../stores/project";

  const editor = getEditor();
  $: editor.setEditable(!$readOnly) 

  export function save() {
    editor.dispatchCommand(FORCE_SAVE_COMMAND,{})
  }




  function handleChange(tr : TranscriptRecord[], force:boolean) {
    saveTranscript(tr,force)
  }

  $: {
    if($isPlaying) {
      for(const record of $transcript.records) {
        if(record.words && record.words[0].startTime < $currentPos && record.words[record.words.length-1].endTime > $currentPos) {
          $activeRecordId = record.id
          for(let i=0;i<record.words.length;i++) {
            if(record.words[i].startTime < $currentPos && record.words[i].endTime > $currentPos) {
              $activeWordIndex = i
              break
            }
          }
          break
        }
        
      }


    } else {
      const record = $transcript.records.find(r => r.id === $activeRecordId) 
      if(record) {
        if($activeWordIndex && $activeWordIndex < record.words.length)
          seek(record.words[$activeWordIndex].startTime)
      }
    }
  } 

 //----------------- hover ----------------- 
  function handleMouseRecordEnter(recordId:string) {
    $hoverRecordId = recordId
  }

  function handleMouseRecordLeave(recordId:string) {
    $hoverRecordId = null
  }

  let prevHoverRecordEl = null
  $: {
    const hoverEl = getRecordElementById(editor,$hoverRecordId)
    if (prevHoverRecordEl) {
      prevHoverRecordEl.classList.remove("hover")
    }
    if (hoverEl) {
      hoverEl.classList.add("hover")
    }
    prevHoverRecordEl = hoverEl
  }
  
  //----------------- active ----------------- 
  function handleSelectionChange(recordId:string, wordIndex:number) {
    if($activeRecordId !== recordId) {
      $activeRecordId = recordId
    }
    if($activeWordIndex !== wordIndex) {
      $activeWordIndex = wordIndex
    }
  }

  let prevActiveRecordEl = null
  let prevActiveWordEl = null

  $: {
    const activeRecordEl = getRecordElementById(editor,$activeRecordId)
    if (prevActiveRecordEl) {
      prevActiveRecordEl.classList.remove("active")
    }
    if (activeRecordEl) {
      activeRecordEl.classList.add("active")
    }
    prevActiveRecordEl = activeRecordEl

    const activeWordEl = getWordElementByIndex(editor,$activeRecordId,$activeWordIndex)
    if (prevActiveWordEl) {
      prevActiveWordEl.classList.remove("active")
    }
    if (activeWordEl) {
      activeWordEl.classList.add("active")
    }
    prevActiveWordEl = activeWordEl
  }


  onMount(() => {
    //console.log("TranscriptPlugin onMount",$transcript.records)
    initEditorState(editor,$transcript.records)
    return registerWordTransormers(
        editor,
        handleMouseRecordEnter,
        handleMouseRecordLeave,
        handleChange,
        handleSelectionChange,
        )
  })

</script>
