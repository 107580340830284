<script lang="ts">
    import SvgVolume from "../../assets/volume.svg";

    export let cue
    export let hover:boolean
    export let index:number

</script>

<div class="cue axis-x" class:hover={hover} on:mouseenter on:mouseleave>
    <div class="blue cue-pic"> 
        {index+1}  
        <SvgVolume/> 
    </div>
    
    <div class="extrasmall-text cue-text">
        {cue.text}
    </div> 
</div>

<style>
    .cue {
        margin-top: 4px;
        width: 100%;
        height: 32px;
        border: 1px solid #EFEFEF; /* --light-gray opacity 0.1*/
        border-radius: 4px;
        padding: 4px;
        min-width: 20px;
        background-color: white;
    }

    .cue.hover {
        border: 1px solid var(--main-blue-op04);

    }

    .cue.hover :global(svg path) {
        fill: red;
        stroke: red;
    }

    :global(.selected) .cue {
        /*background: rgba(62, 149, 235, 0.1);*/
        background-color: var(--hover-blue-op01);
        border: 1px solid var(--main-blue-op04);
    }

    .cue-pic {
        font-size: 10px; 
        font-weight: 600; 
        margin: 0 10px 0 4px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    .cue-text {
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
</style>