<script>
    import { useNavigate, useLocation } from "svelte-navigator";
    import { authStore } from "../stores/auth";

    import {dbUserProfileStore} from "../stores/db"
  
    const navigate = useNavigate();
    const location = useLocation();
    
    $: if (!$dbUserProfileStore.loading && !$dbUserProfileStore.data.admin) {
      navigate("/", {
        replace: true,
      });
    }

  </script>
  
  {#if $dbUserProfileStore.data?.admin}
    <slot />
  {/if}
  