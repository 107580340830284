<script lang="ts">
    import Ruller from "./Ruller.svelte";
    import { getContext } from "svelte";
    import {getVisibleRange, type TimelineStore} from "./store";
    import {timelineStoreSymbol,timesampToScreen} from "./store";

    export let showMarker = false

    const ctx = getContext<TimelineStore>(timelineStoreSymbol);

    $: visibleRange = getVisibleRange($ctx);


    function splitTime(sec:number) {
        const hours = Math.floor(sec/3600)
        const minutes = Math.floor((sec - hours * 3600)/60)
        const seconds = sec - hours * 3600 - minutes*60

        return {
            hours,
            minutes,
            seconds
        }
    }

    function formatTime(sec:number) {
        const {hours,minutes,seconds} = splitTime(sec)

        const hoursStr = hours.toString();
        const minutesStr = minutes.toString().padStart(2,"0");
        const secondsStr = seconds.toFixed(2).padStart(5,"0");

        if(hours === 0)
            return `${minutesStr}:${secondsStr}`
        
        return `${hoursStr}:${minutesStr}:${secondsStr}`
    }

</script>

<!--
{#if $ctx.zoom > 1 }
    <Ruller devisionValue={100} pixelsPerSec={$ctx.zoom} {formatTime} start={visibleRange.start} end = {visibleRange.end}/>
{:else}
    <Ruller devisionValue={10} pixelsPerSec={$ctx.zoom} {formatTime} start={visibleRange.start} end = {visibleRange.end}/>
{/if}
-->

{#if $ctx.zoom > 45 }
    <Ruller devisionValue={1} pixelsPerSec={$ctx.zoom} {formatTime} {showMarker} start={visibleRange.start} end = {visibleRange.end} subMark1={10} subMark2={2} on:click/>
{:else if $ctx.zoom > 40}
    <Ruller devisionValue={1} pixelsPerSec={$ctx.zoom} {formatTime} {showMarker} start={visibleRange.start} end = {visibleRange.end} subMark1={0} subMark2={2} on:click/>
{:else if $ctx.zoom > 5}
    <Ruller devisionValue={10} pixelsPerSec={$ctx.zoom} {formatTime} {showMarker} start={visibleRange.start} end = {visibleRange.end} subMark1={10} subMark2={2} on:click/>
{:else if $ctx.zoom > 2.5}
    <Ruller devisionValue={60} pixelsPerSec={$ctx.zoom} {formatTime} {showMarker} start={visibleRange.start} end = {visibleRange.end} subMark1={60} subMark2={4} on:click/>
{:else}
    <Ruller devisionValue={60} pixelsPerSec={$ctx.zoom} {formatTime} {showMarker} start={visibleRange.start} end = {visibleRange.end} subMark1={0} subMark2={4} on:click/>
{/if}