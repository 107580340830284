<script lang="ts">
    import { voiceover } from "../../stores/voiceover";
    import {formatTime} from "../../utils/time"

    export let id:string
    $: record = $voiceover.find((v) => v.id === id)
    $: startTime = record?.startTime ?? 0
    
    

    $: soundReady = record?.soundReady ?? false
    $: duration = (soundReady ? record?.duration : record?.estimatedDuration) ?? 0
    $: endTime = startTime + duration

    $:startTimeStr = formatTime(startTime)
    $:endTimeStr = formatTime(endTime)
    $:durationStr = duration.toFixed(1)

</script>

<div class="voiceover-record-translation-header time">{startTimeStr} <span class:estimated={!soundReady}>- {endTimeStr} ({durationStr} sec)</span></div>

<style>
.time {
    font-weight: 600;
    font-size: 14px;
    padding-bottom: 6px;
    text-align: center;
}
.estimated {
    color: lightgrey;
}
</style>