<script lang="ts">
    
  import Composer from "../../lexer-editor/Composer.svelte";
  import ContentEditable from "../../lexer-editor/ContentEditable.svelte";
  import VoiceoverPlugin from "./VoiceoverPlugin.svelte";
  //import Debugger from "../../lexer-editor/Debugger.svelte";
  //import { customNodesInfo } from "./editor";
  import { RecordNode } from "./RecordNode";
  import { TranslationTextNode } from "./TranslationTextNode";
  import { HeaderNode } from "./HeaderNode";
  import { RecordMarginNode } from "./MarginNode";
  import { TranscriptHeaderNode } from "./TranscriptHeaderNode";
  import { TranslateHeaderNode } from "./TranslationHeaderNode";
  import { TranscriptTextNode } from "./TranscriptTextNode";
    import { voiceoverEditorStore } from "./store";
    import ElementBlocker from "./ElementBlocker.svelte";
    import {readOnly } from "../../stores/project";

    import Switcher from "../../controls/Switcher.svelte";

    let showSource = true

    let plugin: VoiceoverPlugin | undefined;

    

    export function save() {
        plugin?.save();
    }

    export async function translateAll(engine:string) {
        plugin?.translateAll(engine);
    }

    $: config = {
        namespace: 'voiceover-editor',
        editable: !$readOnly,
        nodes: [RecordNode,TranslationTextNode,HeaderNode,RecordMarginNode,TranscriptHeaderNode,TranslateHeaderNode,TranscriptTextNode],
        onError: console.error
    };

    $:console.log(config)

</script>

<div style="padding: 0 0 2px 10px; ">
    <Switcher bind:checked={showSource} label="show sources"/>
</div>

<div class:show-source={showSource}>
    <Composer config={config}>
        <ContentEditable className="voiceover-editor"/>
        <VoiceoverPlugin bind:this={plugin}/>
        <!-- <Debugger getNodeDebugInfo={customNodesInfo}/>  -->
    </Composer>
    

</div>

{#each $voiceoverEditorStore as el }
    <ElementBlocker element = {el}/>
{/each}

<style> 

:global(voiceover-editor) {
    font-weight: 400;
    position: relative;
}

:global(.voiceover-editor:focus-visible) {
    outline: none;
} 


:global(.voiceover-record) {
    display: grid;
    grid-template-columns: 0fr 0fr 1fr;
    grid-template-rows: repeat(3, 0fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;

    width: calc(100% - 4px);
    padding: 4px 12px 0 8px;
    border: 1px solid transparent;
    border-radius: 4px;
    font-weight: 400;
    margin: 0 4px 10px 4px;
}

:global(.show-source .voiceover-record) {
    grid-template-columns: 0fr 1fr 1fr;
} 

:global(.voiceover-record.hover) {
    border: 1px solid var(--main-blue-op04);
}

:global(.voiceover-record.active) {
    background-color: var(--main-blue-op01);
    border: 1px solid var(--main-blue-op04);
}

:global(.voiceover-record-header) { 
    grid-area: 1 / 1 / 2 / 4; 
    justify-content: space-between;
    position: relative;
}
:global(.voiceover-record-margin) { 
    position: relative;
    grid-area: 2 / 1 / 5 / 2;
    width: 36px;
 }

:global(.voiceover-record-transcript-header) {
    display: none;
     grid-area: 2 / 2 / 3 / 3; 
}

:global(.show-source .voiceover-record-transcript-header) {
    display: block;
}

:global(.voiceover-record-translation-header) { grid-area: 2 / 3 / 3 / 4; }

:global(.voiceover-record-transcript-text) { 
    display :none;
    grid-area: 3 / 2 / 3 / 3; 
    padding-right: 16px;
}

:global(.show-source .voiceover-record-transcript-text) {
    display: block;
}

:global(.voiceover-record-translation-text) { 
    display: block;
    grid-area: 3 / 3 / 4 / 4; 
    padding-left: 16px;
}


</style>