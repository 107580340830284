import type { Transcript, TranscriptRecord } from "../types/transcript";
import { transcript } from './transcript';
import { derived } from "svelte/store";
import { voiceoverInitial } from './voiceover'

type AddTranscriptRecord = {
    action: "add"
    transcript: TranscriptRecord
}

type DelTranscriptRecord = {
    action: "del"
    recordId: string
}


type UpdateTranscriptRecord = {
    action: "change"
    recordId: string
    speaker: string | undefined
    text: string | undefined
}

type TranscriptChanges = UpdateTranscriptRecord | DelTranscriptRecord | AddTranscriptRecord


export const transcriptChanges = derived([voiceoverInitial,transcript], ([$voiceoverInitial,$transcript]) => {
    const res: TranscriptChanges[] = []

    for(const rec of $voiceoverInitial) {
        const transcriptRecord = $transcript.records.find(tr => tr.id == rec.id)
        if(!transcriptRecord) {
            //record was deleted
            //console.log("TRANSCRIPT CHANGES","DELETED")
            const del:DelTranscriptRecord = {action: "del",recordId: rec.id}
        } else {
            const change:UpdateTranscriptRecord = {action: "change", recordId:rec.id, speaker: undefined, text:undefined}
            //check update
            let changed = false
            if(transcriptRecord.speaker !== rec.speaker) {
                //speaker changes
                //console.log("TRANSCRIPT CHANGES","SPEAKER CHANGED")
                change.speaker = rec.speaker
                changed = true
            }

            const transcriptText = transcriptRecord.words.map(w => w.word).join(' ')

            if(transcriptText !== rec.transcript?.text) {
                //text changes
                //console.log("TRANSCRIPT CHANGES","TEXT CHANGED")
                change.text = transcriptText
                changed = true
            }

            if(changed)
                res.push(change)
        }

        for(const rec of $transcript.records) {
            if(!$voiceoverInitial.some(t => t.id === rec.id)) {
                const add:AddTranscriptRecord = {action: "add", transcript: rec}
                res.push(add)
            }
        }
    }

    return res
})