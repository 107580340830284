export const firebaseErrors = {
    "auth/admin-restricted-operation":"Only administrators can perform this operation",
    "auth/argument-error":"Invalid argument provided",
    "auth/app-not-authorized":"App is not authorized",
    "auth/app-not-installed":"App is not installed",
    "auth/captcha-check-failed":"Captcha verification failed",
    "auth/code-expired":"Verification code has expired",
    "auth/cordova-not-ready":"Cordova is not ready",
    "auth/cors-unsupported":"Cross-origin resource sharing is not supported",
    "auth/credential-already-in-use":"Credential is already in use",
    "auth/custom-token-mismatch":"Custom token mismatch",
    "auth/requires-recent-login":"Credential is too old,  please log in again",
    "auth/dependent-sdk-initialized-before-auth":"Dependent SDK initialized before authentication",
    "auth/dynamic-link-not-activated":"Dynamic link is not activated",
    "auth/email-change-needs-verification":"Email change requires verification",
    "auth/email-already-in-use":"Email is already in use",
    "auth/emulator-config-failed":"Emulator configuration failed",
    "auth/expired-action-code":"Action code has expired",
    "auth/cancelled-popup-request":"Popup request was cancelled due to expiration",
    "auth/internal-error":"Internal error occurred",
    "auth/invalid-api-key":"Invalid API key",
    "auth/invalid-app-credential":"Invalid app credential",
    "auth/invalid-app-id":"Invalid app ID",
    "auth/invalid-user-token":"Invalid user token",
    "auth/invalid-auth-event":"Invalid authentication event",
    "auth/invalid-cert-hash":"Invalid certificate hash",
    "auth/invalid-verification-code":"Invalid verification code",
    "auth/invalid-continue-uri":"Invalid continue URI",
    "auth/invalid-cordova-configuration":"Invalid Cordova configuration",
    "auth/invalid-custom-token":"Invalid custom token",
    "auth/invalid-dynamic-link-domain":"Invalid dynamic link domain",
    "auth/invalid-email":"Invalid email address",
    "auth/invalid-emulator-scheme":"Invalid emulator scheme",
    "auth/invalid-credential":"Invalid credential from identity provider",
    "auth/invalid-message-payload":"Invalid message payload",
    "auth/invalid-multi-factor-session":"Invalid multi-factor authentication session",
    "auth/invalid-oauth-client-id":"Invalid OAuth client ID",
    "auth/invalid-oauth-provider":"Invalid OAuth provider",
    "auth/invalid-action-code":"Invalid action code",
    "auth/unauthorized-domain":"Unauthorized domain",
    "auth/wrong-password":"Incorrect password",
    "auth/invalid-persistence-type":"Invalid persistence type",
    "auth/invalid-phone-number":"Invalid phone number",
    "auth/invalid-provider-id":"Invalid provider ID",
    "auth/invalid-recipient-email":"Invalid recipient email",
    "auth/invalid-sender":"Invalid sender",
    "auth/invalid-verification-id":"Invalid verification ID",
    "auth/invalid-tenant-id":"Invalid tenant ID",
    "auth/multi-factor-info-not-found":"Multi-factor authentication info not found",
    "auth/multi-factor-auth-required":"Multi-factor authentication is required",
    "auth/missing-android-pkg-name":"Missing Android package name",
    "auth/missing-app-credential":"Missing app credential",
    "auth/auth-domain-config-required":"Authentication domain configuration is required",
    "auth/missing-verification-code":"Missing verification code",
    "auth/missing-continue-uri":"Missing continue URI",
    "auth/missing-iframe-start":"Missing iframe start",
    "auth/missing-ios-bundle-id":"Missing iOS bundle ID",
    "auth/missing-or-invalid-nonce":"Missing or invalid nonce",
    "auth/missing-multi-factor-info":"Missing multi-factor authentication info",
    "auth/missing-multi-factor-session":"Missing multi-factor authentication session",
    "auth/missing-phone-number":"Missing phone number",
    "auth/missing-verification-id":"Missing verification ID",
    "auth/app-deleted":"App has been deleted",
    "auth/account-exists-with-different-credential":"Account exists with a different credential, confirmation needed",
    "auth/network-request-failed":"Network request failed",
    "auth/null-user":"User is null",
    "auth/no-auth-event":"No authentication event found",
    "auth/no-such-provider":"Provider not found",
    "auth/operation-not-allowed":"Operation not allowed",
    "auth/operation-not-supported-in-this-environment":"Operation not supported in this environment",
    "auth/popup-blocked":"Popup blocked",
    "auth/popup-closed-by-user":"Popup closed by user",
    "auth/provider-already-linked":"Provider is already linked to this account",
    "auth/quota-exceeded":"Quota exceeded",
    "auth/redirect-cancelled-by-user":"Redirect cancelled by user",
    "auth/redirect-operation-pending":"Redirect operation is pending",
    "auth/rejected-credential":"Credential is rejected",
    "auth/second-factor-already-in-use":"Second factor is already enrolled",
    "auth/maximum-second-factor-count-exceeded":"Maximum second factor count exceeded",
    "auth/tenant-id-mismatch":"Tenant ID mismatch",
    "auth/timeout":"Operation timed out",
    "auth/user-token-expired":"User token has expired",
    "auth/too-many-requests":"Too many attempts, please try again later",
    "auth/unauthorized-continue-uri":"Unauthorized continue URI",
    "auth/unsupported-first-factor":"Unsupported first factor",
    "auth/unsupported-persistence-type":"Unsupported persistence type",
    "auth/unsupported-tenant-operation":"Unsupported tenant operation",
    "auth/unverified-email":"Email is unverified",
    "auth/user-cancelled":"User cancelled the operation",
    "auth/user-not-found":"User not found",
    "auth/user-disabled":"User account is disabled",
    "auth/user-mismatch":"User mismatch",
    "auth/user-signed-out":"User has signed out",
    "auth/weak-password":"Password is too weak",
    "auth/web-storage-unsupported":"Web storage is unsupported",
    "auth/already-initialized":"Authentication is already initialized",
    "auth/recaptcha-not-enabled":"ReCAPTCHA is not enabled",
    "auth/missing-recaptcha-token":"Missing ReCAPTCHA token",
    "auth/invalid-recaptcha-token":"Invalid ReCAPTCHA token",
    "auth/invalid-recaptcha-action":"Invalid ReCAPTCHA action",
    "auth/missing-client-type":"Missing client type",
    "auth/missing-recaptcha-version":"Missing ReCAPTCHA version",
    "auth/invalid-recaptcha-version":"Invalid ReCAPTCHA version",
    "auth/invalid-req-type":"Invalid request type",
}