<script lang="ts">
    import { getContext } from "svelte";
    import { pannable } from "../utils/pannable";
    import {timelineStoreSymbol,calcThumbWidth,calcRatioOnScreen} from "./store";
    import type {TimelineStore} from "./store";


    const ctx = getContext<TimelineStore>(timelineStoreSymbol);

    $: ratioOnScreen = calcRatioOnScreen($ctx);
    $: thumbWidth = calcThumbWidth($ctx);
    

    function handlePanStart() {
	}

	function handlePanMove(event) {
        ctx.scrollDelta(event.detail.dx/ratioOnScreen)
	}

	function handlePanEnd(event) {

	}
</script>

<div class="scrollbar" draggable={false}>
    <div class="track"></div>
    {#if thumbWidth < $ctx.width}
    <div class="thumb" 
        use:pannable
        on:panstart={handlePanStart}
	    on:panmove={handlePanMove}
	    on:panend={handlePanEnd}
        style="transform: translateX({$ctx.scroll*ratioOnScreen}px); width: {thumbWidth}px;"
    ></div>
    {/if}

</div>

<style>
    .scrollbar {
        position: absolute;
        bottom: 0;
        margin-bottom: -4px;
        width: 100%;
        height: 8px;
        background-color: white;    
        pointer-events:all;
        user-select: none;
        z-index: 10;
    }
    .thumb {
        position: absolute;
        top: 0;
        height: 100%;
        background-color: var(--main-blue-op09);
        border-radius: 4px;
    }
/*    
    .thumb:hover {
        cursor: grab;
        background-color: green;
    }
*/
 .track {
        position: absolute;
        top: 2px;
        height: 4px;
        width: 100%;
        background-color: var(--disabled-gray-op05);
        
    }    

</style>