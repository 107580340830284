<script lang="ts">
    import SvgArrowDown from "../../assets/arrow_down.svg";    
    import Popup from "../Popup.svelte";
    import SpeakerSelectorPopup from "./SpeakerSelectorPopup.svelte";
    import type { Speaker } from "./Speaker";

    export let speakers : Speaker[]
    export let selectedSpeaker : string
    export let disabled = false
</script>

{#if disabled}
    <div class="axis-x hh2 blue">
        <div style="margin: 0 8px 0 20px; color:darkgray;">{selectedSpeaker}</div>
        <button class="svg-button squre20-button"><SvgArrowDown/></button> 
    </div>
{:else}
    <Popup let:close>
        <div class="axis-x hh2 blue">
            <div style="margin: 0 8px 0 20px">{selectedSpeaker}</div>
            <button class="svg-button squre20-button"><SvgArrowDown/></button> 
        </div>
        <SpeakerSelectorPopup speakers={speakers} close={close} on:addSpeaker on:updateSpeaker on:selectSpeaker slot="popup"/>
    </Popup>
{/if}



<style>


</style>