import {database} from "../firebase"
import {ref,onValue, push,set,remove, get, update} from 'firebase/database'
import type {DbTypes} from "common"
import type { TranscriptRecord } from "../types/transcript"

export type DbCallback<T> = (data: T) => void
export function _onValue<T>(path:string, callback: DbCallback<T>) {
    console.log("onValue",path)
    const dataRef = ref(database, path)
    return onValue(dataRef, (snapshot) => {
        console.log("read",path)
        const data = snapshot.val()
        callback(data)
    },err=>{
        console.error(`Error reading ${path}`)
        console.error(err)}
    )
}

export const _set = <T>(path:string, data:T) => set(ref(database,path),data)

export const onSourceLanguages = (callback:DbCallback<DbTypes.SourceLanguageSet>) => _onValue("/sourceLanguages", callback)
export const onTargetLanguages = (callback:DbCallback<DbTypes.TargetLanguageSet>) => _onValue("/targetLanguages", callback)
export const onVoices = (callback:DbCallback<DbTypes.VoiceSet>) => _onValue("/voices", callback)
export const onUserProfile = (userId:string, callback:DbCallback<DbTypes.UserProfile>) => _onValue(`/users/${userId}`, callback)
export const onProject = (projectId:string, callback:DbCallback<DbTypes.Project>) => _onValue(`/projects/${projectId}`, callback)

export function waitForDbPath(path:string) {
    return new Promise((resolve,reject) => {
        const un = onValue(ref(database,path), (snapshot) => {
            const data = snapshot.val()
            if(data) { 
                un()
                resolve(data)
            }
        })
    })
}

export async function readDb<T>(path:string) {
    const snapshot = await get(ref(database,path))
    return snapshot.val() as T
}

export function deleteProject(projectId: string) {
    remove(ref(database,`projects/${projectId}`))
}

export function deleteTarget(projectId: string, targetId: string) {
    remove(ref(database,`projects/${projectId}/targets/${targetId}`))
    console.log("deleteTarget",projectId,targetId )
}

export function deleteGenerationResults(projectId: string, targetId: string, resultId:string) {
    remove(ref(database,`projects/${projectId}/targets/${targetId}/results/${resultId}`))
    console.log("deleteGenerationResults",projectId,targetId,resultId)
}


export async function precreateProject(name:string,owner:string,sourceVideoFileName:string) {
    const projsRef = ref(database,'projects')
    const projRef = push(projsRef)
    const id = projRef.key
    
    await set(projRef,{
        id,
        owner,
        name,
        status:"precreate",
        sourceVideoFileName
    })

    return id!
}

export async function addTarget(projectId:string, targetId:string,order:number) {
    const target = {
        languageId : targetId,
        status     : "created",
        order,

    }
    return set(ref(database,`projects/${projectId}/targets/${targetId}`),target)
}

export async function saveTranscript(projectId:string, transcript:TranscriptRecord[]) {
    console.log("saveTranscript",projectId,transcript)
    return set(ref(database,`projects/${projectId}/transcript/data`),transcript)
}

export async function defineSpeakerVoice(projectId:string, targetId:string, speakerId:string, voiceId:string) {
    return set(ref(database,`projects/${projectId}/targets/${targetId}/voiceDefinition/${speakerId}`),{speaker:speakerId, voice:voiceId})
}

export async function saveVoiceDefinitions(projectId:string, targetId:string, voiceDefinitions:DbTypes.VoiceDefinitionSet) {
    return set(ref(database,`projects/${projectId}/targets/${targetId}/voiceDefinition`),voiceDefinitions)
}

export async function saveFragments(projectId:string, targetId:string, fragments:DbTypes.Fragments) {
    return set(ref(database,`projects/${projectId}/targets/${targetId}/fragments`),fragments)
}

export async function moveVoiceoverRecord(projectId:string, targetId:string, voiceoverIndex:number, time:number) {
    return set(ref(database,`/projects/${projectId}/targets/${targetId}/voiceover/${voiceoverIndex}/start`),time)
}

export async function setTargetPreviewProcessing(userId: string, projectId:string, targetId:string) {
    return set(ref(database,`/users/${userId}/projects/${projectId}/targets/${targetId}/resultsGenerating`),true)
}
export async function setTargetProcessing(projectId:string, targetId:string, resultsId:string, name:string ) {
    return set(ref(database,`/projects/${projectId}/targets/${targetId}/results/${resultsId}`),{
        generating:true,
        name
    })
}

export async function setVoiceoverRecordRation(projectId:string, targetId:string, voiceoverIndex:number, ratio:number) {
    return set(ref(database,`/projects/${projectId}/targets/${targetId}/voiceover/${voiceoverIndex}/ratio`),ratio)
}

export async function setTranscriptSpeakerInfo(projectId:string,speakerId:string,gender:string) {
    const data = {
        id:speakerId,
        gender:gender,
        autodetect:false
    }

    return set(ref(database,`projects/${projectId}/transcript/speakers/${speakerId}`),data)
}

export async function deleteTranscriptSpeakerInfo(projectId:string,speakerId:string) {
    return remove(ref(database,`projects/${projectId}/transcript/speakers/${speakerId}`))
}


export async function setTargetSpeakerInfo(projectId:string,targetId:string,speakerId:string,gender:string) {
    const data = {
        id:speakerId,
        gender:gender,
        autodetect:false
    }

    
    return set(ref(database,`projects/${projectId}/targets/${targetId}/speakers/${speakerId}`),data)
}

export async function deleteTargetSpeakerInfo(projectId:string,targetId:string,speakerId:string) {
    return remove(ref(database,`projects/${projectId}/targets/${targetId}/speakers/${speakerId}`))
}


export async function _adminSetCredit(userId:string, credits:number) {
    return set(ref(database,`/users/${userId}/credits`),credits)
}
export async function _adminSetAdmin(userId:string, admin:boolean) {
    return set(ref(database,`/users/${userId}/admin`),admin)
}
export async function _adminSetFreePlan(userId:string, freeplan:boolean) {
    return set(ref(database,`/users/${userId}/freePlan`),freeplan)
}

export async function updateTarget(projectId:string,targetId:string, updatedTarget:any) {
    return update(ref(database,`projects/${projectId}/targets/${targetId}`),updatedTarget)
}