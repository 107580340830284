import type { Transcript, TranscriptRecord } from "../types/transcript";
import { writable, derived,type Readable, type Writable, get } from "svelte/store";
import { project, type Project } from "./project";
import * as db from '../services/database'
import { currentProject } from "./currentProject";
import { debounce2 } from "../utils/debounce";
import { dbProjectStore } from './db';


//const dbTranscriptStore = dbProjectStore.sub(() => "transcript")

const savedTranscript = writable<TranscriptRecord[]|null>(null);

currentProject.subscribe((project) => {
    savedTranscript.set(null);
})


export const transcript = derived<[Readable<Project>,Writable<TranscriptRecord[]|null>],Transcript>([project,savedTranscript], ([$project,$savedTranscript]) => {

    if(!$project.loaded) 
        return {
            transcriptId: "",
            records: []
        };

    if($savedTranscript)
        return {
            transcriptId: $project.projectId,
            records: $savedTranscript,
            speakers: $project.speakers
        }

    if($project.transcriptDataDb) {
        const records = $project.transcriptDataDb
        return {
            transcriptId: $project.projectId,
            records,
            speakers: $project.speakers
        }
    }
        

    
    return {
        transcriptId: $project.projectId,
        records: ($project.transcriptOriginalDb ?? []).filter(tr=>tr.alternatives).map(tr => ({
            id: crypto.randomUUID(),
            speaker: tr.speaker ? `Speaker ${tr.speaker}` :  "Speaker 1",
            words: tr.alternatives[0].words.map((w,i) => ({...w, id: crypto.randomUUID(), word: i === 0 ? w.word :" " + w.word})), 
            text: tr.alternatives[0].transcript
        })),
        speakers: $project.speakers
    }
    
});

export function addSpeakerInfo(speaker) {
    //if(speaker.gender !== "unknown")
    db.setTranscriptSpeakerInfo(get(currentProject).projectId,speaker.name,speaker.gender)
}

export function updateSpeakerInfo(oldSpeaker,newSpeaker) {
    console.log("updateSpeakerInfo",oldSpeaker,newSpeaker)
    if(oldSpeaker.name !== newSpeaker.name) {
        db.deleteTranscriptSpeakerInfo(get(currentProject).projectId,oldSpeaker.name)
    }
    db.setTranscriptSpeakerInfo(get(currentProject).projectId,newSpeaker.name,newSpeaker.gender)
}

export const speakers = derived(transcript, ($transcript) => $transcript.records.map(t => t.speaker).filter((v,i,a) => a.indexOf(v) === i).map(s => ({name:s, gender:$transcript.speakers?.[s]?.gender ?? "unknown" })));

//add speaker
//rename speaker
//change speaker

export const hoverRecordId = writable<string | null>(null); //>to index
export const activeRecordId = writable<string | null>(null); //>to index
export const activeWordIndex = writable<number | null>(null);

function _save(projectId:string,transcript:TranscriptRecord[]) {
    db.saveTranscript(projectId,transcript)
    savedTranscript.set(null);
}

const debouncedSaveTranscript = debounce2(_save, 3000)

export function saveTranscript(transcript:TranscriptRecord[],force:boolean) {
    const projectId = get(currentProject).projectId
    if(force) {
        debouncedSaveTranscript.cancel();
        _save(projectId,transcript);
    } else {
        debouncedSaveTranscript.call(projectId,transcript);
    }
    savedTranscript.set(transcript);
}